<template>
  <div class="history" >
    <div style=" background: white; z-index: 100000; box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.4); hight: 40px; width: 100vw; top:0; position: fixed; display: flex; align-items: center; justify-content: space-between; align-content: space-between;">
      <div style="margin: 10px 0px; margin-left: 10px;font-size: 19px;" @click="tomainPage"><i class="cubeic-back"></i></div>
      <div style="margin: 0 0px 0px 120px; font-size: 17px; font-weight: 500;"></div>
    </div>

    <div class="dia" v-if="couponLocalList.length === 0">
      <img  :src="eilisProfile.logo" style="margin-top: 90px; transform: scale(1); text-align:center; opacity: 0.8;" alt=" logo"  width="100%">
      <div style="color: #3F3F3F; font-size: 18px; margin-top: 100px; ">目前無票券</div>
    </div>
    <!-- <div class="item-box" :style="{height: cal}"> -->
    <div class="item-box" :style="{height: cal}">
      <ul class="list" v-for="(coupon, index) in couponLocalList"  :key="index">
        <div class="item" style="background: white;margin:0 px; box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.1); border-radius: 10px;" >
          <!-- <i class="cubeic-right" style="width: 10vw; font-size:38px; color: var(--main-color); margin-left: -10px;"/> -->
          <div class="left" style="width: 15vw;">
            <img :src="coupon.img" width="65px" height="100%" style="border-radius:3px; margin-left: -10px; margin-bottom: 40px;" >
            <!-- <li class="title">兌換日期</li>
            <li class="entitle">{{  }}</li> -->
          </div>
            <!-- <li class="title">任務日期</li> -->
          <div class="middle" style="width:50vw; margin-left: 10px; margin-top: 0px;">
            <li class="title">{{ coupon.itemName.length > 13 ? coupon.itemName.substr(0,13) + '...' :  coupon.itemName}}</li>
            <li class="entitle" v-if="coupon.status === 'redeem'" >使用期限：{{ coupon.expire ? coupon.expire : '無期限' }}</li>
            <li class="entitle" v-if="coupon.status !== 'redeem'">使用期限：</li>
            <div><button type="button" class="button" @click="useCoupon(coupon)" :style="{opacity: coupon.status !== 'redeem' ? 0.7 : 1}">{{ coupon.status === 'redeem' ? '立即使用' : '已核銷' }}</button></div>
          </div>
          <div class="right" style="width:15vw; margin-top: -40px;">
            <li class="title"><div class="button0" :style="{opacity: coupon.status !== 'redeem' ? 0.7 : 1}"  v-if="coupon.status === 'redeem'">{{ coupon.status === 'redeem' ? '可使用' : '已核銷'}} </div></li>
            <li class="entitle">{{  }}</li>
          </div>
        </div>
      </ul>
    </div>

    <!-- 品項彈出內容 -->
    <div class="drinkPopDia" v-if="drinkDialog === 'out'">
      <div class="movePart">
        <div class="drinktop">
          <div style="z-index:-50"></div>
          <div style="z-index:-50"></div>
          <!-- DEV NOTE: 20210914 新增左上角‘返回前頁’ -->
          <div class="bottom_left">
            <cube-button
              class="bottom_left_button"
              @click="closeCoupon"
              :primary="true"
              >返回前頁</cube-button>
          </div>
          <!-- DEV NOTE: 20210914 新增左上角‘返回前頁’ -->
          <!-- DEV NOTE: 20210914 關閉原本的‘關閉按鈕’ -->
          <!-- <div style="height: 13px; width:13px ; border-radius: 999em; margin: 20px -10px 20px 40px; transform: scale(1.9); color: var(--main-color);" @click="closeCoupon"><i  class="cubeic-wrong"></i></div> -->
        </div>
        <div class="img" style="width:100%; height:200px; background-color: #ffffff;">
          <!-- <img :src="img" width="100%" height="200px" > -->
          <div :style="{ overflow: 'hidden', width: `${100}%`, height: `${210}px`, backgroundImage: 'url(' + item.img + ')', backgroundSize: 'cover', backgroundPosition: 'center', margin: '0 auto', }"></div>
        </div>
        <br>
        <div class="title" style=" word-break: break-all;" :style="{ height: item.itemName && item.itemName.length > 100 ? '110px' : '60px'}">
          <div style="font-weight: 500;">{{ item.itemName }}</div>
          <div style="font-size:12.5px; margin: 0px  0px; letter-spacing: 0.5px; line-height:14.5px;" v-if="item.expire">使用期限：{{ item.expire }}</div>
        </div>

        <div class="item_contain" >
          <qrcode-vue :value="qrCode" size="130" level="H"></qrcode-vue>
          <div>此QrCode於三分鐘有效</div>
          <div>剩餘{{ number }}</div>
        </div>
        <!---->
        <!---->
      </div>
      <!-- <div class="bottom_contain" :style="{ margin: adjest === '1' ? '-130px auto' : adjest === '2' ?  '-130px auto' : '-10px auto'}"> -->
      <div class="bottom_contain" >
        <div class="bottom_item_box">
          <ul class="bottom_list">
            <br>
            <div class="bottom_item" style="white-space:pre-wrap;"> <!--DEV NOTE:20210915MARK 增加自動換行-->
              <ul>
                <li>{{ item.content }}</li>
              </ul>
              <!-- <div class="bottom_left">
                <li class="title" style="width: 210px;">{{  }} {{  }}</li>
                <li class="entitle" > {{  }}</li>
              </div>
              <div class="bottom_right" style="transform: scale(1.4) translateX(-25px); "></div> -->
            </div>
            <br>
            <div class="bottom_item">
              <ul>
                <li>{{ item.notice }}</li>
              </ul>
              <!-- <div class="bottom_left" style="color: transparent;">充值</div>
              <div class="bottom_middle"><cube-button style="color: var(--main-color);" class="bottom_middle_button" :primary="true" >立即購買</cube-button></div>
              <div class="bottom_right"><cube-button style="background-color: var(--main-color);" class="bottom_right_button" :primary="true">加入購物車</cube-button></div> -->
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapMutations, mapGetters, mapActions } from 'vuex'
import QrcodeVue from 'qrcode.vue';
export default {
  data () {
    return {
      empty: false,
      qrCode: '',
      item: {},
      drinkDialog: '',
      cal: 0,
      couponLocalList: [],
      tempArr: [],
      clientLevel: '',
      number: '',
      time: null,
    }
  },
  components: {
    QrcodeVue,
  },
  mounted() {
    var date = new Date();
    var year = date.getFullYear().toString();
    var month = (date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1).toString()}` : (date.getMonth() + 1).toString();
    var day = date.getDate() < 10 ? `0${date.getDate().toString()}` : date.getDate().toString();
    this.thisDate = parseInt(year + month + day);
    this.getWholeCouList()
  },
  computed: {
    ...mapGetters({
      eilisProfile: 'eilisProfile',
      personalData: 'personalData',
      brandData: 'brandData',
      couponList: 'couponList',
      levelContent: 'levelContent'
    }),
    updateCouponList: {
      get () { return this.couponList },
      set(value) { this.storeCouponList({ couponList: value }); },
    },
  },
  methods: {
    ...mapActions({
      getUserCoupons: 'getUserCoupons',
      getCoupon: 'getCoupon'
    }),
    ...mapMutations({
      storeCouponList: 'storeCouponList'
    }),
    timer() {
      this.number = 180;
      this.time = setInterval(() => {
        this.number -= 1;
      }, 1000)
    },
    setTime() {
      this.timer()
    },
    stopTime() {
      if (this.time) {
        clearInterval(this.time)
      }
    },
    closeCoupon() {
      this.number = '';
      this.stopTime();
      this.drinkDialog = ''
    },
    useCoupon(val) {
      if (val.status !== 'redeem') {
        return;
      }
      this.number = '';
      console.log(val);
      console.log(Date.now());
      this.setTime();
      this.qrCode = `${val.coupon}_${Date.now()}`
      console.log(this.qrCode);
      this.item = val
      this.drinkDialog = 'out'
    },
    tomainPage() {
      this.$emit('toMain','QQ');
    },
    getWholeCouList() {
      this.$loading.show();
      for (let i = 0; i < this.levelContent.list.length; i++) {
        // console.log(i);
        if (i === this.personalData.level) {
          this.clientLevel = this.levelContent.list[i].name
        }
      }
      const A = this.getCoupon({accountId: this.eilisProfile.accountId})
      const B = this.getUserCoupons({accountId: this.eilisProfile.accountId, userId: this.personalData.userId})
      Promise.all([A, B]).then(values => {
        console.log(values);
        let couponLocalListB = values[1].data.result
        if (couponLocalListB.length < 1) {
          this.empty = true
        }

        let couponListA = values[0].data.result
        let arr = [];
        let filterTag = [];
        // let birtharr = a.filter(el => el.births).length > 0 ? a.filter(el => el.births) : a
        this.monthFilter(couponListA)
        filterTag = this.tempArr
        console.log(this.clientLevel);
        console.log(this.personalData);
        // 判斷等級、性別、櫃位
        // console.log(filterTag[0].indexOf(this.clientLevel));
        filterTag = filterTag.filter(el =>
          ((el.levels && el.levels.indexOf(this.clientLevel) === -1 && !el.noShow ) || (el.levels && el.levels.indexOf(this.clientLevel) !== -1  ) || el.levels && el.levels.length < 1 || !el.levels) &&
          ((el.genders && el.genders.indexOf(this.personalData.gender) === -1 && !el.noShow)|| el.genders && el.genders.indexOf(this.personalData.gender) !== -1  || el.genders && el.genders.length < 1 || !el.genders) &&
          ((el.shops && el.shops.indexOf(this.personalData.shopNo) === -1 && !el.noShow) || (el.shops && el.shops.indexOf(this.personalData.shopNo) !== -1 ) || el.shops && el.shops.length < 1 || !el.shops)
        )
        // console.log(filterTag);
        // 過濾同名
        let array2 = couponListA.map(el => el.type)
        let typeArr = array2.filter((ele,pos) => array2.indexOf(ele) == pos);
        for (let i = 0; i < typeArr.length; i++) {
          arr.push({
            item: [],
            type: typeArr[i]
          })
        }
        for (let i = 0; i < filterTag.length; i++) {
          const type = filterTag[i].type
          const index = arr.map(el => el.type).indexOf(type);
          // console.log(index);
          arr[index].item.push(filterTag[i])
          arr[index].item.filter(n => n)
        }
        // console.log(filterTag);
        for (let i = 0; i < arr.length; i++) {
          for (let e = 0; e < arr[i].item.length; e++) {
            if (arr[i].item[e].onShelf && arr[i].item[e].offShelf) {
              arr[i].item = arr[i].item.filter(el => parseInt(this.getFullDate(el.offShelf).substr(0,10).replace('-','').replace('-','')) >= this.thisDate && parseInt(this.getFullDate(el.onShelf).substr(0,10).replace('-','').replace('-','')) <= this.thisDate)                  // arr[i].item.splice(e,1);
            }
          }
        }
        this.updateCouponList = arr
        console.log(this.updateCouponList);
        console.log(couponLocalListB);
        console.log(filterTag);
        let finalCouponList = []
        for (let i = 0; i < couponListA.length; i++) {
          for(let e = 0; e < couponLocalListB.length; e++) {
            console.log(couponListA[i].eventId === couponLocalListB[e].eventId);
            if (couponLocalListB[e].eventId === couponListA[i].eventId ) {
              console.log(couponLocalListB[e].eventId);
              console.log(e);
              finalCouponList[e] = {
                content: couponListA[i].content,
                eventId: couponListA[i].eventId,
                img: couponListA[i].img,
                itemName: couponListA[i].itemName,
                notice: couponListA[i].notice,
                active: couponLocalListB[e].active,
                coupon: couponLocalListB[e].coupon,
                status: couponLocalListB[e].status,
                // expire: couponLocalListB[e].expire,
                expire: couponLocalListB[e].expire ? this.getFullDate(couponLocalListB[e].active + (couponLocalListB[e].expire * 86400 * 1000)) : '',
                invalidTime: couponLocalListB[e].invalidTime ? couponLocalListB[e].invalidTime : '',
              }
            }
          }
        }
        finalCouponList = finalCouponList.filter(el => el)
        console.log(finalCouponList);
        setTimeout(() => {
        this.$loading.hide()
        // DEV NOTE:20210915 ping 原本直接列全陣列
        // this.couponLocalList = finalCouponList
        // DEV NOTE:20210915 ping 將陣列依照使用狀態排序，已核銷至後
        this.couponLocalList = finalCouponList.sort((a, b)=>(b.status > a.status)? 1 : -1)
        // this.couponLocalList = []
        this.cal = (130 * this.couponLocalList.length.toString()) + 'px'
        },800)      
      })
    },
    getFullDate(time) {
      // console.log(time);
      const date = time ? new Date(time) : new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1).toString()}` : (date.getMonth() + 1).toString();
      const day = date.getDate() < 10 ? `0${date.getDate().toString()}` : date.getDate().toString();
      return `${year}${month}${day}`;
    },
    monthFilter(val) {
      // console.log(val);
      // console.log(this.personalData.birth.substr(5,2));
      let arr = val.filter(el => el.births && el.births === this.personalData.birth.substr(5,2))
      let arr1 = val.filter(el => !el.births)
      arr = arr.concat(arr1)
      this.tempArr = arr
    },
  },
}
</script>

<style lang="less" scoped>
  // .menu {
  //   // padding-bottom: 10px;
  //   overflow: scroll;
  //   height: calc(100vh - 70px);
  //   z-index: 1;
  //   padding-left: 0!important;
  //   padding-right: 0!important;
  //   margin: 0 auto;
  //   width: 100%;
  //   max-width: 500px;
  //   background-color: #ffffff;
  //   /* border: 1px solid rgb(170, 170, 170); */
  //   /* border:1px solid #8a8a8a; */
  // }
  .dia {
    padding: 0px;
    width: 100%;
    height: 400px;
    border-radius: 10px;
  }
  .button0 {
    text-align: center;
    background-color: burlywood;
    color: white;
    border-radius: 3px;
    font-size: 10px;

  }
  .button {
    /* Rerun */
    width: 80vw;
    height: 33px;
    text-align: center;
    position: relative;
    cursor: pointer;
    display: inline-block;
    background: var(--main-color);
    border-radius: 5px;
    padding: 2px 7px;
    color: white;
    text-decoration: none;
    margin: 10px 0px 0px 0px;
    margin-left: -75px;
    border: none;
    // &:hover {
    //   @include materialShadow(2);
    // }
  }
  .history {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: scroll;
    height: calc(100vh);
    z-index: 100;
    padding-left: 0!important;
    padding-right: 0!important;
    // margin: 0 auto;
    width: 100%;
    max-width: 500px;
    background-color: #ffffff;
  }
  .item-box {
    z-index: 10000000;
    padding-left: 0!important;
    padding-right: 0!important;
    // height: calc(100vh + 50px);
    // height: 5000px;
    margin-top: 15%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    // padding-bottom: 20px;
    // DEV NOTE: 20210915 ping 拿掉內部滾輪
    // overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .item-box .list{
    padding-left: 0!important;
    padding-right: 0!important;
    text-align: left;
    // height: 100px;
    width: 100%;
    max-width: 400px;
    // padding: 0px;
    // overflow-y: auto;
    // -webkit-overflow-scrolling: touch;
    // padding-bottom: 70px;
  }
  .item-box .list .item {
    // margin-top: 7px;
    display: flex;
    //overflow-y: auto;
    align-items: center;
    justify-content: space-between;
    align-content: space-between;
    // border-bottom: 1px solid rgba(210, 210, 210, 0.815);
    height: 75px;
    padding: 20px;
  }
  .item-box .list .item .left .entitle{
    margin-left: 0px;
    // margin-top: 2px;
    // color: rgba(67, 67, 67, 0.851);
    font-size: 14px;
    // font-weight: 400;
    letter-spacing: 1px;
  }
  .item-box .list .item .middle .title {
    margin-top: 0px;
    color: rgba(0, 0, 0, 0.851);
    font-size: 14px;
    // font-weight: 500;
    display: flex;
    letter-spacing: 1px;
    justify-content: space-between;
  }
  .item-box .list .item .middle .entitle {
    // margin-top: 2px;
    margin-left: 0px;
    color: rgba(140, 140, 140, 0.851);
    font-size: 13px;
    // font-weight: 400;
    letter-spacing: 0px;
  }
  .item-box .list .item .right {
    margin-right: -10px;
    // margin-top: 2px;
    // color: rgba(67, 67, 67, 0.851);
    font-size: 14px;
    // font-weight: 400;
    letter-spacing: 1px;
  }
  .item-box .list .item .right .title {
    // margin-top: 2px;
    margin-left: 10px;
    // color: rgba(67, 67, 67, 0.851);
    font-size: 14px;
    // font-weight: 400;
    letter-spacing: 1px;
  }
  .item-box .list .item .right .entitle {
    // margin-top: 2px;
    margin-left: 25px;
    color: rgba(67, 67, 67, 0.851);
    font-size: 14px;
    // font-weight: 400;
    letter-spacing: 1px;
  }
  .drinkPopDia {
    z-index: 3000000000;
    position: fixed;
    top: 0px;
    // margin: 0 auto;
    width: 100vw;
    max-width: 600px;
    height: 400px;
    background-color: #f9f9f9;
  }
  .drinkPopDia .movePart {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: scroll;
    // -webkit-overflow-scrolling: touch;
  }
  .drinkPopDia .drinktop {
    display: grid;
    grid-template-columns: 10% 70% 20%;
    position: fixed;
    z-index: 10000;
    width: 100%;
    height: 40px ;
    // background-color: rgba(255, 255, 255, 0.24);
    // box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.4);
  }
  .drinkPopDia .title {
    display: grid;
    grid-gap: 5px;
    width:95%;
    padding: 10px;
    height: 60px;
    // max-height: 60px;
    background-color: rgba(255, 255, 255, 0.933);
    margin: 0 auto;
    border-radius:5px;
    text-align: left;
    // background-color: rgba(255, 255, 255, 0.24);
    // box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.4);
  }
  .drinkPopDia .item_contain {
    width:95%;
    height: 400px;
    // height: calc(100% - 250px);
    background-color: rgb(255, 255, 255);
    margin: 15px auto;
    border-radius:5px;
    grid-template-columns: 2fr 2fr ;
    grid-template-rows:  2fr 2fr;
    grid-gap: 20px;
    padding: 10px;
  }
  .bottom_item_box {
    padding-left: 0!important;
    padding-right: 0!important;
    // height: 150px;
    width: 100%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0;
  }
  .bottom_list {
    padding-left: 0!important;
    padding-right: 0!important;
    text-align: left;
    height: 200px;
    width: 94vw;
    margin-top: 0px;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .bottom_item {
    // margin-top: 0px;
    // border-bottom: none;
    // // align-items: center;
    // height: 55px;
    // padding: 5px;
    position: relative;
    width: 80vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: space-between;
    margin-top: 30px;
    word-break: break-all;
    // border-bottom: 1px solid rgba(210, 210, 210, 0.815);
    height: 50px;
    padding: 0px;
  }
  .bottom_left {
    // height: 5px;
    // text-align: left;
    align-items: center;
  }
    // DEV NOTE: 20210914 增加‘返回首頁’按鈕bottom_left_button樣式
  .bottom_left_button {
  // background-clip: text;
  font-size: 15px;
  border-radius: 20px;
  width: 90px;
  height: 35px;
  margin-top: 15px;
  margin-left: -380%;
  // -webkit-text-fill-color: transparent;
  // border: 1px solid rgba(112, 88, 88, 0.877);
  color: rgb(255, 255, 255);
  background-color: #da3925;
  padding: 0px;
  z-index: 1000;
  }
  .bottom_right {
    background-clip: text;
    font-size: 17px;
    // width: 70px;
    // -webkit-text-fill-color: transparent;
    // border: 1px solid rgba(112, 88, 88, 0.877);
    color:#F37423;
    padding: 0px;
    transform: translateX(-20px);
  }
  .bottom_middle_button {
    width: 110px;
    height: 35px;
    line-height: 0px;
    font-size: 15px;
    color: #F37423;
    background-color: rgba(255, 255, 255, 0.877);
    border:1px solid #F37423;
    border-radius:50px;
    margin-left: 30px;
  }
  .bottom_right_button {
    // background-clip: text;
    font-size: 15px;
    border-radius:50px;
    width: 110px;
    height: 35px;
    // -webkit-text-fill-color: transparent;
    // border: 1px solid rgba(112, 88, 88, 0.877);
    color: rgb(255, 255, 255);
    background-color: #F37423;
    padding: 0px;
  }
  .bottom_item .title{
    color: rgba(0, 0, 0, 0.851);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    height: 35px;
    // justify-content: space-between;
  }
  .bottom_item .entitle{
    width: 200px;
    font-size: 13px;
    color: rgb(91, 91, 91);
    margin-left: 15px;
  }
  .drinkPopDia .item_contain .item{
    text-align: left;
    color: #5b5b5b;
    width: 22em;
    max-width: 540px;
    height: 60px;
    margin: 12px 0px;
    // background: #ffa5a8;
  }
  .drinkPopDia .item_contain .item .button{
    color: #6e6e6e;
    //  DEV NOTE:20210914 補上background-color樣式 ping
     background-color: transparent;
    width: 65px;
    height: 28px;
    margin: 5px;
    border-radius: 50px;
    font-size: 13px;
  }
  .active{
    border: 1px solid rgb(255, 116, 30);
    color: rgb(19, 3, 3);
    background-color:rgb(255, 255, 255);
  }
  .drinkPopDia .bottom_contain {
    position: fixed;
    width:100%;
    // height: 200px;
    border-top: 1px solid #dfdfdf;
    background-color: #ffff;
    // background-color: #f9f9f9;
    bottom: 0;
    // margin: 0px auto;
    // display: grid;
    // grid-gap: 5px;
    // grid-template-columns: 33% 33% 33%;
    padding: 5px;
  }
</style>