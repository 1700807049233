import { render, staticRenderFns } from "./couponPage.vue?vue&type=template&id=bc6f6a9e&scoped=true&"
import script from "./couponPage.vue?vue&type=script&lang=js&"
export * from "./couponPage.vue?vue&type=script&lang=js&"
import style0 from "./couponPage.vue?vue&type=style&index=0&id=bc6f6a9e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc6f6a9e",
  null
  
)

export default component.exports