<template>
  <div>
    <div class="mobile-wrap" v-if="isMoblie" style="position: relative;">


      <bonusPage v-if="title === brandData.iframeName && !this.brandData.azure"/>
      <!-- <bonusPage v-if="title === brandData.iframeName"/> -->

      <azureShoppingCart @gotochart="gotochart" v-if="title === brandData.iframeName && this.brandData.azure && this.brandData.azure === true"/>

      <!-- 首頁 -->
      <Mainpage v-if="title === '首頁'"/>

      <Menu v-if="title === '菜單'"/>

      <!-- 紀錄 -->
      <Record v-if="title === '訂單'"></Record>

      <!-- 購物車 -->
      <shoppingcart id="switch" @gotochart="gotochart"  v-if="title === '購物車'">
      </shoppingcart>

      <!-- 個人檔案 -->
      <profile v-if="title === '我的'"      />

      <!-- 計分板 -->
      <scoreboard v-if="title === '計分板'" />

        <!-- <div class="notificationCode"></div> -->
        <nav class="nav" ref="menu">
          <a href="#">
            <div v-if="brandData.nav.iframe === true && !this.brandData.azure && !this.brandData.azure === true" class="nav_contain" @click="gotoPage(brandData.iframeName)" :class="{ active: title === brandData.iframeName, }">
              <div style="transform: scale(1.8);"><i class="cubeic-home"></i></div>
              <div :style="{transform: 'scale(0.7)', fontSize: brandData.iframeName.length > 2 ? '14px' : '17px'}" class="nav_link">{{brandData.iframeName}}</div>
            </div>
          </a>
          <a href="#">
            <div class="nav_contain" @click="gotoPage('首頁')" :class="{ active: title === '首頁', }">
              <div style="transform: scale(1.8);"><i class="cubeic-home"></i></div>
              <div style="transform: scale(0.7);" class="nav_link">首頁</div>
            </div>
          </a>
          <a href="#">
            <div v-if="brandData.nav.iframe === true && this.brandData.azure && this.brandData.azure === true" class="nav_contain" @click="gotoPage(brandData.iframeName)" :class="{ active: title === brandData.iframeName,}">
              <div style="transform: scale(1.8);"><i class="cubeic-red-packet"></i></div>
              <div :style="{transform: 'scale(0.7)', fontSize: brandData.iframeName.length > 2 ? '17px' : '17px'}" class="nav_link">{{ brandData.iframeName }}</div>
            </div>
          </a>
          <a href="#">
            <div v-if="brandData.nav.order === true" class="nav_contain" style="" @click="gotoPage('訂單')" :class="{ active: title === '訂單', }">
            <!-- <div class="nav_contain" style="" @click="gotoPage('訂單')" :class="{ active: title === '訂單', }"> -->
              <div style="transform: scale(1.8);"><i class="cubeic-calendar"></i></div>
              <div style="transform: scale(0.7);" class="nav_link">訂單</div>
            </div>
          </a>
          <a href="#">
            <div class="notificationCode" v-show="shopCart.length > 0"></div>
            <div  v-if="brandData.nav.iframe === true && this.brandData.azure && this.brandData.azure === true" class="nav_contain" @click="gotoPage('購物車')" :class="{ active: title === '購物車'}" :style="{opacity: limit === true ? 0.3 : 1, marginTop: shopCart.length > 0 ? '-34%' : '0%',}">
              <div style="transform: scale(1.95);"><i class="cubeic-mall"></i></div>
              <div style="transform: scale(0.7);" class="nav_link">購物車</div>
            </div>
          </a>
          <a href="#">
            <div v-if="brandData.nav.mission === true" class="nav_contain" @click="gotoPage('計分板')" :class="{ active: title === '計分板', }">
              <div style="transform: scale(1.5);"><i class="cubeic-person"></i></div>
              <div style="transform: scale(0.7);" class="nav_link">計分板</div>
            </div>
          </a>
          <a href="#">
            <!-- <div v-if="brandData.nav.shop === true" class="nav_contain" @click="gotoPage('菜單')" :class="{ active: title === '菜單'}" :style="{opacity: limit === true ? 0.3 : 1}"> -->
            <div v-if="brandData.nav.coupon === true" class="nav_contain" @click="gotoPage('菜單')" :class="{ active: title === '菜單'}" :style="{opacity: limit === true ? 0.3 : 1,}">
              <div style="transform: scale(1.7);"><i class="cubeic-like"></i></div>
              <div  :style="{transform: 'scale(0.7)', fontSize: brandData.iframeName.length > 2 ? '17px' : '17px'}" class="nav_link">優惠商城</div>
            </div>
          </a>
          <!-- <a href="#">
            <div  v-if="brandData.nav.shoppingCart === true" class="nav_contain" @click="gotoPage('菜單')" :class="{ active: title === '菜單'}" :style="{opacity: limit === true ? 0.3 : 1}">
              <div style="transform: scale(1.8);"><i class="cubeic-person"></i></div>
              <div style="transform: scale(0.7);" class="nav_link">購物車</div>
            </div>
          </a> -->
          <a href="#">
            <div class="nav_contain" @click="gotoPage('我的')" :class="{ active: title === '我的', }">
              <div style="transform: scale(1.8);"><i class="cubeic-person"></i></div>
              <div style="transform: scale(0.7);" class="nav_link">我的</div>
            </div>
          </a>
        </nav>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapMutations, mapGetters, mapActions, createNamespacedHelpers} from 'vuex'
// import Register from '../components/louisaElement/Register.vue'
// import initial from '@/components/louisaElement/initial.vue'
import QrcodeVue from 'qrcode.vue';
import Mainpage from '@/components/user/mainpage.vue'
import Record from '@/components/user/record.vue'
import shoppingcart from '@/components/user/shoppingcart.vue'
import profile from '@/components/user/profile.vue'
import Menu from '@/components/user/menu.vue'
import bonusPage from '@/components/user/iframe.vue'
import azureShoppingCart from '@/components/user/AzureShopping.vue'
import scoreboard from '@/components/user/scoreBoard.vue'
import axios from 'axios'

const code = createNamespacedHelpers('code');
const CryptoJS = require('crypto-js');
// U5e40bf2c39348a53054882025fd91695
// 黃靖婷 李依珊 黃亞儀 丁泓丞 王子杰 林書豪 RYAN

export default {
  data () {
    return {
      thisDate: '',
      couponArr: [],
      // couponList: [],
      profileImg: '',
      myCheck: false,
      onlineOrder: {},
      loading: false,
      none: false,
      dialog: '',
      limit: false,
      //
      address:'',
      gender: '',
      phone:'',
      id: '',
      birth: '',
      points: '',
      userName: '',
      barcode: '',
      qrcode: '',
      email: '',
      //
      // county: '',
      // district: '',
      point_record: '',
      record: '',
      detail: {},
      isMoblie: true,
      title: '首頁',
      itemNum: 0,
      selectedLabelDefault: '首頁',
      tempBrandData: '',
      tabs: [{
        label: '首頁',
        icon: 'cubeic-home'
      },
      {
        label: '菜單',
        icon: 'cubeic-red-packet'
      },
      {
        label: '訂單',
        icon: 'cubeic-calendar'
      },
      {
        label: '購物車',
        icon: 'cubeic-mall'
      },
      {
        label: '我的',
        icon: 'cubeic-person'
      }],
      channelData: {
        AuthToken: this.$route.query.Auth,
        Service: this.$route.params.service,
        PhoneNumber: '',
        Name: this.$route.query.Name ? this.$route.query.Name : '',
        PageID: this.$route.query.Page,
      },
    }
  },
  components: {
    Mainpage,
    Record,
    QrcodeVue,
    profile,
    shoppingcart,
    Menu,
    bonusPage,
    azureShoppingCart
  },
    mounted() {
      console.log(this.eilisProfile);
      console.log(this.personalData);
      // console.log(this.brandData);
      console.log(this.$refs.menu);
      // console.log(liff.isLoggedIn());
      // console.log(this.aisleName);
      // setTimeout(() => {
      // console.log(this.azureShopList);
      // },500)
      this.storeCarrierNumber = this.personalData.carrier ? this.personalData.carrier : ''
      this.arrangeNav()
      // DEV NOTE:20210917MARK 合併馬力歐 
      if (this.shopDetail && this.shopDetail.secretkey && this.brandData.azure) {
        this.gotoAzureShopping()
      }
      if (this.paymentStatus && this.paymentStatus === '0') {
        this.$createToast({
          type: 'correct',
          zIndex: 10000,
          txt: '訂單已送出,付款已成功',
          time: 2500
        }).show()
        this.storePaymentStatus = ''
      } else if (this.paymentStatus && this.paymentStatus !== '0') {
        this.$createToast({
          type: 'error',
          zIndex: 10000,
          txt: '付款失敗',
          time: 2500
        }).show()
        this.storePaymentStatus = ''
      }
      // 判斷有沒有帶aisle 參數
      if (this.aisleName && this.aisleName !== 'undefined') {
        this.gotoPage(this.aisleName)
      }
      this.getLevel({accountId: this.eilisProfile.accountId})
      .then((res) => {
        console.log(res);
        this.updateLevelContent = res.data.result
      })
      this.getAzureRecord(); // 聯發
      this.checkUserByToken({ service: this.channelData.Service, token: this.channelData.AuthToken, auth: this.eilisProfile.auth, accountId: this.eilisProfile.accountId})
      .then((res) => {
        console.log(res);
        this.PData = res.data.result; //個人資料
        if (res.data.result === null) {
          localStorage.removeItem('personalInfo');
          this.$createDialog({
            type: 'alert',
            zIndex: 10000000000,
            title: '已刪除會員',
            content: '請重新登入註冊',
            confirmBtn: '確定',
            onConfirm: (e, promptValue) => {
              if (this.$route.params.service === 'FB') {
                window.close()
              } else {
                liff.closeWindow();
              }
            }
          }).show()
        }
      })

      var date = new Date();
      var year = date.getFullYear().toString();
      var month = (date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1).toString()}` : (date.getMonth() + 1).toString();
      var day = date.getDate() < 10 ? `0${date.getDate().toString()}` : date.getDate().toString();
      this.thisDate = parseInt(year + month + day);
    },
    computed: {
    ...mapGetters({
      eilisProfile: 'eilisProfile',
      personalData: 'personalData',
      brandData: 'brandData',
      tempCarrier: 'tempCarrier',
      aisleName: 'aisleName',
      azureShopList: 'azureShopList',
      orderRecord: 'orderRecord',
      levelContent: 'levelContent',
      shopList: 'shopList',
      shopCart: 'shopCart',
      paymentStatus: 'paymentStatus',
      shopDetail: 'shopDetail'
    }),
    storePaymentStatus: {
      get () { return this.paymentStatus },
      set(value) { this.updatePaymentStatus({ paymentStatus: value }); },
    },
    updateLevelContent: {
      get () { return this.levelContent },
      set (value) { this.storeLevelContent({ levelContent: value }); },
    },
    storeOrderRecord: {
      get () { return this.orderRecord },
      set (value) { this.updatedOrderRecord({ OrderRecord: value }); },
    },
    storeCarrierNumber: {
      get () { return this.tempCarrier },
      set (value) { this.updateCarrier({ carrier: value }); },
    },
    PData: {
      get () { return this.personalData },
      set(value) { this.storepersonal({ personal: value }); },
    },
    storeShopList: {
      get () { return this.shopList },
      set (value) { this.updateShopList({ shopList: value }); },
    },
    // EFile: {
    //   get () { return this.eilisProfile },
    //   set(value) { this.storeEilisFile({ Efile: value }); },
    // },
  },
  watch: {
    brandData: {
      handler: function(brandData) {
        // console.log(brandData);
        if (!brandData) {
          this.$nextTick(() => {
            this.tempBrandData = brandData
            console.log(this.tempBrandData);
            this.arrangeNav();
          })
        }
      },
      deep: true,
      immediate: true
    },
    shopCart(val) {
      const el = document.querySelector('.notificationCode');
      console.log(val);
      console.log(el);
      if (val.length > 0) {
        // const el = document.querySelector('.notificationCode');
        el.setAttribute('data-count', val.length);
        el.classList.add('show-count');
      } else {
        el.classList.remove('show-count');
      }
    }
  },
  methods: {
    ...mapActions({
      checkUserByToken: 'checkUserByToken',
      updateMember: 'updateMember',
      updateMemberEilis: 'updateMemberEilis',
      getOrderRecord: 'getOrderRecord',
      getLevel: 'getLevel',
      getShops: 'getShops'
    }),
    ...mapMutations({
      storeEilisFile: 'storeEilisFile',
      updateCarrier: 'updateCarrier',
      storepersonal: 'storepersonal',
      updatedOrderRecord: 'updatedOrderRecord', // 更新訂單記錄
      storeLevelContent: 'storeLevelContent',
      updateShopList: 'updateShopList',
      updatePaymentStatus: 'updatePaymentStatus'
    }),
    gotoAzureShopping() {
      var div = document.querySelectorAll('.nav_contain');
      console.log(div);
      for (let i = 0; i < div.length; i ++) {
        if (div[i].innerText === this.brandData.iframeName || div[i].textContent === this.brandData.iframeName) {
          div[i].classList.add('active')
          this.gotoPage(this.brandData.iframeName)
        }
      }
    },
    gotochart(val, val1) {
      console.log(val);
      console.log(val1);
      if (val1 === '購物車') {
        this.$createToast({
          type: 'correct',
          zIndex: 10000,
          txt: '訂單已送出',
          time: 1500
        }).show()
      }
      // this.gotoPage('購物車')
      var div = document.querySelectorAll('.nav_contain');
      console.log(div);
      for (let i = 0; i < div.length; i ++) {
        if (div[i].innerText === val || div[i].textContent === val) {
          // const index =
          console.log(div[i].innerText === val);
          div[i].classList.add('active')
          this.gotoPage(val)
        }
      }
      // this.$refs.menu3[0].$el.click();
      // console.log(this.$refs.menu);
    },
    getAzureRecord() {
      // DEV NOTE:20210916 ping loading資料
      this.$loading.show();
      //if (this.brandData.azure && this.brandData.azure === true) {
      // this.getOrderRecord({accountId: this.eilisProfile.accountId, userId: this.personalData.userId, duration: 'week',type: 'online',})
      // .then((res) => {
      //   console.log(res);
      //   if (res.data.result.length !== 0) {
      //     this.storeOrderRecord = res.data.result
      //   }
      // })
      const A = this.getOrderRecord({accountId: this.eilisProfile.accountId, userId: this.personalData.userId, duration: null,type: 'online',})
      const B = this.getShops({accountId: this.eilisProfile.accountId})
      const C = this.getOrderRecord({accountId: this.eilisProfile.accountId, userId: this.personalData.userId, duration: null,type: 'offline',})
      Promise.all([A, B, C]).then(values => {
        console.log(values);
        console.log(values[0]);
        if (values[0].data.result.length !== 0 || values[2].data.result.length !== 0) {
          let offline = [];
          offline = values[2].data.result
          console.log(offline);
          this.storeOrderRecord = values[0].data.result.concat(offline);
          this.storeShopList = values[1].data.result
          console.log(this.storeOrderRecord);
        }
        this.$loading.hide()
      })
      // }
    },
    arrangeNav() {
      var div = document.querySelectorAll('.nav_contain');
      const nav = this.brandData ? Object.values(this.brandData.nav).filter(el => el === true).length : Object.values(this.tempBrandData.nav).filter(el => el === true).length
      console.log(nav);
      console.log(div);
      if (nav === 0) {
        for (let i = 0; i < div.length; i ++) {
          div[i].style.margin = '90px';
        }
      }
      else if (nav === 1) {
        for (let i = 0; i < div.length; i ++) {
          if (document.body.offsetWidth <= 320) {
            div[i].style.margin = '40px';
          } else if (document.body.offsetWidth <= 375) {
            div[i].style.margin = '55px';
          } else if (document.body.offsetWidth <= 414){
            div[i].style.margin = '65px';
          }
        }
      } else if (nav === 2) {
        for (let i = 0; i < div.length; i ++) {
          if (document.body.offsetWidth <= 320) {
            div[i].style.margin = '24px';
          } else if (document.body.offsetWidth <= 375) {
            div[i].style.margin = '31px';
          } else if (document.body.offsetWidth <= 414){
            div[i].style.margin = '38px';
          }
        }
      } else if (nav === 3) {
        for (let i = 0; i < div.length; i ++) {
          // if (document.body.offsetWidth <= 320) {
          //   div[i].style.margin = '10px';
          // } else if (document.body.offsetWidth <= 375){
          //   div[i].style.margin = '15px';
          // } else if (document.body.offsetWidth <= 414){
          //   div[i].style.margin = '21px';
          // }

          if (document.body.offsetWidth <= 320) {
            div[i].style.margin = '3px';
          } else if (document.body.offsetWidth <= 375){
            if (div[i].outerText.length > 3) {
              div[i].style.margin = '0px';
            } else {
              div[i].style.margin = '13px';
            }
          } else if (document.body.offsetWidth <= 414) {
            if (div[i].outerText.length > 3) {
              div[i].style.margin = '2px';
            } else {
              div[i].style.margin = '18px';
            }
            // div[i].style.margin = '12px';
          }
        }
      } else if (nav === 4) {
        for (let i = 0; i < div.length; i ++) {
          if (document.body.offsetWidth <= 320) {
            div[i].style.margin = '7px';
          } else if (document.body.offsetWidth <= 375) {
            div[i].style.margin = '13px';
          } else if (document.body.offsetWidth <= 414){
            div[i].style.margin = '15px';
          }
        }
      }
    },
    getFullDate(time) {
      const date = time ? new Date(time) : new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1).toString()}` : (date.getMonth() + 1).toString();
      const day = date.getDate() < 10 ? `0${date.getDate().toString()}` : date.getDate().toString();
      return `${year}${month}${day}`;
    },
    gotoPage(val) {
      console.log('441' + val);
      if (val === '菜單' && this.limit === true) {
        this.toast = this.$createToast({
          txt: `商城即將上線，敬請期待`,
          time: 900,
          type: 'txt'
        }).show()
        return;
      }
      this.title = val;
    },
    gotoList() {
      this.getUser()
      setTimeout(() => {
        this.$refs.menu2[0].$el.click();
      },1500)
    },
    gotoMain(val) {
      this.title = val;
      // this.$refs.menu3[0].$el.click();
      // this.$refs.menu.$children[3].handleClick.isActive = true
      // this.eventFire(document.getElementById('switch'), 'click');
      // const a = document.getElementById('switch');
      // a.click();
    },
    validateHandler(result) {
      // console.log(this.model.county);
      this.fields[1].props.options = region[this.model.county];
    },
    // 解密
    Decrypt(word) {
      // console.log(word);
      var key = "FFzqekWVwgu7Oz28pfyPUlbOk4UusnNc"
      var iv = "2uY28N7v5kVEuFPr"
      const keysStr = CryptoJS.enc.Utf8.parse(key);
      const ivStr = CryptoJS.enc.Utf8.parse(iv);
      let encryptedHexStr = CryptoJS.enc.Base64.parse(word);
      let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
      let decrypt = CryptoJS.AES.decrypt(srcs, keysStr, {
          iv: ivStr,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });
      let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
      // console.log(decryptedStr);
      return decryptedStr.toString();
    },
    // 取得用戶資料跟解碼，拿電話同時打查詢訂單api
    generateCode(phone) {
      var key = "FFzqekWVwgu7Oz28pfyPUlbOk4UusnNc"
      var iv = "2uY28N7v5kVEuFPr"
      var keyStr = CryptoJS.enc.Utf8.parse(key)
      var ivStr = CryptoJS.enc.Utf8.parse(iv)
      const options = {
        iv: ivStr,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      };
      const QrPhone =  CryptoJS.AES.encrypt(phone, keyStr, options).toString()
      // console.log(QrPhone);
      // const CodedPhone = Math.floor((Math.random() * (99 - 11))).toString() + phone.toString() + Math.floor((Math.random() * (99 - 11))).toString();
      // const transformPhone = CodedPhone.replace(/0/g, 'a').replace(/1/g, 'b').replace(/2/g, 'c').replace(/3/g, 'd').replace(/4/g, 'e').replace(/5/g, 'f').replace(/6/g, 'g').replace(/7/g, 'h').replace(/8/g, 'i').replace(/9/g, 'j');
      this.num = 0;
      this.qrcode = QrPhone;
      const arr = QrPhone.split();
      // console.log(arr[0].replace('+','%2B'));
      if (arr.indexOf('+')) {
        // QrPhone.replace('+','%2B')
        this.barcode = `https://api-bwipjs.metafloor.com/?bcid=code128&text=${QrPhone.replace('+','%2B')}&scaleY=0.4`
        // console.log(this.barcode);
      } else if (arr.indexOf('%')) {
        // QrPhone.replace('%','%25')
        this.barcode = `https://api-bwipjs.metafloor.com/?bcid=code128&text=${QrPhone.replace('%','%25')}&scaleY=0.4`
      } else if (arr.indexOf('#')) {
        this.barcode = `https://api-bwipjs.metafloor.com/?bcid=code128&text=${QrPhone.replace('#','%23')}&scaleY=0.4`
      } else if (arr.indexOf('&')) {
        this.barcode = `https://api-bwipjs.metafloor.com/?bcid=code128&text=${QrPhone.replace('&','%26')}&scaleY=0.4`
      } else {
        this.barcode = `https://api-bwipjs.metafloor.com/?bcid=code128&text=${QrPhone}&scaleY=0.4`
      }
      // for (var i = 0; i < arr.length; i++) {
      //   if (arr.indexOf('+')) {
      //     arr[i].replace('+', '')
      //   }
      // }
      // console.log(QrPhone);
      // this.barcode = `https://api-bwipjs.metafloor.com/?bcid=code128&text=${QrPhone}&scaleY=0.4`
    },
    filterStr(str) {
      var pattern = new RegExp("[`~!@#$^&*()=|{}‘:;‘,\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“‘。，、？%+_]");
      var specialStr = '';
      for ( var i = 0; i < str.length; i++) {
        specialStr += str.substr(i, 1).replace(pattern, '');
      }
      return specialStr;
    },
  }
}
</script>

<style lang="scss" scoped>
  body {
  font: 300 14px/18px Roboto;
  // overflow-x:hidden;
  // overflow-y:hidden;
  // overflow: hidden;
  // height:100%;
}
  .nav_contain{
    height: 50px; // 調整icon高度 2020/08/20
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
    color: rgb(102, 102, 102);
    // left: -20px;
    // margin: 21px; // 決定位置  // 36 or 60
    // text-align: center;
    @media screen and (max-width: 320px) {
      margin: 26px;
      // margin-bottom: 20px;
    }
  }
  .nav {
    z-index: 10;
    background-color: white;
    position: fixed;
    bottom: -5px;
    width: 100%;
    height: 70px;
    // display: flex;
    // background-color: white;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.358);
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    vertical-align:middle;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    align-content: center;
  }
  .nav_link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    min-width: 30px;
    // overflow: hidden;
    white-space: nowrap;
    font-family: Roboto;
    font-size: 17px;
    // color: #505050;
  }
  .active {
    color: var(--main-color);
  }
  .loadingIcon {
    z-index: 100000;
    display: flex; // 置中
    align-items: center; // 置中
    justify-content: center; // 置中
    margin: 0 auto;
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    // margin-top: -270px;
    width: 55px;
    height: 55px;
    color: #ffffff;
    border-radius: 5px;
    background-color: #717171;
    -webkit-text-fill-color: transparent;
  }
  #layer{
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(181, 181, 181, 0.344);
  }
  .mobile-wrap {
    overflow: hidden;
    // overflow: auto;
    // height: calc(100vh);
  }
  .bottomNav {
    background-color: white;
    border-top: 1px solid #e0e0e0;
    z-index: 10000000;
    position: fixed;
    bottom: 0px;
    width: 100%;
    // padding-top: 5px;
    // padding-bottom: 0px;
    // background-color: #555;
    height: 60px;
  }
  .notificationCode {
    z-index: 10;
    // position: fixed;
    bottom: 9px;
    right: -33%;
    position: relative;
    border-radius: 0px;
  }
  .notificationCode::before,
  .notificationCode::after {
    color: #fff;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  }


  .notificationCode::after {
    font-family: Arial;
    font-size: 0.8em;
    font-weight: 700;
    padding: 2px 6px;
    line-height: 100%;
    border-radius: 80px;
    background: var(--main-color);
    opacity: 0;
    content: attr(data-count);
    transform: scale(0.6);
    transition: transform, opacity;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }

  .notificationCode.notify::before {
    animation: ring 1.5s ease;
  }

  .notificationCode.show-count::after {
    transform: scale(0.1);
    opacity: 1;
  }
</style>