<template>
  <!-- <div class="menu" :style="styleList"> -->
  <div class="menu">
    <div class="muck-up">
      <div class="overlay">
        <!-- <img src="../img/louisa.png" > -->
      </div>
      <div class="top">
        <div class="nav">
          <span class="ion-android-menu"></span>
          <p>-</p>
          <!-- <cube-button style="margin-left: 200px ;width: 40%; background-color: transparent;" @click="Dedia">取消黑卡會員</cube-button> -->
        </div>
        <div class="user-profile">
          <!-- <img :src="'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' + currentCardBackground + '.jpeg'" class="card-item__bg"> -->
          <img v-if="!personalData.profileImg" src="../img/default.jpg" class="card-item__bg">
          <img v-else :src="personalData.profileImg" class="card-item__bg" border="0">
          <div class="user-details">
            <h4>{{personalData.name}}</h4>
            <div style="margin: 10px;"></div>
            <p style="font-size: 14px;">{{brandData.name}} 數位會員卡</p>
          </div>
        </div>
      </div>
      <div class="level" v-if="this.levelContent.list && this.levelContent.list.length > 1">
        <div class="item">
          <div class="left">
            <li class="title" style="line-height: 30px; color: var(--main-color);">累積進度</li>
            <li class="title" style="line-height: 30px; font-size: 22px;"><span style="font-size: 28px;">{{ personalData.point }}/</span>{{ azureJudgePoint() ? azureJudgePoint() : '' }} </li>
          </div>
          <div class="middle">

          </div>
          <div class="right">
            <li class="title" style="line-height: 25px; color: var(--main-color);">目前等級:  <span style="font-size: 22px; font-weight: 500; color: black;">{{ azureJudgeLevel() ? azureJudgeLevel() : '一般' }}</span> 會員</li>
            <li class="title" style="line-height: 25px;" v-if="levelContent.list.length > 1 && azureJudgeName() !== '' ">累積 {{ azureJudgePoint() }}晉升{{ azureJudgeName()}}會員</li>
          </div>
        </div>
        <div style="margin-top: -20px;">
          <li class="bar" :data-skill="personalData.point "><span id="client"></span></li>
        </div>
      </div>

      <!-- <div class="clearfix"></div>
        <div class="filter-btn">
        <a id="one" href="#"><i class="ion-ios-checkmark-outline"></i></a>
        <a id="two" href="#"><i class="ion-ios-alarm-outline"></i></a>
        <a id="three" href="#"><i class="ion-ios-heart-outline"></i></a>
        <a id="all" href="#"><i class="ion-ios-star-outline"></i></a>
        <span class="toggle-btn ion-android-funnel"></span>
      </div> -->
      <div style="margin: 25px;"></div>
      <div class="bottom">
        <div class="title">
          <!-- <h3>profile</h3>
          <small>February 8,2015</small> -->
        </div>
        <ul class="tasks">
          <li class="two green">
            <span class="task-cat">姓名</span>
            <span class="task-title">{{personalData.name}}</span>
            <!-- <span class="task-time">2pm</span> -->
          </li>
          <li class="one red">
            <span class="task-cat">生日</span>
            <span class="task-title">{{personalData.birth}}</span>
            <!-- <span class="task-time">5pm</span> -->
          </li>

          <li class="one red">
            <span class="task-cat">住家地址</span>
            <span class="task-title">{{ personalData.address ? personalData.address: '-' }}</span>
            <!-- <span class="task-time">3pm</span> -->
          </li>

          <li class="two green">
            <span class="task-cat">個人電話</span>
            <span class="task-title" style="margin-left: 10px;">{{ personalData.phone }}</span>
            <!-- <span class="task-time" @click="Dedia"><i class="cubeic-edit"></i></span> -->
          </li>

          <li class="two green">
            <span class="task-cat">電子信箱</span>
            <span class="task-title">{{ personalData.email ? personalData.email : '-' }}</span>
            <!-- <span class="task-time">2pm</span> -->
          </li>

          <!-- <li class="two green">
            <span class="task-title"><button style="border: none; background-color: transparent;" @click="Dedia">取消黑卡</button></span>
            <span class="task-time">2pm</span>
            <span class="task-cat">刪除會員</span>
          </li> -->
          <!-- <li class="tow green hang">
            <span class="task-title">Team Meeting</span>
            <span class="task-time">2pm</span>
            <span class="task-cat">Hangouts</span>
            <img src="https://raw.githubusercontent.com/arjunamgain/FilterMenu/master/images/2.jpg">
            <img src="https://raw.githubusercontent.com/arjunamgain/FilterMenu/master/images/3.jpg">
            <img src="https://raw.githubusercontent.com/arjunamgain/FilterMenu/master/images/profile.jpg">
          </li> -->

        </ul>
      </div>
    </div>

  </div>
</template>

<script>
/* eslint-disable */
import { mapMutations, mapGetters, mapActions } from 'vuex'
import QrcodeVue from 'qrcode.vue';
export default {
  data () {
    return {
      platform: 'mobile',
      channelData: {
        qrcode: this.$route.query.qrcode,
        trigger: this.$route.query.trigger,
        PageID: this.$route.query.Page
      },
    }
  },
  mounted() {
    console.log(this.personalData);
    console.log(this.brandData);
    console.log(this.levelContent);
    this.calculateBar()
    // console.log(this.levelContent);
    // console.log(this.levelContent.list);
  },
  computed: {
    ...mapGetters({
      personalData: 'personalData',
      brandData: 'brandData',
      levelContent: 'levelContent'
    }),
  },
  methods: {
  ...mapActions({

    }),
    calculateBar() {
      if (this.levelContent.list.length > 1) {
        let el1 = document.getElementById('client');
        console.log(Math.round((this.personalData.point / this.azureJudgePoint() * 10000) / 100.00));
        var a = Math.round(((this.personalData.point / this.azureJudgePoint() * 10000) / 100.00)) / 70
        a = Math.floor(a * 100) / 100
        console.log(a);
        el1.style.width = Math.round((this.personalData.point / this.azureJudgePoint() * 10000) / 100.00) * 0.8  + 'vw'
      }
    },
    azureJudgePoint() {
      if (this.levelContent.list.length > 1) {
        // if (this.personalData.level === '' || this.personalData.level === '銅饗' || this.personalData.level === '一般') {
        //   return this.levelContent.list.filter(el => el.point === 68)[0].point
        // } else if (this.personalData.level === '銀饗' || this.personalData.level === '銀卡'){
        //   return this.levelContent.list.filter(el => el.point === 188)[0].point
        // }
          for (let i = 0; i < this.levelContent.list.length; i ++) {
            let biggy = this.levelContent.list.length - 1
            console.log(biggy);
            if (this.personalData.level === this.levelContent.list[0].name || this.personalData.level === '一般') {
              return this.levelContent.list[i+1].point;
            } else if (this.personalData.level === '') {
              return this.levelContent.list[0].point;
            }
            else if (this.personalData.level === biggy) {
              return ''
            } else if (this.personalData.level === i) {
              return this.levelContent.list[i+1].point;
            }
          }
      }
    },
    azureJudgeName() {
      if (this.levelContent.list.length > 1) {
        // if (this.personalData.level === '' || this.personalData.level === '銅饗' || this.personalData.level === '一般') {
        //   return this.levelContent.list.filter(el => el.point === 68)[0].name
        // } else if (this.personalData.level === '銀饗' || this.personalData.level === '銀卡'){
        //   return this.levelContent.list.filter(el => el.point === 188)[0].name
        // }
        for (let i = 0; i < this.levelContent.list.length; i ++) {
          let biggy = this.levelContent.list.length - 1
          console.log(biggy);
          // 判斷初級
          if (this.personalData.level === this.levelContent.list[0].name || this.personalData.level === '一般') {
            return this.levelContent.list[i+1].name
          } else if (this.personalData.level === '') {
            return this.levelContent.list[0].name
          }
          // 判斷最高級
          else if (this.personalData.level === biggy) {
            return ''
          // 判斷其他
          } else if (this.personalData.level === i) {
            return this.levelContent.list[i+1].name;
          }
        }
      }
    },
    azureJudgeLevel() {
      if (this.levelContent.list.length > 1) {
        for (let i = 0; i < this.levelContent.list.length; i++) {
          if (this.personalData.level === '' || this.personalData.level === this.levelContent.list[0].name || this.personalData.level === '一般') {
            return this.levelContent.list[0].name
          } else if (this.personalData.level === '') {
            return '無'
          }
          else {
          if (i === this.personalData.level) {
            return this.levelContent.list[i].name;
          }

          }
        }
        // for (let i = 0; i < this.levelContent.list.length; i++) {
        //   console.log(i);
        //   if (i === this.personalData.level) {
        //     cardLink = this.levelContent.list[i].img
        //     console.log(this.levelContent.list[i].name);
        //     console.log(cardLink);
        //   }
        // }
      }
    },
    Dedia() {
      // localStorage.removeItem('Member');
      // localStorage.removeItem('AuthToken');
      // localStorage.removeItem('Service');
      this.$createDialog({
        type: 'confirm',
        title: '系統通知',
        content: '要修改您的電話嗎？',
        icon: 'cubeic-alert',
        confirmBtn: {
          text: '確定',
          active: true,
          disabled: false,
          href: 'javascript:;'
        },
        cancelBtn: {
          text: '取消',
          active: false,
          disabled: false,
          href: 'javascript:;'
        },
        onConfirm: () => {
          this.dialog = this.$createDialog({
            type: 'prompt',
            title: '請輸入電話',
            prompt: {
              value: this.memberAccount,
              placeholder: ''
            },
            confirmBtn: {
              text: '確定',
              active: true,
              disabled: false,
              href: 'javascript:;'
            },
            cancelBtn: {
              text: '上一頁',
              active: true,
              disabled: false,
              href: 'javascript:;'
            },
            onCancel: () => {
              this.Dedia()
            },
            onConfirm: (e, promptValue) => {
              this.loading = true;
              // promptValue = null;
              // setTimeout(() => {
              //   this.addCode();
              this.checkPhoneNumbers({ phone: promptValue, clientId: this.info.clientId,})
              .then((res) => {
                // console.log(res);
                if (res.data.result.phone) {
                  const contents = '更改電話已存在!'
                  this.showAlert1(contents)
                  this.loading = false;
                  return;
                } else if (this.phone === promptValue) {
                  const contents = '更改電話與當前電話相同!'
                  this.loading = false;
                  return this.showAlert1(contents)
                // return alert('更改電話與當前電話相同。')
                } else if (promptValue === ''){
                  const contents = '請輸入修改電話'
                  this.loading = false;
                  return this.showAlert1(contents)
                }
              // if (this.phone === promptValue) {
              //   const contents = '更改電話與當前電話相同!'
              //   return this.showAlert1(contents)
              //   // return alert('更改電話與當前電話相同。')
              // } else if (promptValue === ''){
              //   const contents = '請輸入修改電話'
              //   return this.showAlert1(contents)
              // }
              this.newPhone = promptValue;
              const re = /^[0-9]+$/;
              if (!re.test(promptValue)) {
                const contents = '請輸入正確格式'
                this.showAlert1(contents)
                this.Dedia()
              } else if (promptValue.length > 10) {
                const contents = '請輸入正確數字'
                this.showAlert1(contents)
                this.Dedia()
              } else {
                this.loading = true;
                //
                const sixcodes = `${Math.floor(Math.random()*(9-0+1))+0}${Math.floor(Math.random()*(9-0+1))+0}${Math.floor(Math.random()*(9-0+1))+0}${Math.floor(Math.random()*(9-0+1))+0}${Math.floor(Math.random()*(9-0+1))+0}${Math.floor(Math.random()*(9-0+1))+0}`;
                this.tempCode = sixcodes;
                // service:FB OR LINE ,gate: channel ID or FB ID
                const words = `您好，感謝您修改${this.brand} Taiwan會員資料，您的驗證碼為:`;
                const wordsSecond = `記得完成驗證步驟，繼續與我們一同品嘗好茶喔`
                // promptValue = '';
                // this.addCode();
                // return;
                //
                this.ChTextMsg({ phone: promptValue, msg: words + sixcodes + '' + wordsSecond, brand: this.equal,})
                .then((res)=> {
                  // console.log(res);
                  const empty = '';
                  promptValue = empty
                  if (res.data.text !== 'mobile tag error') {
                    this.b();
                    const toast = this.$createToast({
                      time: 1500,
                      txt: '驗證碼已送出'
                    })
                    toast.show()
                    this.loading = false;
                    // promptValue = empty
                    setTimeout(() => {
                      this.addCode();
                    },1500)
                  } else {
                    this.showAlert(res.data.text);
                    this.loading = false;
                  }
                });
                //
              }
              });
            },
          }).show()
        },
      }).show()
    },
    showAlert1(contents) {
      this.$createDialog({
        type: 'alert',
        title: '小提示',
        content: contents,
        icon: 'cubeic-alert',
        confirmBtn: {
          text: '確定',
          active: true,
          disabled: false,
          href: 'javascript:;'
        },
      }).show()
    },
    showAlert(val) {
      this.$createDialog({
        type: 'alert',
        title: '小提示',
        content: val,
        confirmBtn: {
          text: '確定',
          active: true,
          disabled: false,
          href: 'javascript:;'
        },
        // icon: 'cubeic-alert'
      }).show()
    },

    addCode() {
      this.dialog = this.$createDialog({
        type: 'prompt',
        title: '請輸入驗證碼',
        prompt: {
          value: '',
          placeholder: '請輸入'
        },
        confirmBtn: {
          text: '確定',
          active: true,
          disabled: false,
          href: 'javascript:;'
        },
        cancelBtn: {
          text: '取消',
          active: false,
          disabled: false,
          href: 'javascript:;'
        },
        onConfirm: (e, promptValue) => {
          this.loading = true;
          // console.log(promptValue);
          // console.log(this.info.clientId);
          // console.log(this.newPhone);
          if (promptValue.length > 6) {
            promptValue = promptValue.slice(0, 6)
            if (this.tempCode === promptValue) {
              this.updatePhoneNumber({clientId: this.info.clientId, oldPhone: this.phone, newPhone: this.newPhone,})
              .then(() => {
                this.loading = false;
                const toast = this.$createToast({
                  txt: `電話更新成功`,
                  type: 'correct'
                })
                toast.show()
                window.location.reload();
              })
            } else {
              this.loading = false;
              const toast = this.$createToast({
                txt: `驗證碼錯誤`,
                type: 'correct'
              })
              toast.show()
              setTimeout(()=>{
                toast.hide()
              },700)
              this.addCode()
              // window.location.reload();
            }
          } else if (this.tempCode === promptValue) {
            // console.log(this.tempCode);
            this.updatePhoneNumber({clientId: this.info.clientId, oldPhone: this.phone, newPhone: this.newPhone,})
            .then((res) => {
              // console.log(res);
              this.loading = false;
              const toast = this.$createToast({
                txt: `電話更新成功`,
                type: 'correct'
              })
              toast.show()
              window.location.reload();
            })
          } else {
            this.loading = false;
            const toast = this.$createToast({
              txt: `驗證碼錯誤`,
              type: 'correct'
            })
            toast.show()
            setTimeout(()=>{
              toast.hide()
            },700)
            this.addCode()
          }
        },
      }).show()
    },
    a() {
      this.number -= 1;
    },
    b() {
      this.number = 300;
      var si = setInterval(this.a, 1000);
      setTimeout(() => {
        this.number = '';
        // this.phoneVerify = true;
        clearInterval(si);
      }, 300000);
    },
  }
}
</script>

<style lang="scss" scoped>
  body {
    font: 300 14px/18px Roboto
  }

  *,
  :after,
  :before {
    box-sizing: border-box
  }

  .clearfix:after,
  .clearfix:before {
    content: '';
    display: table
  }

  .clearfix:after {
    clear: both;
    display: block
  }
  .level {
    height: 100px;
    // background-color: blanchedalmond;
  }
  .item {
    margin-top: -10px;
    display: flex;
    //overflow-y: auto;
    align-items: center;
    justify-content: space-between;
    align-content: space-between;
    border-bottom: 0px solid rgba(210, 210, 210, 0.815);
    height: 75px;
    padding: 0px;
    color: rgba(48, 48, 48, 0.851);
  }
  .item .middle .title {
    margin-top: -20px;
    color: rgba(118, 118, 118, 0.851);
    font-size: 16px;
    font-weight: 500;
    width: 190px;
    display: flex;
    letter-spacing: 1px;
    justify-content: space-between;
  }
  .item .middle .entitle {
    margin-top: 5px;
    // transform: scale(0.8);
    margin-left: 1px;
    color: rgba(142, 142, 142, 0.851);
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1px;
  }
  .item .right {
    background-clip: text;
    font-size: 17px;
    // width: 70px;
    // -webkit-text-fill-color: transparent;
    // border: 1px solid rgba(112, 88, 88, 0.877);
    // color:#F37423;
    padding: 0px;
    // transform: translateX(-20px);
  }
  .bar {
    position: relative;
    margin: 0 auto;
    height: 28px;
    width: 85vw;
    border-radius: 55px;
    // background: linear-gradient(145deg, #fa6565, #d35555);
    background: var(--main-color);
    // box-shadow: 2px 1px 7px #c75050, -2px -1px 7px #ff6c6c,
    //   inset 2px 2px 7px #c75050, inset -2px -1px 7px #ff6c6c;
  }
  .bar::before {
    content: attr(data-skill);
    z-index: 1000000;
    position: absolute;
    top: 5px;
    right: 15px;
    font-weight: 700;
    font-size: 16px;
  }
  #client {
    position: absolute;
    content: "";
    left: 6px;
    top: 5px;
    background: #fff;
    opacity: 0.97;
    height: 65%;
    border-radius: 55px;

  }
  // #client {
  //   width: 49vw;
  // }
  // .menu {
  //   overflow: scroll;
  //   height: calc(100vh - 70px);
  //   z-index: 1;
  //   padding-left: 0!important;
  //   padding-right: 0!important;
  //   margin: 0 auto;
  //   width: 100%;
  //   max-width: 500px;
  //   background-color: #ffffff;
  //   /* border: 1px solid rgb(170, 170, 170); */
  //   /* border:1px solid #8a8a8a; */
  // }
  .menu {
    z-index: 0;
    padding-left: 0!important;
    padding-right: 0!important;
    margin: 0 auto;
    overflow: hidden;
    overflow-x: hidden;
    width: 100%;
    max-width: 540px;
    // height: 650px;
    background-color: #ffffff;
    /* border: 1px solid rgb(170, 170, 170); */
    /* border:1px solid #8a8a8a; */
  }
  .menu:after {
    content: '';
    position: relative;
    top: 0px;
    background: var(--main-color);
    opacity: 0.025;
    left: 0;
    right: 0;
    height: 500px;
    transform: rotate(10deg);
    min-width: 70px;
    z-index: 1;
  }
  // .muck-up {
  //   z-index: 1;
  //   min-width: 310px;
  //   height: 100%;
  //   margin: 0em auto;
  //   position: relative;
  //   overflow: hidden;
  // }

.overlay {
  // background-color: var(--main-color);
  // background: url(https://raw.githubusercontent.com/arjunamgain/FilterMenu/master/images/header.jpg) no-repeat top /contain;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.overlay:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  // background: rgba(71, 32, 84, 0.5);
}

.top {
  // z-index: 100000;
  position: relative;
  min-height: 190px;
  padding: 25px;
  color: rgb(159, 159, 159);
}
// .muck-up > .top {
//   // z-index: 100000;
//   // position: relative;
//   min-height: 250px;
//   padding: 25px;
//   color: #fff;
// }

.top .nav span {
  float: left;
  display: block;
}

.nav p {
  margin-top: 2px;
  display: inline-block;
  // float: left;
  vertical-align: bottom;
}

.ion-android-menu {
  font-size: 24px;
  margin-right: 17px;
}

.nav .ion-ios-more-outline {
  font-size: 38px;
  float: right !important;
}

.user-profile {
  margin-top: 50px;
  // z-index: 10000000;
}

.user-profile img {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  float: left;
  margin-right: 24px;
}

.user-details p {
  font-size: 11px;
}

.user-details {
  color: rgb(52, 52, 52);
  float: left;
  margin-top: 23px;
  vertical-align: bottom;
}

.user-details h4 {
  font-size: 25px;
}

.filter-btn {
  position: absolute;
  z-index: 2;
  right: 0;
  width: 40px;
  height: 40px;
  transition: all 0.4s ease-in-out 0s;
}

.filter-btn span {
  width: 40px;
  height: 40px;
  background: #FA396B;
  display: block;
  position: absolute;
  right: 25px;
  top: -46px;
  text-align: center;
  color: #fff;
  line-height: 40px;
  border-radius: 50%;
  font-size: 22px;
  z-index: 999;
}
span.toggle-btn.ion-android-funnel:hover{
      cursor: pointer;
}
.filter-btn a {
  position: absolute;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  right: 25px;
  display: block;
  top: -46px;
  color: #fff;
  z-index: 99;
  font-size: 22px;
  transition: all .4s cubic-bezier(.68, 1, .265, 1)
}

.filter-btn:after {
  height: 170px;
  width: 170px;
  content: '"\f38b"';
  background-color: #FA396B;
  position: absolute;
  top: -110px;
  right: -40px;
  border-radius: 50%;
  transform: scale(0);
  transition: all 0.3s ease-in-out 0s;
}

.filter-btn.open span.toggle-btn.ion-android-funnel {
  background-color: #DE3963;
}

.filter-btn.open .ion-android-funnel:before {
  content: "\f2d7";
}

.open:after {
  transform: scale(1);
}

.filter-btn.open a:nth-child(1) {
  transform: translate(9px, -62px);
}

.filter-btn.open a:nth-child(2) {
  transform: translate(-50px, -34px);
}

.filter-btn.open a:nth-child(3) {
  transform: translate(-56px, 25px);
}

.filter-btn.open a:nth-child(4) {
  transform: translate(5px, 61px);
}

.muck-up .bottom {
  // top: 10px;
  background-color: #fff;
  min-height: 350px;
  z-index: 1;
  padding: 35px;
  position: relative;
  color: #222;
  padding-top: 0px;
}

.bottom:after {
  content: '';
  position: absolute;
  top: 40px;
  background: #fff;
  left: -22px;
  right: 0;
  height: 100px;
  transform: rotate(10deg);
  min-width: 400px;
  z-index: -1;
}

.bottom .title {
  margin-bottom: 20px;
}

.bottom .title h3 {
  font-size: 22px;
  margin-bottom: 5px;
}

ul.tasks {
  height: 500px;
}

.title small {
  font-size: 10px;
  color: #888;
  text-transform: uppercase;
  letter-spacing: 1px;
}

ul.tasks .task-title {
  letter-spacing: 0.5px;
  font-size: 15px;
  display: inline-block;
  color: #888;
  border-bottom: 1px solid rgba(210, 210, 210, 0.815);
}

ul.tasks .task-time {
  float: right;
  font-size: 10px;
  color: #888;
}

ul.tasks .task-cat {
  margin: 15px;
  font-size: 19px;
  display: block;
  // color: #888;
}

ul.tasks li {
  margin-bottom: 5px;
  position: relative;
  z-index: 8;
}

ul.tasks li:after {
  content: '';
  position: absolute;
  left: -18px;
  top: 8px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
}

// ul.tasks li.red:after {
//   background: #FF3163;
// }

// ul.tasks li.green:after {
//   background: #54D6C7;
// }

// ul.tasks li.yellow:after {
//   background: #EAB429;
// }

// ul.tasks::after {
//   content: '';
//   position: absolute;
//   height: 400px;
//   width: 1px;
//   background: #eee;
//   left: 20px;
//   top: -68px;
// }

ul li.hang {
  margin-bottom: 48px;
}

ul li.hang img {
  float: left;
  height: 20ox;
  width: 20px;
  border-radius: 50%;
  margin-right: 8px;
}
</style>
