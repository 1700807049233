<template>
  <div class="menu">
    <div v-if="cascasdeStatus === false">
      <iframe width="100%" :src="brandData.iframe" style="height: 100vh; border: 0 padding-bottom: 70px;"
        frameborder="0" allowfullscreen>
      </iframe>
    </div>
    <div v-else>
      <i  v-if="showShop === true && azureUrl === ''" @click="showShop = false" class="cubeic-back" style="position: fixed; top: 7px; left: 10px; transform: scale(1.3);" ></i>
      <div>
      <div class="pic" v-if="cascasdeUrl === '' && azureUrl === ''">
        <img :src="eilisProfile.logo" style="margin-left: 5px; margin-top: -20%;" alt="louisa logo"  width="100%">
        <div v-if="showShop === false">
          <div style="margin: 25px auto; font-size: 22px; ">-請選擇線上訂餐品牌-</div>
          <cube-form :model="model">
            <cube-form-group>
              <cube-form-item  class="item" style="border: 1px solid #eee; width: 80%" :field="fields[0]">
                <button style="font-size: 16px; border: none; background: none; color: black; outline: none;" @click="showBrandPicker">{{model.brand || '請選擇品牌'}}</button>
              </cube-form-item>
            </cube-form-group>
          </cube-form>
          <cube-button id="sign-in-button" class="item"  style="transform: scale(0.9)" @click="getShop">確認</cube-button>
        </div>

        <div v-if="showShop === true">
          <div style="margin: 25px auto; font-size: 22px; ">-請選擇線上訂餐門市-</div>
          <cube-form :model="model">
            <cube-form-group>
              <cube-form-item  class="item" style="border: 1px solid #eee; width: 80%" :field="fields[1]">
                <button style="font-size: 16px; border: none; background: none; color: black; outline: none;" @click="showShopPicker">{{model.saleroom || '請選擇門市'}}</button>
              </cube-form-item>
            </cube-form-group>
          </cube-form>
          <cube-button id="sign-in-button" class="item"  style="transform: scale(0.9)" @click="doAzureUrl">確認</cube-button>
        </div>
      </div>
      <div v-else>
        <iframe class="ifr" width="100%" :src="cascasdeUrl ? cascasdeUrl : azureUrl" style="height: 92vh; border: 0; padding-bottom: 70px;"
          frameborder="0" allowfullscreen>
        </iframe>
       </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapMutations, mapGetters, mapActions, createNamespacedHelpers} from 'vuex'

export default {
  data () {
    return {
      model: {
        brandId: '',
        saleroom: '',
        brandKey: '',
        brand: '',
        posId: '',
      },
      fields: [
        {
          type: 'checkbox-group',
          modelKey: 'brand',
          label: '品牌',
          props: {
            confirmTxt: '確定',
            title: '請選擇',
            placeholder: '',
            options: [],
          },
          messages: {
            required: '此為必填'
          },
          rules: {
            required: true
          }
        },
        {
          type: 'checkbox-group',
          modelKey: 'saleroom',
          label: '門市',
          props: {
            confirmTxt: '確定',
            title: '請選擇',
            placeholder: '',
            options: [],
          },
          messages: {
            required: '此為必填'
          },
          // rules: {
          //   required: false
          // }
        },
      ],
      shopList: '',
      azure: this.azureShopList,
      cascasdeUrl: '',
      cascasdeStatus: false,
      showBrand: false,
      showShop: false,
    }
  },
  props: [],
  mounted() {
    // this.$loading.show();
    this.checkCascasde()
    console.log(this.azureUrl);
    console.log(this.personalData);
    console.log(this.brandData);
    console.log(this.eilisProfile);
    console.log(this.cascasdeUrl);
    setTimeout(() => {
    console.log(this.azureShopList);
    },500)
  },
  computed: {
    ...mapGetters({
      personalData: 'personalData',
      brandData: 'brandData',
      azureShopList: 'azureShopList',
      azureUrl: 'azureUrl',
      eilisProfile: 'eilisProfile',
    }),
    tempAzureUrl: {
      get () { return this.azureUrl },
      set(value) { this.storeAzureUrl({ azureUrl: value }); },
    },
  },
  methods: {
    ...mapActions({
      getAzureUrl: 'getAzureUrl',
      getAzureBrandList: 'getAzureBrandList',
      getAzureShopList: 'getAzureShopList',
    }),
    ...mapMutations({
      storeAzureUrl: 'storeAzureUrl'
    }),
    showShopPicker() {
      console.log(this.shopList);
      let arr = []
      for (let i = 0; i< this.shopList.length; i++ ) {
        arr[i] = {
          text: this.shopList[i].shopName,
          value: this.shopList[i].brandId,
          zips: this.shopList[i].zips,
          posId: this.shopList[i].posId,
        }
      }
      console.log(arr);
      this.$createPicker({
        title: '選擇分店',
        data: [arr],
        confirmTxt: '確定',
        onSelect: this.selectShop
      }).show()
    },
    selectShop(selectedVal, selectedIndex, selectedTxt) {
      console.log(selectedVal);
      console.log(selectedIndex);
      console.log(selectedTxt);
      this.model.brandId = selectedVal
      this.model.posId = this.shopList[selectedIndex].posId
      this.model.saleroom = `${selectedTxt}`
    },
    showBrandPicker() {
      console.log(this.azureShopList);
      let arr = []
      for (let i = 0; i< this.azureShopList.length; i++ ) {
        arr[i] = {
          text: this.azureShopList[i].brandName,
          value: this.azureShopList[i].brandKey,
          zips: this.azureShopList[i].zips
        }
      }
      console.log(arr);
      this.$createPicker({
        title: '選擇品牌',
        data: [arr],
        confirmTxt: '確定',
        onSelect: this.selectBrand
      }).show()
    },
    selectBrand(selectedVal, selectedIndex, selectedTxt) {
      console.log(selectedVal);
      this.model.brandKey = selectedVal
      // this.model.brandId = '10849'
      this.model.brand = `${selectedTxt}`
    },
    getShop() {
      this.$loading.show();
      if (!this.model.brandKey[0]) {
        this.$loading.hide();
        return
      } else {
        this.getAzureShopList({
          accountId: this.eilisProfile.accountId,
          brandKey: this.model.brandKey[0],
          zips: '',
        })
        .then((res) => {
          console.log(res);
          this.shopList = res.data.result
          this.$loading.hide();
          this.showShop = true;
          this.showBrand = true;
        })
      }
    },
    doAzureUrl() {
      this.$loading.show();
      if (!this.model.brandId[0]) {
        this.$loading.hide();
        return
      } else {
        this.getAzureUrl({
          accountId: this.eilisProfile.accountId,
          brandId: this.model.brandId[0],
          posId: this.model.posId,
          phone: this.personalData.phone,
          point: this.personalData.point,
          name: this.personalData.name,
          birth: this.personalData.birth.replace('-','').replace('-',''),
        })
        .then((res) => {
          this.$loading.hide();
          console.log(res);
          if (res.data.result !== '取得訂餐網址錯誤') {
            this.tempAzureUrl = res.data.result.OrderURL
            this.cascasdeUrl = res.data.result.OrderURL
          } else {

            this.showShop = true;
          }
        })
      }
    },
    checkCascasde() {
      if (!this.azureUrl) {
        this.$loading.show();
        if (this.brandData.azure && this.brandData.azure === true) {
          this.cascasdeStatus = true
          this.$loading.hide();
        } else {
          this.$loading.hide();
          this.cascasdeStatus = false
        }
      } else {
        this.$loading.hide();
        // var iframe = document.querySelector('iframe[src=""]');
        // console.log(iframe);
        // iframe.src = '';
        this.$createDialog({
          zIndex: 100000000000,
          type: 'confirm',
          title: '系統提示',
          confirmBtn: '確定',
          content: '是否選擇其他品牌以及其他門市?',
          icon: 'cubeic-alert',
          confirmBtn: {
            text: '確定',
            active: true,
            disabled: false,
            href: 'javascript:;'
          },
          // cancelBtn: {
          //   text: '取消',
          //   active: true,
          //   disabled: false,
          //   href: 'javascript:;'
          // },
          onConfirm: () => {
            this.cascasdeStatus = true;
            this.tempAzureUrl = ''
            this.cascasdeUrl = ''
            this.showShop = false;
            this.showBrand = false;
            // this.$loading.hide();
          },
          onCancel: () => {
          },
        }).show()
      }
    }
  }
}
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,600&display=swap');

  body {
    font: 300 14px/18px Roboto;
    padding-bottom: 100px;
  }

  .menu {
    z-index: 0;
    padding-left: 0!important;
    padding-right: 0!important;
    margin: 0 auto;
    overflow: hidden;
    height: 100vh;
    width: 100%;
    max-width: 540px;
    // height: 650px;
    background-color: #ffffff;
    /* border: 1px solid rgb(170, 170, 170); */
    /* border:1px solid #8a8a8a; */
  }
  .pic {
    position: relative;
    z-index: 1;
    transform: scale(1);
    // top: 30px;
    margin-top: 100px;
    margin-left: 0px;
    height: 100%;
  }
  .item {
    // z-index: 1;
    // border-radius: 10px;
    font-size: 16px;
    width: 80%;
    max-width: 420px;
    min-width: 200px;
    margin: 0 auto 25px ;
  }
</style>

