<template>
<div class="box">
  <!-- <div id="layer" v-if="loadingIcon"/>
  <cube-loading :size="35" class="loading" v-if="loadingIcon"></cube-loading> -->
  <!-- <CubePage @click.native="getCity"/> -->
  <div v-if="azureUrl">
    <iframe class="ifr" width="100%" :src="azureUrl" style="height: 101vh; border: 0; padding-bottom: 70px;"
      frameborder="0" allowfullscreen>
    </iframe>
  </div>
  <div style="z-index:0;" v-if="beforeStep === '1' && !azureUrl">
    <!-- 選擇城市 -->
    <div class="top" v-if="showList === false">
      <ul class="list">
        <div class="item">
          <!--DEV NOTE:20210914 修改附近門市常用門市-->

          <div class="input-container" > <!--@click="showNearBy"-->
            <div class="left" style="font-size: 18px; font-weight: 400; margin-left:5px;">
              <!-- DEV NOTE:20210914 先拿掉附近門市
              <input id="bike" class="radio-button1" type="radio" name="radio" />
              <div class="radio-tile">
                <label for="bike" class="radio-tile-label"></label> 
                
                <label for="bike" class="radio-tile-label">附近門市</label>
                
              </div>
              -->
            </div>
          </div>
          
          <div class="input-container" @click="toGeneral"> <!--@click="toGeneral"-->
            <div class="middle" style="font-size: 18px; font-weight: 400; margin-left:20px;" >
              <!-- DEV NOTE:20210914 先拿掉常用門市
              <input id="drive" class="radio-button" type="radio" name="radio" />
              <div class="radio-tile">
                <label for="drive" class="radio-tile-label">常用門市</label> 
                <label for="drive" class="radio-tile-label"></label>
              </div>
              -->
            </div>
          </div>
          

          <div class="input-container" @click="showCityList">
            <div class="right" style="font-size: 18px; font-weight: 400; margin-left: 20px;">
              <input id="drive" class="radio-button2" type="radio" name="radio" />
              <div class="radio-tile">
                <label for="drive" class="radio-tile-label">{{ place ? place : '選擇門市' }}<i class="cubeic-pulldown" style="transform:scale(1.6);"></i></label>
              </div>
            </div>
          </div>
        </div>
      </ul>
    </div>
    <br>
    <div class="dia" v-if="!azureUrl && (showList !== true && place === '') && !nearbyListShow ">
      <img  :src="eilisProfile.logo" style="margin-top: 90px; transform: scale(1); text-align:center; opacity: 0.8;" alt=" logo"  width="100%">
      <div style="color: #3F3F3F; font-size: 18px; margin-top: 100px; ">請選擇您的所在地與門市進行消費。</div>
    </div>

    <div class="dia" v-show="nearbyListShow === true && notSupport === true">
      <img :src="eilisProfile.logo" style="margin-top: -90px; transform: scale(0.35); text-align:center; opacity: 0.5;" alt="logo"  width="100%">
      <div style="color: #3F3F3F; margin-top: -100px; ">您的裝置不支援此功能</div>
      <!-- <div style="transform: scale(5); width: 50px; height: 20px;margin: 35px auto;">
        <i class="cubeic-warn"></i><br><br>
      </div> -->
    </div>
    <!-- 附近門市 -->
    <div v-if="nearbyListShow === true">
      <!-- 地圖區域 -->
      <div class="map" style="height: 240px; overflow: hidden; position: relative" >
        <iframe
          width="102%"
          height="105%"
          frameborder="0"
          style="border:0"
          loading="lazy"
          :src="locate"
          allowfullscreen>
        </iframe>
      </div>
      <!-- 門市清單 -->
      <div class="container" >
        <ul class="list">
          <div class="item" style="height: 100px;" v-for="(item, index) in LocationShop" :key="index" @click="locationShop(item.name, item.shopNo, item.address)">
            <div class="left" style="height: 100px;">
              <li class="title" :style="{color: (shopAddress === item.address) ? 'var(--main-color)' : defaultColor }">{{ item.shopName }}</li>
              <li class="entitle">{{ item.openTime }}</li>
              <li class="entitle">{{ item.address }}</li>
              <li class="entitle">{{ item.phone }}</li>
            </div>
            <div class="right" @click="doAzureUrl(item.shopName,item.shopNo,item.brandId,item.posId, item.poiId, item.secretkey, item.openTime)"><i class="cubeic-arrow"  style="transform: scale(1.25);"></i></div>
          </div>
        </ul>
      </div>
    </div>
    <!-- 附近門市 -->

    <!-- 喜好門市 -->
    <div v-if="favoriteListShow === true && favoriteList.length > 0">
      <!-- 地圖區域 -->
      <div class="map" style="height: 240px; overflow: hidden; position: relative" >
        <iframe
          width="102%"
          height="105%"
          frameborder="0"
          style="border:0"
          loading="lazy"
          :src="locate"
          allowfullscreen>
        </iframe>
      </div>
      <!-- <div style="text-align:left; margin: 15px; margin-top: -20px;"></div> -->
      <!-- 門市清單 -->
      <div class="container" >
        <ul class="list">
          <div class="item" style="height: 110px;" v-for="(item, index) in favoriteListDetail" :key="index" @click="locationShop(item.name, item.shopNo, item.address)">
            <div class="left" style="height: 100px;">
              <li class="title" :style="{color: (shopAddress === item.address) ? activeColor : defaultColor }">{{ item.name }}</li>
              <li class="entitle">{{ item.openTime }}</li>
              <li class="entitle">{{ item.address }}</li>
              <li class="entitle">{{ item.shopPhone }}</li>
            </div>
            <div class="right" @click="doAzureUrl(item.shopName,item.shopNo,item.brandId,item.posId, item.poiId, item.secretkey, item.openTime)"><i class="cubeic-arrow"  style="transform: scale(1.25);"></i></div>
            <!-- <div class="right"><i class="cubeic-info"></i></div> -->
          </div>
        </ul>
      </div>
    </div>
    <!-- 喜好門市 -->

    <!-- 一般條件門市 -->
    <div v-if="place !== ''">
      <!-- 地圖區域 -->
      <div class="map" style="height: 240px; overflow: hidden; position: relative" >
        <iframe
          width="102%"
          height="105%"
          frameborder="0"
          loading="lazy"
          style="border:0"
          :src="locate"
          allowfullscreen>
        </iframe>
      </div>
      <!-- 門市清單 -->
      <div class="container" >
        <ul class="list">
          <div class="item" style="height: 110px;" v-for="(item, index) in citylist" :key="index" @click="locationShop(item.name, item.shopNo, item.address)">
            <div class="left" style="height: 100px;">
              <li class="title" :style="{color: (shopAddress === item.address) ? 'var(--main-color)' : defaultColor }">{{ item.shopName }}</li>
              <!-- DEV NOTE: 20210916 ping拿掉開店時間 -->
              <!-- <li class="entitle">{{ item.openTime }}</li> -->
              <li class="entitle">{{ item.address }}</li>
              <li class="entitle">{{ item.phone }}</li>
            </div>
            <div class="right" @click="doAzureUrl(item.shopName,item.shopNo,item.brandId,item.posId, item.poiId, item.secretkey, item.openTime)"><i class="cubeic-arrow"  style="transform: scale(1.25);"></i></div>
            <!-- <div class="right"><i class="cubeic-info"></i></div> -->
          </div>
        </ul>
      </div>
    </div>
    <!-- 一般條件門市 -->

    <!-- 城市清單 -->
    <div class="box" v-if="showList === true">
      <cube-page @trans="getCity" @transCity="getWhole" type="index-list" title="選擇城市" @showList="upsidedown" @favoriteList="showFavorite">
      <div slot="content">
        <button class="cityButton" v-for="(city,index) in citydata" :key="index" @click="toCity(city.name)">{{ city.name }}</button>
        <div class="view-wrapper">
        <br>
        <br>
        <br>
          <div class="index-list-wrapper">
            <!-- <button class="cityButton" v-for="(city,index) in citydata" :key="index" @click="toCity(city.name)">{{ city.name }}</button> -->
            <cube-index-list
              ref="indexList"
              :data="citydata"
              :title="title"
              :options="options"
              @select="selectItem"
              @title-click="clickTitle"
              @pulling-up="onPullingUp">
            </cube-index-list>
          </div>
        </div>
      </div>
      </cube-page>
    </div>
  </div>

  <div v-if="beforeStep === '2'">
  <div class="nameHint" v-if="drinkDialog !== 'out'" @click="test(shopName)" ><div style="margin: 10px;"><i class="cubeic-location"></i>{{ shopName }}</div></div>
  <!-- <cube-loading :size="35" class="loading" v-if="loading"></cube-loading>
  <div id="layer" v-if="loading"/> -->
  <!-- 菜單品項內容 -->
  <div class="drinkItem" v-if="chosenShop === true">
    <cube-scroll-nav
      :side="true"
      :data="itemData"
      :current="current"
      @change="changeHandler"
      @sticky-change="stickyChangeHandler">
      <div style="height: 15px;"></div>
      <cube-scroll-nav-panel class="cube-scroll-nav-panel"
        v-for="item in itemData"
        :key="item.name"
        :label="item.name"
        :title="item.name">
      <div class="item-box" ref="bannerImg">
        <ul class="list" v-for="(food,index) in item.item" :key="index" @click="decideItem(food)">
          <div class="item">
            <div class="left" :style="{width: '80px', opacity: food.isOutOfStock === true ? 0.4 : 1}">
              <img :src="food.webImageLink" style="width:60px; height: 35px; overflow: hidden; backgroundSize: cover; backgroundPosition: center; border-radius:3px; margin-left: -5px; margin-top: -20px;" >
              <!-- <div style="display: inline-block; width: 40px; height: 70px; overflow: hidden; backgroundSize: cover; backgroundPosition: center;"><img style="width: 60px; height: 60px; position:absolute; overflow: hidden; clip:rect(0px,200px,200px,0px); border-radius: 5px;" :src="food.webImageLink" /></div> -->
            </div>
            <div class="middle" style="width:180px; margin-left: 5px; margin-top: -5px">
              <li class="title" :style="{ opacity: food.isOutOfStock === true ? 0.4 : 1, fontSize: deviceWidth <= 375 && (food.itemName.length > 7) ? '15px' : '16px'}" >{{ food.itemName }} </li>
              <li class="entitle">{{ food.itemNameAlt && food.itemNameAlt.length > 22 ? food.itemNameAlt.substr(0, 22) + '...' : food.itemNameAlt   }}</li>
              <!-- <li class="entitle">{{ food.preset }}</li> -->
              <!-- <li class="title" style="font-size: 14px; color: #F37423; margin-top: 6px ; margin-left: 2px;">NT.{{ food.price.L ? food.price.L : food.price.M ? food.price.M : food.f_price}}</li> -->
              <li class="title" style="font-size: 14px; color: var(--main-color); margin-top: 6px ; margin-left: 2px;"><span :style="{opacity: food.isOutOfStock === true ? 0.4 : 1}">NT.{{ food.price }}</span></li>
            </div>
            <div class="right" style="color: var(--main-color);"><i :style="{opacity: food.isOutOfStock === true ? 0.4 : 1}" class="cubeic-add"/></div>
          </div>
        </ul>
      </div>
      </cube-scroll-nav-panel>
    </cube-scroll-nav>
  </div>

    <!-- 品項彈出內容 -->
    <div class="drinkPopDia" v-if="drinkDialog === 'out'">
      <div class="movePart">
        <div class="drinktop">
          <div></div>
          <div></div>
          <!-- DEV NOTE: 20210914 新增左上角‘返回菜單’ -->
          <div class="bottom_left">
            <cube-button
              class="bottom_left_button"
              @click="close"
              :primary="true"
              >返回菜單</cube-button>
          </div>
          <!-- DEV NOTE: 20210914 新增左上角‘返回菜單’ -->
          <!-- DEV NOTE: 20210914 關閉原本的‘關閉按鈕’ -->
          <!--
          <div style="height: 13px; width:13px ; border-radius: 999em; margin:30px -15px 30px 40px; transform: scale(1.9); color: var(--main-color);" @click="close"><i  class="cubeic-wrong"></i></div>
          -->
          <!-- DEV NOTE: 20210914 關閉原本的‘關閉按鈕’ -->
        </div>
        <div class="img" style="width:100%; height:200px; background-color: #ffffff;">
          <!-- <img :src="img" width="100%" height="200px" > -->
          <div :style="{ overflow: 'hidden', width: `${100}%`, height: `${210}px`, backgroundImage: 'url(' + img + ')', backgroundSize: 'cover', backgroundPosition: 'center', margin: '0 auto', }"></div>
        </div>
        <br>
        <div class="title" style=" word-break: break-all;" :style="{ height: item.remark && item.remark.length > 100 ? '110px' : '60px'}">
          <div style="font-weight: 500;">{{ item.title }}</div>
          <div style="font-size:12.5px; margin: 0px  0px; letter-spacing: 0.5px; line-height:14.5px;" v-if="item.remark">備註：{{ item.remark }}</div>
        </div>
        <!-- DEV NOTE: 20210914 調整配料排版 -->
        <div class="item_contain" >
          <div class="item">
            溫度<br> 
            <input class="button" type="button" :value="ice.title" v-for="(ice, index) in ices" :key="index" @click="toggleActiveIce(ice, index)" :class="{ active: ice.active }"/>
          </div>
          <br>
          <div class="item">
            甜度<br>
            <input class="button" type="button" :value="sugar.title" v-for="(sugar, index) in sugars" :key="index" @click="toggleActiveSugar(sugar, index)" :class="{active: sugar.active}"/>
          </div>
          <br>
          <div class="item">
            加料<br>
            <input class="button" type="button" :value="food.title" v-for="(food, index) in foodsOption" :key="index" @click="toggleActiveFood(food, index)" :class="{ active: food.active }" :style="{ opacity: food.isOutOfStock === true ? 0.4 : 1}">
          </div>
        </div>
        <!-- DEV NOTE: 20210914 調整配料排版 -->
      </div>
      <!-- <div class="bottom_contain" :style="{ margin: adjest === '1' ? '-130px auto' : adjest === '2' ?  '-130px auto' : '-10px auto'}"> -->
      <div class="bottom_contain" >
        <div class="bottom_item_box">
          <ul class="bottom_list">
            <div class="bottom_item">
              <div class="bottom_left">
                <li class="title" style="width: 210px;">{{ item.price ? 'NT.': '' }} {{ item.price }}</li>
                <li class="entitle" > {{ item.title }}  {{ item.iceSelect.title }} / {{ item.sugarSelect.title }}</li>
                <li class="title" style="width:100%" v-if="item.type === '食物'" >{{ item.f_price ? 'NT.': '' }} {{ totalPrice }}</li>
                <li class="entitle" v-if="item.type === '食物' || item.AreaType === 'C'"> {{ item.title }} <span v-for="(item, index) in item.drinkSelect" :key="index + 'd'"> {{ item }}  </span> {{ item.iceSelect }} / {{ item.sugarSelect}}  <span v-for="(item, index) in item.foodSelect" :key="index"> {{ item }}  </span></li>
              </div>
              <div class="bottom_right" style="transform: scale(1.4) translateX(-25px); "><i class="cubeic-remove" style="color: var(--main-color);" @click="minus"/> <div style="color: black; display: inline-block; text-align:center; transform: scale(0.8) translateY(-2px);width:30px; height: 5px;line-height: 10px;">{{ item.num  }} </div> <i  class="cubeic-add" style="color: var(--main-color);"  @click="plus(item)"/></div>
            </div>
            <div class="bottom_item">
              <div class="bottom_left" style="color: transparent;">充值</div>
              <div class="bottom_middle"><cube-button style="color: var(--main-color);" class="bottom_middle_button" :primary="true" @click="imCheck(totalPrice)">立即購買</cube-button></div>
              <div class="bottom_right"><cube-button style="background-color: var(--main-color);" class="bottom_right_button" @click="pushIntoCart(totalPrice, item)" :primary="true">加入購物車</cube-button></div>
            </div>
          </ul>
        </div>
      </div>
    </div>

    <!-- <cube-dialog ref="dialog" title="系統通知" type="confirm" :cancelBtn="{ text: '取消',disabled: false, active: true, href: 'javascript:;' }" :confirmBtn="{ text: '確認', active: true, href: 'javascript:;' }" :showClose=true @confirm="sendOrder">
      <div slot="content" style="margin: 20px 20px; font-size:18px;">
        <span>{{ '線上訂餐規則：目前線上訂餐僅限線上訂購，' + '到店取餐付款 ( 尚未開放外送、線上支付)。'+ '<br>' +'開放店家有：輔大店' + '<br>' +'此功能會陸續開放更多店家使用' }} </span>
      </div>
    </cube-dialog> -->
  </div>
</div>
</template>

<script>
/* eslint-disable */
import { mapMutations, mapGetters, mapActions, createNamespacedHelpers } from 'vuex'
import axios from 'axios'
import CubePage from '@/components/user/CubePage.vue'
const sHeight = document.documentElement.scrollTop
const azure = createNamespacedHelpers('azure');
// const geocoder = new google.maps.Geocoder();
/* eslint-disable */
export default {
  components: {
    CubePage
  },
  data () {
    return {
      // '1718982948216030'
      whiteList: ['U7b6d287d630f71666adca971726756ac','1718982948216030','U228fbe4c030dcac20827795500514757','4926950970708178','1199453656825739', '1637534846319049', 'U8348fdf78a45c26258f59302685b4d7d', 'U0db2b8c1a0c978f907ebac3a591800c2', 'Ua84ab3d3ef0655dd967503a031ca2769', 'U5b78d6e404ba9ab8927d89ecb81ced33', '1755530031154781', '2768586729844018', 'U461207a5e6ac417a7e5e6006f027bfc1'],
      district: '',
      city: '',
      longitude: '',
      longitude: '',
      deviceWidth: document.body.clientWidth,
      ogData: [],
      // 飲料內容
      // activeColor: ,
      defaultColor: '#000',
      shopId: '',
      orderToken: '',
      secretkey: '',
      // title: '',
      // img: '',
      sizeArr: [],
      ices: [],
      sugars: [],
      drinksOption: [],
      foodsOption: [],
      iceSelect: '',
      sugarSelect: '',
      City: '',
      num: 1,
      item: {
        foodSelect: [],
        title: '',
        en_title: '',
        iceSelect: '',
        sugarSelect: '',
        num: 1,
        img: '',
        remark: '',
      },
      //
      notSupport: false,
      groupList: [],
      LocationShop: [],
      nearbyListShow: false,
      favoriteListApear: false,
      favoriteListDetail: [],
      favoriteListShow: false,
      scrollHeight: sHeight,
      tempCityInfo: {},
      adjest: false,
      drinkDialog: '',
      beforeStep: '1',
      itemData: [],
      current: '',
      chosenShop: false, // 確認店家後，進入點餐畫面的動作。
      CityandArea: [],
      citylist: [],
      place: '',
      shopName: '',
      shopAddress: '',
      location: this.locate,
      showList: false,
      page: '1',
      title: '',
      citydata: [],
      options: {
        pullUpLoad: true
      }
    }
  },
  watch: {
    change: "changeHandler",
  },
  mounted() {
    // const el = document.querySelector('.radio-button');
    // el.checked = true;
    //如果有喜好門市的條件
    // if (this.favoriteList && this.favoriteList.length > 0) {
    //   this.loadingIcon = true;
    //   this.favoriteListShow = true;
    //   this.getStoreThrNumber({
    //     shopNoList: this.favoriteList.map(el => el.shopNo),
    //   })
    //   .then((res) => {
    //     // console.log(res);
    //     this.favoriteListDetail = res.data;
    //     this.shopAddress = res.data[0].address;
    //     this.getItem()
    //     .then((res) => {
    //       // console.log(res);
    //       if (res.data) {
    //         this.itemData = this.itemList;
    //         this.current = this.itemData[0].name;
    //         this.loadingIcon = false;
    //       }
    //     })
    //   })
    // }
    // else {
    //   this.showCityList()
    //   this.favoriteListApear = true;
    // }
    this.getshop()
    this.iframe()
    // DEV NOTE:20210917MARK 合併馬力歐 
    if (this.shopDetail && this.shopDetail.secretkey && this.brandData.azure)  {
      console.log(this.shopDetail);
      // item.shopName,item.shopNo,item.brandId,item.posId, item.poiId, item.secretkey, item.openTime
      setTimeout(() => {
        this.doAzureUrl(this.shopDetail.shopName,this.shopDetail.shopNo, this.shopDetail.brandId, this.shopDetail.posId, this.shopDetail.poiId, this.shopDetail.secretkey, this.shopDetail.openTime, this.shopDetail)
      },300)
      // DEV NOTE:20210917MARK 合併馬力歐 
    } else if (this.brandData.azure && localStorage.getItem('shopcontent') && localStorage.getItem('shopcontent').secretkey){
       setTimeout(() => {
        this.doAzureUrl(JSON.parse(localStorage.getItem('shopcontent')).shopName, JSON.parse(localStorage.getItem('shopcontent')).posId, JSON.parse(localStorage.getItem('shopcontent')).brandId, JSON.parse(localStorage.getItem('shopcontent')).posId, JSON.parse(localStorage.getItem('shopcontent')).poiId, JSON.parse(localStorage.getItem('shopcontent')).secretkey, JSON.parse(localStorage.getItem('shopcontent')).openTime, JSON.parse(localStorage.getItem('shopcontent')))
      },300)
    }
    // if (this.showList === true) {
    //   this.getCity();
    // }
    const getDate = new Date().getDate().toString();
    console.log(this.itemDetail);
    console.log(this.Shop);
    // this.updateItemList = [];
    // setTimeout(() => {
    //   this.itemList
    // },1000)
    // console.log(azure);
    if (this.itemDetail.length > 0) {
      this.shopName = this.Shop.name;
      this.itemData = this.itemDetail;
      this.beforeStep = '2';
      this.chosenShop = true;
      this.place = '';
    }
    // console.log(localStorage.getItem('locatedShop'));
    // console.log(localStorage.getItem('tempCityInfo'));
    // if (localStorage.getItem('locatedShop')) {
    //   if (this.shopCart.length > 0 && this.shopTitle !== '') {
    //     this.beforeStep = '2';
    //     this.chosenShop = true;
    //     this.shopName = this.shopTitle;
    //     this.itemData = this.itemList;
    //     this.current = this.itemData[0].name;
    //     return;
    //   }
    //   if (localStorage.getItem('tempCityInfo')) {
    //     const CityInfo = JSON.parse(localStorage.getItem('tempCityInfo'));
    //     this.getStore({ city: CityInfo.city, area: CityInfo.district, })
    //     .then((res)=> {
    //       // console.log(res);
    //       if (!res.data.Error) {
    //         const lastPlace = localStorage.getItem('locatedShop');
    //         const compare = res.data.filter(el => el.name === lastPlace)
    //         // console.log(lastPlace);
    //         // console.log(compare[0].status);
    //         if (compare.length !== 0 && compare[0].status !== 'off') {
    //           if (compare[0].linePay === true && compare[0].channelId && compare[0].channelSecret) {
    //             this.linePay = true;
    //           } else {
    //             this.linePay = false;
    //           }
    //           this.loadingIcon = false;
    //           this.$createDialog({
    //             type: 'alert',
    //             zIndex: 1000000000000,
    //             showClose: true,
    //             title: '系統提示',
    //             content: '是否前往上次您購買門市？',
    //             // text: '你的訂單已成功送出，請前往訂單查詢查看',
    //             confirmBtn: {
    //               text: '確定',
    //               active: true,
    //               disabled: false,
    //             },
    //             cancelBtn: {
    //               text: '取消',
    //               active: true,
    //               disabled: false,
    //             },
    //             onConfirm: () => {
    //               if (this.itemList.length === 0 ) {
    //                 this.loadingIcon = true;
    //                 this.getItem()
    //                 .then((res) => {
    //                   this.resetItemList = res.data;
    //                   // console.log(compare[0].linePay )
    //                   const shop = localStorage.getItem('locatedShop')
    //                   const shopNum = localStorage.getItem('locatedShopNum')
    //                   this.shopPhoneNumber = localStorage.getItem('locatedShopPhoneNo')
    //                   this.loadingIcon = true;
    //                   this.beforeStep = '2';
    //                   this.chosenShop = true;
    //                   this.shopName = shop;
    //                   this.shopTitle = shop;
    //                   this.shopNumber = shopNum
    //                   this.itemData = this.itemList;
    //                   this.current = this.itemData[0].name;
    //                   this.loadingIcon = false;
    //                   this.loadingIcon = false;
    //                 })
    //               } else {
    //                 setTimeout(() => {
    //                   // console.log(localStorage.getItem('locatedShop'));
    //                   const shop = localStorage.getItem('locatedShop')
    //                   const shopNum = localStorage.getItem('locatedShopNum')
    //                   this.shopPhoneNumber = localStorage.getItem('locatedShopPhoneNo')
    //                   this.loadingIcon = true;
    //                   this.beforeStep = '2';
    //                   this.chosenShop = true;
    //                   this.shopName = shop;
    //                   this.shopTitle = shop;
    //                   this.shopNumber = shopNum
    //                   this.itemData = this.itemList;
    //                   this.current = this.itemData[0].name;
    //                   this.loadingIcon = false;
    //                 },100)
    //               }
    //             }
    //           }).show()
    //         }
    //         this.loadingIcon = false;
    //       }
    //     })
    //   }
    // } else if (localStorage.getItem('tempCityInfo')) {
    //   if (this.shopCart.length > 0 && this.shopTitle !== '') {
    //     this.beforeStep = '2';
    //     this.chosenShop = true;
    //     this.shopName = this.shopTitle;
    //     this.itemData = this.itemList;
    //     this.current = this.itemData[0].name;
    //   }
    // }
  },
  props: ['points','favoriteList'],
  computed: {
    // ...azure.mapGetters({ itemList: 'itemList'}),
    ...mapGetters({
      brandData: 'brandData',
      personalData: 'personalData',
      eilisProfile: 'eilisProfile',
      shopList: 'shopList',
      azureUrl: 'azureUrl',
      itemDetail: 'itemDetail',
      shopDetail: 'shopDetail',

      shopCart: 'shopCart',
      imCart: 'imCart',
      Shop: 'Shop',
      shopNo: 'shopNo',
      shopPhoneNo: 'shopPhoneNo',
      linePay: 'linePay'
    }),
    storeShopContent: {
      get () { return this.shopDetail },
      set(value) { this.updateShopContent({ shopDetail: value }); },
    },
    updateItemList: {
      get () { return this.itemDetail },
      set(value) { this.storeitemDetail({ itemDetail: value }); },
    },
    tempAzureUrl: {
      get () { return this.azureUrl },
      set(value) { this.storeAzureUrl({ azureUrl: value }); },
    },

    linePay: {
      get () { return this.linePay },
      set (value) { this.updateLinePayStatus({ linepay: value }); },
    },
    shopContent: {
      get () { return this.Shop },
      set (value) { this.updateShopName({ ShopName: value }); },
    },
    shopNumber: {
      get () { return this.shopNo },
      set (value) { this.updateShopNum({ ShopNum: value }); },
    },
    shopPhoneNumber: {
      get () { return this.shopPhoneNo },
      set (value) { this.updateShopPhoneNo({ ShopPhone: value }); },
    },
    shopCar: {
      get () { return this.shopCart },
      set (value) { this.updateShopCart({ shopCart: value }); },
    },
    imCar: {
      get () { return this.imCart },
      set (value) { this.updateImCart({ imCart: value }); },
    },
    calGetFree() {
      const num = this.item.num
      const getFree = this.item.getOneFree
      return {num,  getFree}
    },
    totalPrice() {
      const addFoodPrice = this.item.foodSelect.length * 10;
      // const addDrinkPrice = (this.item.drinkSelect.indexOf('超值紅茶') !== -1 ? 15 : this.item.drinkSelect.indexOf('小熱美式') !== -1 ? 25 : 0);
      return parseInt(this.item.price) + addFoodPrice
    },
    userLocation(val) {
      return `https://www.google.com/maps/embed/v1/place?key=AIzaSyCNtpQAXt_GZAzMOiQ7ZvPmejKmZfmeYJo&q=${this.shopAddress}`
    },
    locate(val) {
      // console.log(this.shopAddress);
      if (this.shopAddress === '') {
        this.shopAddress = '台北市';
        return `https://www.google.com/maps/embed/v1/place?key=AIzaSyCNtpQAXt_GZAzMOiQ7ZvPmejKmZfmeYJo&q=${this.shopAddress}`
      } else {
        return `https://www.google.com/maps/embed/v1/place?key=AIzaSyCNtpQAXt_GZAzMOiQ7ZvPmejKmZfmeYJo&q=${this.shopAddress}`
      }
      // console.log(this.shopName);
      // if (this.shopName === '' || this.place === '') {
      //   console.log(this.place);
      //   return `https://www.google.com/maps/embed/v1/place?key=AIzaSyCNtpQAXt_GZAzMOiQ7ZvPmejKmZfmeYJo&q=${this.place}`
      // } else {
      //   console.log(this.shopAddress);
      //   return `https://www.google.com/maps/embed/v1/place?key=AIzaSyCNtpQAXt_GZAzMOiQ7ZvPmejKmZfmeYJo&q=${this.shopAddress}`
      // }
    }
  },
  methods: {
    ...mapActions({
      getShops: 'getShops',
      getAzureUrl: 'getAzureUrl',
      getAzureToken: 'getAzureToken',
      getAzureCategories: 'getAzureCategories',
      getAzureCategoryItems: 'getAzureCategoryItems',
      getAzureCategoryItemDetail: 'getAzureCategoryItemDetail',
      
      getAllStore: 'getAllStore',
      getItem: 'getItem',
      getStoreThrNumber: 'getStoreThrNumber'
    }),
    ...mapMutations({
      storeAzureUrl: 'storeAzureUrl',
      storeitemDetail: 'storeitemDetail',
      updateShopContent: 'updateShopContent',

      updateShopCart: 'updateShopCart',
      updateImCart: 'updateImCart',
      updateShopNum: 'updateShopNum',
      updateShopName: 'updateShopName',
      updateShopPhoneNo: 'updateShopPhoneNo',
      updateLinePayStatus: 'updateLinePayStatus'
    }),
    // ...azure.mapMutations({storeItemList: 'storeItemList'}),
    iframe(){
      if (this.azureUrl) {
      this.$createDialog({
        zIndex: 100000000000,
        type: 'confirm',
        title: '系統提示',
        confirmBtn: '確定',
        content: '是否選擇其他品牌以及其他門市?',
        icon: 'cubeic-alert',
        confirmBtn: {
          text: '確定',
          active: true,
          disabled: false,
          href: 'javascript:;'
        },
        onConfirm: () => {
          this.tempAzureUrl = ''
          // this.$loading.hide();
        },
        }).show()
      }
    },
    doAzureUrl(shopName, shopNo, brandId, posId, poiId, secretkey, openTime, detail) {
      // DEV NOTE:20210916 ping 8點前為非營業時間 === VVV
      // DEV NOTE:20210922 ping 新增打烊後非點餐時間通知
      let timeNowHours = new Date().getHours().toString() < 10 ? `0${new Date().getHours().toString()}` : new Date().getHours().toString();
      let timeNowMinute = new Date().getMinutes().toString() < 10 ? `0${new Date().getMinutes().toString()}` : new Date().getMinutes().toString();
      const timeNow = timeNowHours.toString() + timeNowMinute.toString()
      console.log(timeNow)
      let endTimeHours = openTime.substring(14,12)
      let endTimeMinute  = openTime.substring(17,15)
      const timeEnd = endTimeHours + endTimeMinute
      console.log(timeEnd)
      if (timeNowHours < "08" || timeNow > timeEnd) {
        return this.$createDialog({
          zIndex: 200010,
          type: 'warn',
          title: '提示',
          content: '目前非點餐時間',
          icon: 'cubeic-alert',
          confirmBtn: {
            text: '確定',
            active: true,
            disabled: false,
            // href: 'javascript:;'
          },
        }).show()
      }
      // DEV NOTE:20210916 ping 8點前為非營業時間 === ^^^
      // DEV NOTE:20210922 ping 8點至營業時間可訂購 === VVV
      let openTimeHours = openTime.substring(8,6)
      let openTimeMinute= openTime.substring(11,9)
      const timeOpen = openTimeHours + openTimeMinute
      console.log(timeOpen)
      if (timeNowHours >= "08" && timeNow < timeOpen) {
        this.$createDialog({
          zIndex: 200010,
          type: 'warn',
          title: '通知訊息',
          content: '　線上訂餐八點開放預訂<br>取餐時間需為店家營業時間',
          icon: 'cubeic-alert',
          confirmBtn: {
            text: '開始訂餐',
            active: true,
            disabled: false,
            // href: 'javascript:;'
          },
        }).show()
      }
      // DEV NOTE:20210922 ping 8點至營業時間可訂購 === ^^^
      const toast = this.$createToast({ txt: '請重新操作',
        mask: true, zIndex: 1000000 })
      if (detail && detail.posId) {
        this.$createToast({ txt: '進入店家中',
        mask: true, zIndex: 1000000 }).show()
        localStorage.removeItem('shopcontent')
        this.storeShopContent = null
      }
      if (shopName !== this.Shop.name) {
        this.shopCar = [];
      }
      this.$loading.show();
      // this.orderToken,
      // this.secretkey
      console.log(openTime);
      console.log(brandId);
      console.log(poiId);
      console.log(posId);
      console.log(secretkey);
      this.secretkey = secretkey
      this.shopContent.poiId = poiId
      this.shopContent.posId = posId
      this.shopContent.secretkey = secretkey
      this.shopContent.openTime = openTime
      this.$loading.show();
      this.getAzureToken({
        poiId: poiId,
        secretkey: secretkey,
      })
      .then((res) => {
        console.log(res);
        let itemCategory = [];
        let itemDetail = [];
        if (res.data && res.data.shopId) {
          this.shopId = res.data.shopId;
          this.orderToken = res.data.orderToken;
          this.shopContent.shopId = res.data.shopId;
          this.shopContent.orderToken = res.data.orderToken;
          this.getAzureCategories({shopId: this.shopId, secretkey: this.secretkey,})
          .then((res) => {
            console.log(res);
            if (res.data && res.data.length > 0 && !res.data.error) {
              // this.shopNumber = shopNo;
              this.shopContent.name = shopName;
              this.shopName = shopName;
              this.beforeStep = '2';
              this.chosenShop = true;
              this.place = '';
              // this.itemData = res.data;
              itemCategory = res.data
              for (let e = 0; e < itemCategory.length; e++) {
                itemDetail[e]= {
                  name: itemCategory[e].name,
                  displayCategoryId: itemCategory[e].displayCategoryId,
                  displayIndex: itemCategory[e].displayIndex,
                  item: [],
                }
              }
              console.log(itemDetail);
              for (let i = 0; i < itemCategory.length ; i++) {
                this.getAzureCategoryItems({secretkey: this.secretkey, shopId: this.shopId, displayCategoryId: itemCategory[i].displayCategoryId, orderToken: this.orderToken,})
                .then((res) => {
                  console.log(res);
                  if (res.data.length > 0) {
                    for (let e = 0; e < res.data.length; e++) {
                      const id = res.data[e]['categoryId']
                      const id1 = itemCategory[i]['displayCategoryId']
                      // console.log(id1);
                      for (let a = 0; a < itemDetail.length ; a++) {
                        if (itemDetail[a].displayCategoryId === id) {
                          itemDetail[a].item.push(res.data[e])
                        }
                      }
                    }
                    // console.log(itemDetail);
                    // this.itemData = itemDetail
                    // this.updateItemList = this.itemData
                    // console.log(this.itemData);
                    // console.log(this.updateItemList);
                    this.$loading.hide();
                  } else {
                    this.$loading.hide();
                    // toast.show()
                  }
                })
              }
              setTimeout(() => {
                this.itemData = itemDetail
                this.updateItemList = this.itemData
                console.log(this.itemData);
                console.log(this.updateItemList);
                this.$loading.hide();
              },1000)
              // this.$loading.hide();
              // toast.show()
            } else {
              this.$loading.hide();
              toast.show()
            }
          })
        } else {
          this.$loading.hide();
          toast.show()
        }
      })
      // this.getAzureUrl({
      //   accountId: this.eilisProfile.accountId,
      //   brandId: brandId,
      //   posId: posId,
      //   phone: this.personalData.phone,
      //   point: this.personalData.point,
      //   name: this.personalData.name,
      //   birth: this.personalData.birth.replace('-','').replace('-',''),
      // })
      // .then((res) => {
      //   this.$loading.hide();
      //   console.log(res);
      //   if (res.data.result !== '取得訂餐網址錯誤') {
      //     this.tempAzureUrl = res.data.result.OrderURL
      //   } else {
      //     const toast = this.$createToast({
      //       txt: '請重新操作',
      //       mask: true,
      //       zIndex: 1000000
      //     })
      //     toast.show()
      //     setTimeout(() => {
      //       toast.show()
      //     },700)
      //   }
      // })
    },
    getLocationShop(val) {
      // this.loadingIcon = true;
      this.getStore({ city: val, })
      .then((res)=> {
        this.shopAddress = res.data[0].address;
        // console.log(res);
        if (this.whiteList.indexOf(this.$route.query.Auth) !== -1) {
          // console.log(1);
          this.LocationShop = res.data.filter(el => el.district === this.district)
        } else {
          this.LocationShop = res.data.filter(el => el.name !== '測試店' && el.name !== '公益店'  && el.district === this.district);
        }
        // DEV NOTE: 20210915 ping 網址有 emc-iluxurycard-dev2v 看的到全部店，否則看不到總部測試分店 (目前這段無效)
        if (window.location.host.indexOf("emc-iluxurycard-dev2v") !== -1) {
          this.LocationShop = res.data.filter(el => el.district === this.district)
        } else {
          this.LocationShop = res.data.filter(el => el.name !== '總部測試分店' && el.district === this.district);
        }
        // this.loadingIcon = false;
        // this.LocationShop = res.data.filter(el => el.district === this.district && el.name !== '測試店')

      })
    },
    calculateDistance(lat1, lon1, lat2, lon2) {
      var R = 6371; // km
      var dLat = (lat2 - lat1).toRad();
      var dLon = (lon2 - lon1).toRad();
      var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
              Math.cos(lat1.toRad()) * Math.cos(lat2.toRad()) *
              Math.sin(dLon / 2) * Math.sin(dLon / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      return d;
    },
    // Number.prototype.toRad = function() {
    //   return this * Math.PI / 180;
    // }
    showNearBy() {
      // return;
      // console.log(this.citydata);
      this.place = '';
      this.nearbyListShow = true;
      this.favoriteListShow = false
      this.$loading.show();
      if (this.citydata.length > 0) {
        if (this.LocationShop.length !== 0) {
          this.$loading.hide();
          this.shopAddress = this.LocationShop[0].address;
        } else {
          if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
            console.log(position);
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
            localStorage.setItem('nowlng',this.longitude)
            localStorage.setItem('nowlat',this.latitude)
            var url = `https://api.nlsc.gov.tw/other/TownVillagePointQuery/${this.longitude}/${this.latitude}/4326`
            return axios.get(url)
            .then((res) => {
              // console.log(res);
              const precity = res.data.ctyName.replace('臺', '台');
              this.city = precity;
              console.log(precity);
              console.log(this.citydata);
              if (res.data.error) {
                // console.log(this.LocationShop);
                this.city = '台中市';
                this.loader = false;
                localStorage.setItem('currentCity',this.city)
                this.$emit('trans', this.city);
                this.$loading.hide();
              }
              else if (this.citydata.map(el => el.name).indexOf(precity) === -1 || this.whiteList.indexOf(this.$route.query.Auth) === -1) {
                this.$createDialog({
                  type: 'alert',
                  zIndex: 10000000000,
                  title: '系統提示',
                  content: '您所在地區目前不支援此功能',
                  confirmBtn: '確定',
                  onConfirm: (e, promptValue) => {
                    // const el = document.querySelector('.radio-button');
                    // el.checked = true;
                    // this.toGeneral();
                    this.nearbyListShow = false;
                    this.$loading.hide();
                  }
                }).show()
              }
              else {
                this.district = res.data.townName
                // this.getLocationShop(this.city)
                console.log(this.district);
                console.log(this.ogData);
                this.shopAddress = this.ogData.filter(el => el.county === this.city)[0].address;
                // console.log(res);
                if (this.whiteList.indexOf(this.$route.query.Auth) !== -1) {
                  // console.log(1);
                  this.LocationShop = this.ogData.filter(el => el.county === this.city && el.district === this.district)
                    console.log(this.LocationShop);
                    // for (let i = 0; i < this.LocationShop.length; i ++) {
                    //   geocoder.geocode(
                    //   {
                    //     address: this.LocationShop[i].address,
                    //     componentRestrictions: {
                    //     country: "TW"
                    //     }
                    //   },
                    //   function (result, status) {
                    //   console.log(result);
                    //   if (status == google.maps.GeocoderStatus.OK) {
                    //   var location = result[0].geometry.location;
                    //   console.log(location);
                    //   // location.Pa 緯度
                    //   // location.Qa 經度

                    //   } else {
                    //     alert('解析失敗!回傳狀態為：' + status);
                    //   }
                    //   });
                    // }
                } else {
                  this.LocationShop = this.ogData.filter(el => el.county === this.city && el.shops !== '測試店' && el.name !== '公益店'  && el.district === this.district);
                }
                this.$loading.hide();
              }
            })
            .catch(() => {
              this.$loading.hide();
            })
          },
          (error) => {
            // console.log(error);
            navigator.geolocation.clearWatch(error)
            this.toGeneral();
            const el = document.querySelector('.radio-button');
            el.checked = true;
            // this.loadingIcon = false;
            navigator.permissions.query({ name: 'geolocation' })
            .then((res) => {
              // console.log(res);
              navigator.geolocation.getCurrentPosition((position) => {
              })
            })
          })
        } else {
          this.nearbyListShow = true;
          this.notSupport = true;
          }
        }
      } else {
        console.log('1');
        this.$loading.show()
        this.getshop()
        // setTimeout(() => {
        //   // this.loadingIcon = false;
        //   this.showNearBy()
        // },800)
      }
    },
    fontSize(val) {
      // console.log(val.length);
      if (document.body.clientWidth <= 375 && val.length > 7) {
        return val
      }
    },
    toCity(val) {
      // console.log(val);
      // console.log(this.citydata.map(el => el.name === val));
      const index = this.citydata.map(el => el.name).indexOf(val)
      const el = document.querySelector('.cube-index-list');
      // console.log(el);
      // console.log(el.getElementsByClassName('cube-index-list-group'));
      this.groupList = this.$el.getElementsByClassName('cube-index-list-group')
      // console.log(this.$refs.indexList);
      this.$refs.indexList.$children[0].scrollToElement(this.groupList[index], 20)
    },
    animateToTop(e) {
        // e.preventDefault();
      console.log(e);
      window.scrollBy(0,e);
      // scrolldelay = setTimeout(this.animateToTop,10);
    },
    toGeneral() {
      return;
      this.place = '';
      this.shopAddress = this.favoriteListDetail[0].address;
      this.favoriteListShow = true
      this.nearbyListShow = false
      // this.showList = false;
      // this.beforeStep = '1'
    },
    show () {
      this.$refs.dialog.show()
      this.$emit('show')
    },
    hide () {
      this.$refs.dialog.hide()
      this.$emit('hide')
    },
    getWhole(val) {
      // console.log(val);
      this.citydata = val.filter(el => el.name !== '咖啡車');
      const citydata = JSON.stringify(this.citydata);
      // console.log(citydata);
      if (!localStorage.getItem('citydata')) {
        localStorage.setItem('citydata', citydata)
      }
    },
    getCity(val) {
      // console.log(val);
      this.City = val;
      this.favoriteListApear = false;
    },
    test(val) {
      this.beforeStep = '1';
      // console.log(val);
      this.showList = true;
      this.favoriteListShow = false;
      this.nearbyListShow = false;
      // this.place = '';
    },
    imCheck(totalPrice) {
      const hint = this.$createToast({
        txt: '請選擇糖冰',
        type: 'warn',
        zIndex: 1000000,
      })
      if (this.item.ices.length > 0 && (!this.item.iceSelect || !this.item.sugarSelect)) {
        return hint.show()
      } else if (this.item.sugar.length > 0 && !this.item.sugarSelect) {
        return hint.show()
      }
      // return;
      // 如果是套餐
      let itemArr = JSON.parse(JSON.stringify(this.shopCar));
      console.log(itemArr);
      // return;
      for (let i = 0; i < itemArr.length; i++) {
        // console.log(this.item);
        console.log(itemArr[0].iceSelect.itemId === this.item.iceSelect.itemId);
        console.log(this.item.iceSelect.title);
        console.log(this.item.iceSelect);
        console.log(itemArr[0].iceSelect);
        console.log(itemArr[i].foodSelect.map(el => this.item.foodSelect.indexOf(el.title)));
        // && itemArr[i].foodSelect.map(el => this.item.foodSelect.indexOf(el.title))[0] === -1
        // && itemArr[i].sugarSelect.title === this.item.sugarSelect.title && itemArr[i].iceSelect.tltle === this.item.iceSelect.title
        const foodSelect = this.item.foodSelect.map(el => el.itemId)
        const foodSelectLength = this.item.foodSelect.map(el => el.itemId).length
        if (itemArr[i].title === this.item.title && itemArr[i].sugarSelect.itemId === this.item.sugarSelect.itemId && itemArr[i].iceSelect.itemId === this.item.iceSelect.itemId && itemArr[i].foodSelect.map(el => foodSelect.indexOf(el.itemId))[0] !== -1 && itemArr[i].foodSelect.map(el => el.itemId).length === foodSelectLength) {
          // const index = itemArr.map(el => el.title).indexOf(this.item.title) // 會出錯
          // console.log(index);
          itemArr[i].num = itemArr[i].num + this.item.num
          this.drinkDialog = '';
          const toast = this.$createToast({
            txt: '商品加入成功',
            type: 'warn'
          })
          toast.show()
          console.log(itemArr);
          // return;
          // console.log(JSON.parse(JSON.stringify(itemArr)));
          this.shopCar = JSON.parse(JSON.stringify(itemArr));
          return toast;
        }
      }
      itemArr.push(this.item);
      this.shopCar = JSON.parse(JSON.stringify(itemArr));
      this.drinkDialog = '';
      // console.log(this.shopCar);
      const toast = this.$createToast({
        txt: '商品加入成功',
        type: 'correct'
      })
      toast.show()
      this.item.num = 1;
      this.$emit('gotochart', '購物車')
    },
    pushIntoCart(totalPrice, item) {
      console.log(item);
      console.log(this.item);
      const hint = this.$createToast({
        txt: '請選擇糖冰',
        type: 'warn',
        zIndex: 1000000,
      })
      if (this.item.ices.length > 0 && (!this.item.iceSelect || !this.item.sugarSelect)) {
        return hint.show()
      } else if (this.item.sugar.length > 0 && !this.item.sugarSelect) {
        return hint.show()
      }
      // return;
      // 如果是套餐
      let itemArr = JSON.parse(JSON.stringify(this.shopCar));
      console.log(itemArr);
      // return;
      for (let i = 0; i < itemArr.length; i++) {
        // console.log(this.item);
        console.log(itemArr[0].iceSelect.itemId === this.item.iceSelect.itemId);
        console.log(this.item.iceSelect.title);
        console.log(this.item.iceSelect);
        console.log(itemArr[0].iceSelect);
        console.log(itemArr[i].foodSelect.map(el => this.item.foodSelect.indexOf(el.title)));
        // && itemArr[i].foodSelect.map(el => this.item.foodSelect.indexOf(el.title))[0] === -1
        // && itemArr[i].sugarSelect.title === this.item.sugarSelect.title && itemArr[i].iceSelect.tltle === this.item.iceSelect.title
        const foodSelect = this.item.foodSelect.map(el => el.itemId)
        const foodSelectLength = this.item.foodSelect.map(el => el.itemId).length
        if (itemArr[i].title === this.item.title && itemArr[i].sugarSelect.itemId === this.item.sugarSelect.itemId && itemArr[i].iceSelect.itemId === this.item.iceSelect.itemId && itemArr[i].foodSelect.map(el => foodSelect.indexOf(el.itemId))[0] !== -1 && itemArr[i].foodSelect.map(el => el.itemId).length === foodSelectLength) {
          // const index = itemArr.map(el => el.title).indexOf(this.item.title) // 會出錯
          // console.log(index);
          itemArr[i].num = itemArr[i].num + this.item.num
          this.drinkDialog = '';
          const toast = this.$createToast({
            txt: '商品加入成功',
            type: 'warn'
          })
          toast.show()
          console.log(itemArr);
          // return;
          // console.log(JSON.parse(JSON.stringify(itemArr)));
          this.shopCar = JSON.parse(JSON.stringify(itemArr));
          return toast;
        }
      }
      itemArr.push(this.item);
      this.shopCar = JSON.parse(JSON.stringify(itemArr));
      this.drinkDialog = '';
      // console.log(this.shopCar);
      const toast = this.$createToast({
        txt: '商品加入成功',
        type: 'correct'
      })
      toast.show()
      this.item.num = 1;
      // this.item.drinkSelect = [];
      // this.item.foodSelect = [];
      // // this.totalPrice = '';
      // this.item.iceSelect = '';
      // this.item.sugarSelect = '';
    },
    plus(val) {
      // console.log(val);
      if (this.item.num > 40) {
        return this.num;
      } else {
        this.item.num += 1;
      }
    },
    minus() {
      if (this.item.num < 2) {
        return this.num === 1;
      } else {
        this.item.num -= 1;
      }
    },
    close() {
      this.drinkDialog = '';
    },
    toggleActiveSize(size, index){
      // console.log(size);
      const arr = this.sizeArr;
      for(var i = 0; i < arr.length; i++) {
        arr[i].active = false;
      }
      this.sizeArr[index].active = !this.sizeArr[index].active;
      this.item.item_price = size.price;
      this.item.size = size.title;
      // console.log(this.numSugar);
    },
    toggleActiveSugar(sugar, index){
      console.log(sugar);
      const arr = this.sugars;
      for(var i = 0; i < arr.length; i++) {
        arr[i].active = false;
      }
      this.sugars[index].active = !this.sugars[index].active;
      // this.numSugar = sugar.title;
      this.item.sugarSelect = sugar;
      // this.sugarSelect = sugar
    },
    toggleActiveIce(ice, index){
      console.log(ice);
      const arr = this.ices;
      for(var i = 0; i < arr.length; i++) {
        arr[i].active = false;
      }
      this.ices[index].active = !this.ices[index].active;
      this.item.iceSelect = ice;
      // this.temp = ice.title;
    },
    toggleActiveFood(food, index) {
      console.log(food);
      if (food.isOutOfStock === true) {
        return;
      }
      // console.log(index);
      // console.log(this.item.foodSelect);
      this.foodsOption[index].active = !this.foodsOption[index].active;
      const foodIndex = this.item.foodSelect.filter(el => el.title === food.title)
      const indexx = this.item.foodSelect.map(el => el.title).indexOf(food.title)
      console.log(foodIndex);
      console.log(indexx);
      if (food.active === true && food.price !== 0) {
        this.item.price = parseInt(this.item.price) + food.price; // DEV NOTE:MARK 增加加料價錢

        if (foodIndex.length === 0) {
          this.item.foodSelect.push(food)
        } else {
          this.item.foodSelect.splice(indexx , 1)
        }
        // this.item.foodSelect[index].push(food) =
        console.log(this.item.foodSelect);
      } else if (food.active === true && food.price === 0 || food.active !== true && food.price === 0) {
        if (foodIndex.length === 0) {
          this.item.foodSelect.push(food)
        } else {
          this.item.foodSelect.splice(indexx , 1)
        }
        console.log(this.item.foodSelect);
        // this.item.foodSelect = food
      } else {
        this.item.price = parseInt(this.item.price) - food.price; // DEV NOTE:MARK 減少加料價錢
        this.item.foodSelect.splice(indexx , 1)
        console.log(this.item.foodSelect);
        // this.item.foodSelect = food
      }

    },
    // 點擊選擇商品  0981110333
    decideItem(food) {
      if (food.isOutOfStock === true) {
        this.$createToast({
          type: 'error',
          txt: '品項已售完',
          time: 1500
        }).show()
        return;
      }
      // console.log(food);
      this.$loading.show();
      this.item.ices = []
      this.item.sugar = []
      this.item.add = []
      this.item.num = 1;
      this.item.foodSelect = [];
      this.item.iceSelect = '';
      this.item.sugarSelect = '';
      this.drinkDialog = 'out';
      this.item.remark = food.remark
      this.item.title = food.itemName;
      this.item.en_title = food.itemNameAlt;
      this.item.itemCode = food.itemCode;
      this.item.itemCount = food.itemCount;
      this.item.itemId = food.itemId;
      this.item.classId = food.classId;
      this.item.price = food.price;
      this.item.img = food.webImageLink;
      this.img = food.webImageLink;
      this.getAzureCategoryItemDetail({
        shopId: this.shopContent.shopId, // this.shopId,
        itemId: food.itemId,
        orderToken: this.shopContent.orderToken,
        secretkey: this.shopContent.secretkey
      })
      .then((res) => {
        console.log(res);
        console.log(res.data.modifierGroupList.filter(el => el.groupName === '甜度備註'));
        let sugar = res.data.modifierGroupList.filter(el => el.groupName === '甜度備註')[0]
        this.item.ices = res.data.modifierGroupList.filter(el => el.groupName === '冰量').length > 0 ? res.data.modifierGroupList.filter(el => el.groupName === '冰量') : res.data.modifierGroupList.filter(el => el.groupName === '冰塊備註')[0] ? res.data.modifierGroupList.filter(el => el.groupName === '冰塊備註') : res.data.modifierGroupList.filter(el => el.groupName === '溫度備註')
        this.item.sugar = res.data.modifierGroupList.filter(el => el.groupName === '糖量').length > 0 ? res.data.modifierGroupList.filter(el => el.groupName === '糖量') : res.data.modifierGroupList.filter(el => el.groupName === '甜度備註')
        this.item.add = res.data.modifierGroupList.filter(el => el.groupName === '加料').length > 0 ? res.data.modifierGroupList.filter(el => el.groupName === '加料') : res.data.modifierGroupList.filter(el => el.groupName === '加料備註')
        console.log(this.item.ices);
        console.log(this.item.sugar);
        console.log(this.item.add);
        // this.$loading.hide();
        const arr = [];
        const ices = this.item.ices.length > 0 ? this.item.ices[0].modifierList : []
        console.log(ices);
        // console.log(this.item.suger[0].modifierList);
        // DEV NOTE:20210914 改為不為空值且長度>0，原本用或 ping
        if (ices !== undefined && ices.length > 0) {
          for (let i = 0; i < ices.length; i += 1 ) {
            arr[i] = {
              modifierGroupHeaderId: this.item.ices[0].groupHeaderId,
              title: ices[i].itemName,
              itemId: ices[i].itemId,
              itemCode: ices[i].itemCode,
              active: false,
            }
          }
        } else {
          arr[0] = {
            title: '固定',
            active: false,
          }
        }
        const arr1 = [];
        const sugars = this.item.sugar.length > 0 ? this.item.sugar[0].modifierList : []
        // DEV NOTE:20210914 改為不為空值且長度>0，原本用或 ping
        if (sugars !== undefined && sugars.length > 0) {
          for (let i = 0; i < sugars.length; i += 1 ) {
            arr1[i] = {
              modifierGroupHeaderId: this.item.sugar[0].groupHeaderId,
              title: sugars[i].itemName,
              itemId: sugars[i].itemId,
              itemCode: sugars[i].itemCode,
              active: false,
            }
          }
        } else {
          arr1[0] = {
            title: "固定",
            active: false,
          };
        }
        // console.log(arr1)
        // console.log("1306")
        const arr2 = [];
        const add = this.item.add.length > 0 ? this.item.add[0].modifierList : []
        // const size = Object.keys(food.price);
        // const price = Object.values(food.price);
        if (add !== undefined || add.length > 0) {
          for (let i = 0; i < add.length; i += 1 ) {
            arr2[i] = {
              modifierGroupHeaderId: this.item.add[0].groupHeaderId,
              title: add[i].itemName,
              itemId: add[i].itemId,
              itemCode: add[i].itemCode,
              price: add[i].price ? add[i].price : 0,
              isOutOfStock: add[i].isOutOfStock,
              active: false,
            }
          }
        }
        this.foodsOption = arr2
        // conso2e.log(this.sizeArr);
        // 8/28 調整 拿掉七分冰
        this.ices = arr
        // for(let i = 0; i < this.ices.length; i ++) {
        //   if (this.ices[i].title === '全冰') {
        //     this.ices[i].title = '正常冰'
        //   }
        //   if (this.ices[i].title === '五分冰') {
        //     this.ices[i].title = '少冰'
        //   }
        //   if (this.ices[i].title === '三分冰') {
        //     this.ices[i].title = '微冰'
        //   }
        // }
        // const I = {
        //   '正常冰': 0,
        //   '少冰': 1,
        //   '微冰': 2,
        //   '去冰': 3,
        //   '熱飲': 4
        // }
        // this.ices.sort((a, b) => I[a.title] - I[b.title]);
        // // console.log(arr1);
        // for(let i = 0; i < arr1.length; i ++) {
        //   if (arr1[i].title === '全糖') {
        //     arr1[i].title = '正常甜'
        //   }
        //   if (arr1[i].title === '五分糖') {
        //     arr1[i].title = '半糖'
        //   }
        //   if (arr1[i].title === '三分糖') {
        //     arr1[i].title = '微糖'
        //   }
        // }
        // const S = {
        //   '正常甜': 0,
        //   '半糖': 1,
        //   '微糖': 2,
        //   '無糖': 3,
        // }
        this.sugars = arr1
        // console.log(this.sugars.sort());
        // console.log(this.drinkDialog);
        console.log(this.sugars);
        console.log(this.ices);
        this.$loading.hide();
        // DEV NOTE:20210914 當甜度冰塊為固定時預設 ping VVV
        if (sugars.length == 0) {
          this.toggleActiveSugar(this.sugars[0], 0);
        }
        if (ices.length == 0) {
          this.toggleActiveIce(this.ices[0], 0);
        }
        // DEV NOTE:20210914 當甜度冰塊為固定時預設 ping ^^^
        // this.toggleActiveFood(this.foodsOption[0], 0);
      })
    },
    changeHandler(label) {
      // console.log(label);
      // console.log(this.itemData);
      // console.log(this.current);
      this.current = ''
      if (this.points < 100 && label === '金卡專區') {
        // alert('您的等級無法點選此菜單')
        this.$createDialog({
          zIndex: 200010,
          type: 'warn',
          title: '提示',
          content: '您的等級無法點選此菜單',
          icon: 'cubeic-alert',
          confirmBtn: {
            text: '確定',
            active: true,
            disabled: false,
            // href: 'javascript:;'
          },
        }).show()
        var num = Math.floor(Math.random() * (this.itemData.length - 0 + 1)) + 0
        this.current = this.itemData[5].name;
      } else if (this.points < 20 && label === '銀卡專區') {
        this.$createDialog({
          zIndex: 200010,
          type: 'warn',
          title: '提示',
          content: '您的等級無法點選此菜單',
          icon: 'cubeic-alert',
          confirmBtn: {
            text: '確定',
            active: true,
            disabled: false,
            // href: 'javascript:;'
          },
        }).show()
        var num = Math.floor(Math.random() * (this.itemData.length - 0 + 1)) + 0
        this.current = this.itemData[5].name;
      }
    },
    stickyChangeHandler(current) {
      // console.log(current);
    },
    showCityList() {
      // this.getItem();
      this.showList = true;
      this.favoriteListShow = false; // 喜好門市取消顯示
      this.nearbyListShow = false;
      this.place = '';
    },
    chooseShop(name, shopNo, status, shopPhone, linePay, channelID, channelSecret,) {
      // console.log(linePay);
      // console.log(channelID);
      // console.log(channelSecret);
      // console.log(name);
      // console.log(this.itemList);
      if (status === 'off' && name !== '測試店') {
        // console.log('QQQ');
        return this.$createDialog({
          zIndex: 200010,
          type: 'warn',
          title: '提示',
          content: '所選的門市目前非營業時間',
          icon: 'cubeic-alert',
          confirmBtn: {
            text: '確定',
            active: true,
            disabled: false,
            // href: 'javascript:;'
          },
        }).show()
      }
      if (linePay && linePay === true && channelID &&  channelSecret) {
        this.linePay = linePay;
      } else {
        this.linePay = false;
      }
      this.$loading.show();
      this.shopPhoneNumber = shopPhone;
      this.shopNumber = shopNo;
      this.shopTitle = name;
      this.beforeStep = '2';
      this.chosenShop = true;
      this.place = '';
      this.shopName = name;
      this.itemData = this.itemList;
      // console.log(this.itemData);
      this.current = this.itemData[0].name;
      this.$loading.hide();
    },
    // 點擊顯示地址在google地圖
    locationShop(val,num, address) {
      // console.log(val);
      this.shopName = val;
      this.shopAddress = address;
    },
    selectItem(item) {
      console.log(this.shopList);
      console.log(item.city)
      console.log(item.name)
      this.$loading.show();
      this.place = item.city;
      // if (this.whiteList.indexOf(this.$route.query.Auth) !== -1) {
      //   this.citylist = this.shopList.filter(el => el.district === item.name);
      // } else {
      //   this.citylist = this.shopList.filter(el => el.shopName !== '測試店' && el.shopName !== '公益店');
      // }
      // DEV NOTE:20210915MARK 增加網址白名單可以看到總部測試店，網址有 emc-iluxurycard-dev2v 看的到全部店，否則看不到總部測試分店 (目前這整段無效)

      // if (window.location.host.indexOf("emc-iluxurycard-dev2v") !== -1) {
      //   this.citylist = this.shopList.filter(el => el.district === item.name);
      // } else {
      //   console.log(this.shopList)
      //   this.citylist = this.shopList.filter(el => el.shopName !== '總部測試分店' && el.district === item.name);
      //   console.log('1523')
        
      // }
      // console.log('1526')
      // console.log(this.citylist)

      // if (window.location.host.indexOf("emc-iluxurycard-dev2v") !== -1) {
        this.citylist = this.ogData.length > 0 ? this.ogData.filter(el => el.district === item.name) : this.shopList.filter(el => el.district === item.name);
      // }else{
      //   this.citylist = this.ogData.length > 0 ? this.ogData.filter(el => el.shopName !== '總部測試分店' && el.district === item.name) : this.shopList.filter(el => el.district === item.name);
      // }
      
      // this.citylist = res.data.filter(el => el.county === item.city);

      this.tempCityInfo.city = item.county //貯存選擇的城市資訊;
      this.tempCityInfo.district = item.shopName //貯存選擇的城市資訊;
      localStorage.setItem('tempCityInfo', JSON.stringify(this.tempCityInfo))
      this.shopAddress = this.citylist[0].address;
      this.shopName = this.citylist[0].shopName;
      this.$loading.hide();
        // console.log(address.filter(el => el.county === item.name));
        // this.citylist = address.filter(el => el.county === item.name)
      this.showList = false;
    },
    clickTitle(title) {
      // console.log(title)
    },
    showFavorite(val) {
      setTimeout(() => {
        const el = document.querySelector('.radio-button');
        /// console.log(el);
        el.checked = true;
      },100)
      this.favoriteListShow = val
    },
    upsidedown(val) {
      // console.log(val);
      this.showList = val
    },
    onPullingUp() {
      // Mock async load.
      // console.log('1');
      setTimeout(() => {
        const length = this.citydata.length
        if (length < this.citydata.length) {
          // Update data.
          this.citydata.push(this.citydata[length])
        }
        // Call forceUpdate after finishing data load.
        this.$refs.indexList.forceUpdate()
      }, 1000)
    },
    getshop() {
      console.log(this.shopList);
      this.$loading.show();
      if (this.shopList.length === 0) {
        // DEV NOTE:20210917MARK 合併馬力歐 
        // DEV NOTE:20210916MARK 店家過濾，(目前這段是過濾店家有效的)
        this.getShops({accountId: this.eilisProfile.accountId})
        .then((res)=> {
          console.log(1588)
          console.log(res);
          let arr =[]
          if (window.location.host.indexOf("emc-iluxurycard-dev2v") !== -1) {
            arr = res.data.result.filter(el => el.userOpen !== false) // DEV NOTE:20210916MARK
          }else{
            arr = res.data.result.filter(el => el.userOpen !== false && el.shopName !== '總部測試分店' && el.shopName !== 'EILIS測試店' && el.shopName !== '網路行銷用') // DEV NOTE:20210916MARK
          }
          let newAddress = {};
          for (let i = 0; i < arr.length; i += 1) {
            const cityName = arr[i]['county'];
            if (cityName !== null) {
              newAddress[cityName] = []
            }
            if (i === arr.length - 1) {
              for (let e = 0; e < arr.length; e += 1) {
                const cityName2 = arr[e]['county'];
                const district = arr[e]['district']
                if (district !== null) {
                  if (newAddress[cityName2].indexOf(district) === -1) {
                    newAddress[cityName2].push(district)
                  }
                }
              }
            }
          }
          const arr2  = Object.entries(newAddress)
          let newCity = []
          let items = []
          for (let i = 0; i < arr2.length; i++ ) {
            newCity[i] = {
              name: arr2[i][0],
              items: {
                city:arr2[i][0],
                name:arr2[i][1],
              }
            }
          }
          let newArr = [];
          if (this.city !== '') {
            const temp = newCity.filter(el => el.items.city === this.city)
            var rv = {};
            for (var r = 0; r < temp.length; r++) {
              rv[r] = temp[r];
            }
            const i = newCity.map(el => el.items.city === this.city).indexOf(true)
            newCity[i] = newCity[0];
            // newCity.push(rv);
            newCity[0] = rv[0];
            // let newArr = [];
            for (let e = 0; e < newCity.length; e += 1) {
              newArr.push({ name: newCity[e].name })
              const countyList = newCity[e].items.name;
              // console.log(countyList);
              let itemList = []
              for (let s = 0; s < countyList.length; s += 1) {
                itemList.push({
                  city: newCity[e].name,
                  name: countyList[s].replace('?','')
                })
              }
              newArr[e].items = itemList;
            }
          } else {
            // console.log(this.city);
            for(let e = 0; e < newCity.length; e += 1) {
              newArr.push({ name: newCity[e].name })
              const countyList = newCity[e].items.name;
              let itemList = []
              for (let s = 0; s < countyList.length; s += 1) {
                itemList.push({
                  city: newCity[e].name,
                  name: countyList[s].replace('?','')
                })
              }
              newArr[e].items = itemList;
            }  // console.log
          }
          console.log(newArr);
          this.ogData = res.data.result
          this.citydata = newArr
          this.$loading.hide();
          return newArr
        })
      } else {
        // DEV NOTE:20210916MARK 店家過濾，(目前這段是過濾店家有效的)
        console.log(1669)
        let arr = []
        if (window.location.host.indexOf("emc-iluxurycard-dev2v") !== -1) {
          arr = this.shopList.filter(el => el.userOpen !== false) // DEV NOTE:20210916MARK
        }else{
          arr = this.shopList.filter(el => el.userOpen !== false && el.shopName !== '總部測試分店' && el.shopName !== 'EILIS測試店' && el.shopName !== '網路行銷用' ) // DEV NOTE:20210916MARK
        }        
        let newAddress = {};
        for (let i = 0; i < arr.length; i += 1) {
          const cityName = arr[i]['county'];
          if (cityName !== null) {
            newAddress[cityName] = []
          }
          if (i === arr.length - 1) {
            for (let e = 0; e < arr.length; e += 1) {
              const cityName2 = arr[e]['county'];
              const district = arr[e]['district']
              if (district !== null) {
                if (newAddress[cityName2].indexOf(district) === -1) {
                  newAddress[cityName2].push(district)
                }
              }
            }
          }
        }
        const arr2  = Object.entries(newAddress)
        let newCity = []
        let items = []
        for (let i = 0; i < arr2.length; i++ ) {
          newCity[i] = {
            name: arr2[i][0],
            items: {
              city:arr2[i][0],
              name:arr2[i][1],
            }
          }
        }
        let newArr = [];
        if (this.city !== '') {
          const temp = newCity.filter(el => el.items.city === this.city)
          var rv = {};
          for (var r = 0; r < temp.length; r++) {
            rv[r] = temp[r];
          }
          const i = newCity.map(el => el.items.city === this.city).indexOf(true)
          newCity[i] = newCity[0];

          newCity[0] = rv[0];
          for (let e = 0; e < newCity.length; e += 1) {
            newArr.push({ name: newCity[e].name })
            const countyList = newCity[e].items.name;
            // console.log(countyList);
            let itemList = []
            for (let s = 0; s < countyList.length; s += 1) {
              itemList.push({
                city: newCity[e].name,
                name: countyList[s].replace('?','')
              })
            }
            newArr[e].items = itemList;
          }
        } else {
          for(let e = 0; e < newCity.length; e += 1) {
            newArr.push({ name: newCity[e].name })
            const countyList = newCity[e].items.name;
            let itemList = []
            for (let s = 0; s < countyList.length; s += 1) {
              itemList.push({
                city: newCity[e].name,
                name: countyList[s].replace('?','')
              })
            }
            newArr[e].items = itemList;
          }
        }
        console.log(newArr);
        this.ogData = this.shopList
        this.citydata = newArr
        console.log('');

        this.$loading.hide();
      }
    },
  }
}
</script>

<style lang="scss" scoped>
$primary-color: var(--main-color);
* {
  box-sizing: border-box;
  font-family: 'Helvetica Neue', sans-serif;
  }
  .cube {
    z-index: 1000000000;
    margin-top: -15px;
    height: 20px;
    width: 100%;
    background-color: rgb(94, 94, 94);
  }
  .cityButton {
    margin: 5px;
    border: none;
    color:white;
    height:35px;
    background: $primary-color;
    border-radius:10px;
    font-weight: 600;
    letter-spacing:0.5px;
  }
  #layer{
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(181, 181, 181, 0.419);
  }
  .loading {
    z-index: 10000;
    display: flex; // 置中
    align-items: center; // 置中
    justify-content: center; // 置中
    margin: 0 auto;
    position: fixed;
    top: 55%;
    left: 0;
    right: 0;
    // margin-top: -270px;
    width: 55px;
    height: 55px;
    color: #ffffff;
    border-radius: 5px;
    background-color: #717171;
    -webkit-text-fill-color: transparent;
  }
  .box {
    height: 100vh;
    z-index: 100000;
    padding-left: 0!important;
    padding-right: 0!important;
    // margin: 0 auto;
    width: 100%;
    max-width: 540px;
    background-color: #ffffff;
    /* border: 1px solid rgb(170, 170, 170); */
    /* border:1px solid #8a8a8a; */
  }
  .top {
    position: fixed;
    z-index: 10000;
    margin: 0px auto ;
    margin-left: 0px;
    width: 100vw;
    height: 50px ;
    background-color: rgba(255, 255, 255, 0.24);
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.4);
  }
  .top .list{
    margin-left: 0px;
    text-align: left;
    height: 50px;
    width: 100vw;
  }
  .top  .list .item{
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: space-between;
    border-bottom: 1px solid rgba(210, 210, 210, 0.815);
    height: 50px;
    width: 100%;
    padding: 0px;
  }
  .input-container {
    position: relative;
    height:  2rem;
    width:  8rem;
    margin: 0rem;
    @media screen and (max-width: 360px) {
      width: 8rem;
      height: 2rem;
    }
  .radio-button {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    cursor: pointer;
    z-index: 10000000000;
  }
  .radio-button1 {
    opacity: 0;
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    margin: 0;
    cursor: pointer;
    z-index: 10000000000;
  }
  .radio-button2 {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    cursor: pointer;
    z-index: 10000000000;
  }
  //default tile styles
  .radio-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    // border: 2px solid $primary-color;
    border-radius: 2px;
    padding: 5px;
    transition: transform 300ms ease;
  }
  .icon svg {
    fill: $primary-color;
    width: 2rem;
    height: 2rem;
  }
  .radio-tile-label {
    text-align: center;
    font-size: 15.3px;
    font-weight: 600;
    // text-transform: uppercase;
    letter-spacing: 1px;
    color: $primary-color;
  }
  .radio-button:checked + .radio-tile {
    width: 80px;
    background-color: $primary-color;
    border: 2px solid $primary-color;
    color: white;
    transform: scale(1.15, 1.15);
    @media screen and (max-width: 360px) {
      transform: scale(0.9, 0.9);
    }

    .icon svg {
      fill: white;
      background-color: $primary-color;
    }
    .radio-tile-label {
      color: white;
      background-color: $primary-color;
    }
  }
  .radio-button1:checked + .radio-tile {
    width: 90px;
    background-color: $primary-color;
    border: 2px solid $primary-color;
    color: white;
    transform: scale(1.05, 1.05);
    @media screen and (max-width: 360px) {
      transform: scale(0.9, 0.9);
    }

    .icon svg {
      fill: white;
      background-color: $primary-color;
    }
    .radio-tile-label {
      color: white;
      font-size: 1rem;
      background-color: $primary-color;
    }
  }
  .radio-button2:checked + .radio-tile {
    width: 80px;
    background-color: $primary-color;
    border: 2px solid $primary-color;
    color: white;
    transform: scale(1.1, 1.1);
    @media screen and (max-width: 360px) {
      transform: scale(0.9, 0.9);
    }

    .icon svg {
      fill: white;
      background-color: $primary-color;
    }
    .radio-tile-label {
      color: white;
      font-size: 1rem;
      background-color: $primary-color;
    }
  }
  }
  .dia {
    padding: 0px;
    width: 100%;
    height: 400px;
    border-radius: 10px;
    // border: 1px solid #F37423;
    // margin: 0px auto;
  }
  .dia .cubeic-warn {
    transform: scale(1.4);
    border-radius: 50%;
    background-clip: text;
    // -webkit-text-fill-color: transparent;
    // border: 1px solid rgba(112, 88, 88, 0.877);
    color:#F57E28;
    padding: 15px;
  }
  .view-wrapper {
    position: fixed;
    top: 110px;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .index-list-wrapper {
    height: 98%;
    width: 94%;
    margin: 0 auto;
    overflow: hidden;
  }
  .map {
    z-index: 10000;
    margin: 35px auto;
    width: 100%;
    position: fixed;
  }
  .container {
    // position: fixed;
    position: fixed;
    z-index: 1000;
    height: 100%;
    width: 100%;
    margin: -110px auto;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
  }
  .container .list{
    padding-left: 0!important;
    padding-right: 0!important;
    text-align: left;
    height: 350px;
    width: 100vw;
    margin-top: 70px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 90px;
  }
  .container .list .item{
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    // overflow-y: auto;
    // -webkit-overflow-scrolling: touch;
    margin-top: 7px;
    display: flex;
    //overflow-y: auto;
    align-items: center;
    justify-content: space-between;
    align-content: space-between;
    border-bottom: 1px solid rgba(210, 210, 210, 0.815);
    height: 75px;
    width: 90vw;
    padding: 5px;
  }
  .container .list .item .title{
    color: rgba(0, 0, 0, 0.851);
    font-size: 16px;
    font-weight: 500;
    display: flex;
    letter-spacing: 0.5px;
    justify-content: space-between;
  }
  .container .list .item .entitle{
    font-size: 13px;
    color: rgb(91, 91, 91);
    margin-top: 5px;
  }
  .container .list .item .right {
    transform: scale(1.15);
    border-radius: 50%;
    border: 1px solid  $primary-color ;
    background-clip: text;
    // -webkit-text-fill-color: transparent;
    // border: 1px solid rgba(112, 88, 88, 0.877);
    color: $primary-color;
    padding: 15px;
  }
  .item-box {
    padding-left: 0!important;
    padding-right: 0!important;
    z-index: 1000;
    height: 100%;
    width: 100%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    // padding: 0px 0;
    padding-bottom: 0px;
    // overflow-y: auto;
  }
  .item-box .list{
    padding-left: 0!important;
    padding-right: 0!important;
    text-align: left;
    height: 100px;
    width: 100%;
    margin: 0px;
    padding: 10px;
    // overflow-y: auto;
    // -webkit-overflow-scrolling: touch;
  }
  .item-box .list .item {
    // overflow: auto;
    // -webkit-overflow-scrolling: touch;
    // overflow-y: auto;
    // -webkit-overflow-scrolling: touch;
    margin-top: 7px;
    display: flex;
    //overflow-y: auto;
    align-items: center;
    justify-content: space-between;
    align-content: space-between;
    border-bottom: 1px solid rgba(210, 210, 210, 0.815);
    height: 75px;
    padding: 5px;
  }
  .item-box .list .item .middle .title {
    margin-top: 0px;
    color: rgba(0, 0, 0, 0.851);
    font-size: 16px;
    font-weight: 500;
    display: flex;
    letter-spacing: 1px;
    justify-content: space-between;
  }
  .item-box .list .item .middle .entitle {
    margin-top: 2px;
    transform: scale(0.8);
    margin-left: -16px;
    color: rgba(67, 67, 67, 0.851);
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 1px;
  }
  .item-box .list .item .right {
    transform: scale(1.5);
    border-radius: 50%;
    // border: 1px solid  rgba(177, 198, 44, 0.877) ;
    background-clip: text;
    // -webkit-text-fill-color: transparent;
    // border: 1px solid rgba(112, 88, 88, 0.877);
    color: rgba(177, 198, 44, 0.877);
    margin-top: 30px;
    width: 20px;
    // margin-left: 0px;
  }
  .nameHint {
    width: 95%;
    height: 40px;
    margin: 10px auto;
    border: 1px solid #F37423;
    border-radius: 4px;
    background-color: #ffffff;
  }
  .drinkItem {
    position: fixed;
    overflow: hidden;
    margin: 10px auto;
    height: 100%;
    width: 100%;
    padding-bottom: 150px;
    // overflow-y: auto;
    // -webkit-overflow-scrolling: touch;
  }
  .drinkPopDia {
    // padding: 1em;
    // position: absolute;
    z-index: 1000;
    // display: grid;
    // grid-gap: 15px;
    position: fixed;
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
    height: 800px;
    // max-height: 800px;
    background-color: #f9f9f9;
    // overflow-y: auto;
    // -webkit-overflow-scrolling: touch;
    // background-color: #7e7e7e;
  }
  .drinkPopDia .movePart {
    position: relative;
    height: calc(100% - 250px);
    width: 100%;
    overflow: scroll;
    // -webkit-overflow-scrolling: touch;
  }
  .drinkPopDia .drinktop {
    display: grid;
    grid-template-columns: 10% 70% 20%;
    position: fixed;
    z-index: 10000;
    width: 100%;
    height: 40px ;
    // background-color: rgba(255, 255, 255, 0.24);
    // box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.4);
  }
  .drinkPopDia .title {
    display: grid;
    grid-gap: 5px;
    width:95%;
    padding: 10px;
    height: 60px;
    // max-height: 60px;
    background-color: rgba(255, 255, 255, 0.933);
    margin: 0 auto;
    border-radius:5px;
    text-align: left;
    // background-color: rgba(255, 255, 255, 0.24);
    // box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.4);
  }
  .drinkPopDia .item_contain {
    width:95%;
    height: 400px;
    // height: calc(100% - 250px);
    background-color: rgba(255, 255, 255, 0.933);
    margin: 15px auto;
    border-radius:5px;
    grid-template-columns: 2fr 2fr ;
    grid-template-rows:  2fr 2fr;
    grid-gap: 20px;
    padding: 10px;
  }
  .bottom_item_box {
    padding-left: 0!important;
    padding-right: 0!important;
    // height: 150px;
    width: 100%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0;
  }
  .bottom_list {
    padding-left: 0!important;
    padding-right: 0!important;
    text-align: left;
    height: 110px;
    width: 94vw;
    margin-top: 0px;
  }
  .bottom_item {
    // margin-top: 0px;
    // border-bottom: none;
    // // align-items: center;
    // height: 55px;
    // padding: 5px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: space-between;
    margin-top: 5px;
    // border-bottom: 1px solid rgba(210, 210, 210, 0.815);
    height: 50px;
    padding: 0px;
  }
  .bottom_left {
    // height: 5px;
    // text-align: left;
    align-items: center;
  }
  // DEV NOTE: 20210914 增加‘返回菜單’按鈕bottom_left_button樣式
  .bottom_left_button {
  // background-clip: text;
  font-size: 15px;
  border-radius: 20px;
  width: 90px;
  height: 35px;
  margin-top: 15px;
  margin-left: -380%;
  // -webkit-text-fill-color: transparent;
  // border: 1px solid rgba(112, 88, 88, 0.877);
  color: rgb(255, 255, 255);
  background-color: #da3925;
  padding: 0px;
  z-index: 1000;
  }
  .bottom_right {
    background-clip: text;
    font-size: 17px;
    // width: 70px;
    // -webkit-text-fill-color: transparent;
    // border: 1px solid rgba(112, 88, 88, 0.877);
    color:#F37423;
    padding: 0px;
    transform: translateX(-20px);
  }
  .bottom_middle_button {
    width: 110px;
    height: 35px;
    line-height: 0px;
    font-size: 15px;
    color: #F37423;
    background-color: rgba(255, 255, 255, 0.877);
    border:1px solid #F37423;
    border-radius:50px;
    margin-left: 30px;
  }
  .bottom_right_button {
    // background-clip: text;
    font-size: 15px;
    border-radius:50px;
    width: 110px;
    height: 35px;
    // -webkit-text-fill-color: transparent;
    // border: 1px solid rgba(112, 88, 88, 0.877);
    color: rgb(255, 255, 255);
    background-color: #F37423;
    padding: 0px;
  }
  .bottom_item .title{
    color: rgba(0, 0, 0, 0.851);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    height: 35px;
    // justify-content: space-between;
  }
  .bottom_item .entitle{
    width: 200px;
    font-size: 13px;
    color: rgb(91, 91, 91);
    margin-left: 15px;
  }
  .drinkPopDia .item_contain .item{
    text-align: left;
    color: #5b5b5b;
    width: 22em;
    max-width: 540px;
    height: 60px;
    margin: 12px 0px;
    // background: #ffa5a8;
  }
  .drinkPopDia .item_contain .item .button{
    color: #6e6e6e;
    background-color: transparent;
    width: 65px;
    height: 28px;
    margin: 5px;
    border-radius: 50px;
    font-size: 13px;
  }
  .active{
    border: 1px solid rgb(255, 116, 30);
    color: rgb(19, 3, 3);
    background-color:rgb(255, 255, 255);
  }
  .drinkPopDia .bottom_contain {
    position: fixed;
    width:100%;
    // height: 200px;
    border-top: 1px solid #dfdfdf;
    background-color: #ffff;
    // background-color: #f9f9f9;
    bottom: 0;
    // margin: 0px auto;
    // display: grid;
    // grid-gap: 5px;
    // grid-template-columns: 33% 33% 33%;
    padding: 5px;
  }
  .boldFont {
    font-weight: 500;
  }
</style>