<template>
  <div class="menu">
    <div class="statusBar" >
      <br>
      <div style="position: fixed; width: 100vw; height: 40px; margin-top: 0px; right: 0; left: 10px;" @click="orderRange">
        你的最新訂單<i class="cubeic-pulldown"></i>
      </div>
    </div>
    <div class="container" id="container">
      <div class="dia" v-if="orderRecord.length === 0">
        <div style="transform: scale(4); width: 50px; height: 20px;margin: 35px auto; margin-top: 60px; color: var(--main-color);">
          <i class="cubeic-warn"></i><br><br>
        </div>
        <span style="color: var(--main-color); opacity: 0.8;">目前無記錄，請選擇歷史訂單或是至門市進行消費。</span>
      </div>
      <div class="blog-post" v-else v-for="(item, index) in orderRecord" :key="index">
      <ul class="list" >
        <div class="item">
          <div class="left" style="margin-top: 10px;">
            <li class="title">訂單狀態: </li>
            <!-- DEV NOTE:20210914 將待接單文字改成：訂單已接單 商品製作中 ping -->
            <li class="entitle"><span style="color: var(--main-color); display:flex; flex-wrap:wrap; width:80px">{{ item.status === 'rejectOrder' ? '拒絕接單' : item.status === 'completeOrder' ? '訂單完成' : item.status === 'takeOrder' ? '訂單已接單 商品製作中' : item.status === 'processing' ? '待接單' : Math.sign(item.totalPrice) === -1  ? '已退款' : '訂單完成'}} </span></li>
          </div>
          <div class="middle">
            <li class="title">日期:{{translate(item.time)}}</li>
          </div>
        </div>
        <div class="item">
          <div class="left">
            <li class="entitle">取貨店家：<span style="color: var(--main-color);">{{ shopList.filter(el => el.shopNo === item.posId)[0] ? shopList.filter(el => el.shopNo === item.posId)[0].shopName : item.posId }}</span></li>
          </div>
          <div class="middle" v-if="item.status !== 'processing' && item.status !== 'rejectOrder'">
            <li class="title" @click="getDetail(item.orderId, item.posId, item.status, item.secretkey)"> <span style="color: var(--main-color);">更多資訊 <i class="cubeic-arrow"></i></span></li>
          </div>
        </div>
        <div class="item">
          <div class="left">
            <li class="entitle">訂單編號：<span style="color: var(--main-color);">{{ item.orderId ? item.orderId.substr(0,13) : ''}}</span></li>
          </div>
          <div class="middle">
            <li class="title" @click="callShop(shopList.filter(el => el.shopNo === item.posId)[0].phone)"> <span style="color: var(--main-color);">門市電話 <i class="cubeic-phone"></i></span></li>
          </div>
        </div>
      </ul>
      </div>
    </div>
    <div id="layer1" v-if="exchangePage === true">
      <div class="info">
        <div class="drinktop">
          <div style="position: fixed; right: 15px; margin: -10px 15px; transform: scale(1.3); color: var(--main-color)" @click="exchangePage = false"><i class="cubeic-wrong"></i></div>
          <!-- <div style="margin:20px -10px 10px 290px; transform: scale(1.3); color: #51b0b5" @click="exchangePage = false"><i class="cubeic-wrong"></i></div> -->
        </div>
        <br><br>
        <div class="list">
          <div class="item" style="text-align:center;">
            <div class="left"></div>
            <div class="middle" style="margin-left: 0px;">
              <qrcode-vue :value="qrCode" size="160" level="H"></qrcode-vue>
              <br>
            </div>
            <div class="right">
            </div>
          </div>
        </div>
        <hr width=100% size=2 color=black style="filter:progid:DXImageTransform.Microsoft.Glow(color=#5151A2,strength=10)">
        <div class="title">
          <ul style="text-align: left; margin-left: 20px;padding: 10px; font-size: 17px;">
            <!-- DEV NOTE:20210914 將待接單文字改成：訂單已接單 商品製作中 ping -->
            <li style="line-height:25px;">訂購狀態: {{ statusDetail.status === 'rejectOrder' ? '拒絕接單' : statusDetail.status === 'completeOrder' ? '訂單完成' : statusDetail.status === 'takeOrder' ? '訂單已接單 商品製作中' : '待接單' }}</li>
            <li style="line-height:25px;">訂購店鋪: {{ shopList.filter(el => el.shopNo === statusDetail.shop)[0] ? shopList.filter(el => el.shopNo === statusDetail.shop)[0].shopName : statusDetail.shop}}</li>
            <li style="line-height:25px;">訂購金額: NT.{{ statusDetail.amount }}</li>
            <li style="line-height:25px;">取餐時間: {{ statusDetail.takeTime }}</li>
            <li style="line-height:25px;">備註: {{ statusDetail.remark }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapMutations, mapGetters, mapActions } from 'vuex'
import QrcodeVue from 'qrcode.vue';
const container = document.getElementById('container');
export default {
  data () {
    return {
      qrCode: '',
      exchangePage: false,
      statusDetail: {},
      load: false,
      allowCall: true,
      date: '',
      index: 0,
      selectedLabelDefault: '已完成',
      tabs: [{
        label: '已完成',
        C_record: [],
        // icon: 'cubeic-home'
      },
      // {
      //   label: '進行中',
      //   record: [],
      //   // icon: 'cubeic-home'
      // }
      ],
    }
  },
  components: {
    QrcodeVue,
  },
  props: ['phone','eid',],
  mounted() {
    console.log(this.orderRecord);
    console.log(this.shopList);
  },
  computed: {
    ...mapGetters({
      orderRecord: 'orderRecord',
      eilisProfile: 'eilisProfile',
      personalData: 'personalData',
      shopList: 'shopList'
    }),
    storeShopList: {
      get () { return this.shopList },
      set (value) { this.updateShopList({ shopList: value }); },
    },
    storeOrderRecord: {
      get () { return this.orderRecord },
      set (value) { this.updatedOrderRecord({ OrderRecord: value }); },
    },
  },
  methods: {
    ...mapActions({
      getRecord: 'getRecord',
      getAzureOrderRecordDetail: 'getAzureOrderRecordDetail',
      getOrderRecord: 'getOrderRecord',
      getShops: 'getShops'
    }),
    ...mapMutations({
      updatedOrderRecord: 'updatedOrderRecord',
      updateShopList: 'updateShopList'
    }),
    callShop(phone) {
      console.log(phone);
      this.$createDialog({
        type: 'confirm',
        title: `聯絡店家`,
        content: '按確認將會撥出',
        icon: 'cubeic-phone',
        confirmBtn: {
          text: '確定',
          active: true,
          disabled: false,
          href: 'javascript:;'
        },
        cancelBtn: {
          text: '取消',
          active: false,
          disabled: false,
          href: 'javascript:;'
        },
        onConfirm: () => {
          window.location.href = `tel:${phone}` ;
        },
      }).show()
    },
    orderRange() {
      const column1 = [{ text: 'ㄧ週間', value: 'week'}, { text: 'ㄧ個月', value: 'month' }]
      if (!this.picker) {
        this.picker = this.$createPicker({
          title: '訂單日期區間',
          data: [column1],
          confirmTxt: '確定',
          onSelect: this.selectHandle,
          // onCancel: this.cancelHandle
        })
      }
      this.picker.show()
    },
    selectHandle(selectedVal, selectedIndex, selectedText) {
      this.$loading.show()
      console.log(selectedVal,selectedText);
      const A = this.getOrderRecord({accountId: this.eilisProfile.accountId, userId: this.personalData.userId, duration: selectedVal,type: 'online',})
      const B = this.getShops({accountId: this.eilisProfile.accountId})
      const C = this.getOrderRecord({accountId: this.eilisProfile.accountId, userId: this.personalData.userId, duration: 'week',type: 'offline',})
      Promise.all([A, B, C]).then(values => {
        // console.log(values);
        // console.log(values[0]);
        if (values[0].data.result.length !== 0 || values[2].data.result.length !== 0) {
          let offline = [];
          offline = values[2].data.result
          // console.log(offline);
          this.storeOrderRecord = values[0].data.result.concat(offline);
          this.storeShopList = values[1].data.result
          // console.log(values[1]);
        }
        this.$loading.hide()
      })
      // this.getOrderRecord({accountId: this.eilisProfile.accountId, userId: this.personalData.userId, duration: selectedVal,type: 'online',})
      // .then((res) => {
      //   console.log(res);
      //   if (res.data.result.length !== 0) {
      //     this.storeOrderRecord = res.data.result
      //     this.getShops({accountId: this.eilisProfile.accountId})
      //     .then((res) => {
      //       console.log(res);
      //     })
      //   }
      //   this.$loading.hide()
      // })
    },
    Record() {
      // console.log(this.orderRecord);
      // console.log(this.orderRecord[this.index]);
      this.getRecord({ clientId: this.info.clientId, phone: this.phone, begin: this.orderRecord[this.index].toString(), end: this.orderRecord[this.index].toString(), storeId: ''})
      .then((res) => {
        // console.log(res);
        this.addDataToDOM(res.data.result.reverse());
        this.index = this.index + 1;
        this.updateInd = this.index
        this.updateRec.push(res.data.result);
        // console.log(this.updateRec);
        setTimeout(() => {
          // this.addDataToDOM(res.data.result[0]);
          // this.index = this.index += 1;
          this.allowCall = true;
        },1000)
        // console.log(this.index);
        // console.log(this.orderRecord.length - 1);
      })
    },
    showLoading() {
      const loading = document.querySelector('.loading');
      loading.classList.add('show');
      // load more data
      setTimeout(this.Record(), 1000)
    },

    getRandomNr() {
      this.index = this.index + 1
    },
    translate(data) {
      return new Date(data).toLocaleString()
    },
    getDetail(val, posId, status, secretkey) {
      console.log(secretkey);
      this.$loading.show()
      this.statusDetail.numbers = val;
      console.log(val);
      this.getAzureOrderRecordDetail({ orderId: val, secretKey: secretkey,})
      .then((res) => {
        console.log(res);
        if (res.data && res.data.existingTxSalesHeader) {
          this.qrCode = `https://xxxxxxxx?order_token=${val}`
          console.log(this.qrCode);
          this.statusDetail.shop = posId; // 店鋪
          this.statusDetail.status = status; // 店鋪
          this.statusDetail.amount = res.data.existingTxSalesHeader.amountSubtotal; //金額
          this.statusDetail.takeTime = res.data.existingTxSalesHeader.remark1 ? res.data.existingTxSalesHeader.remark1 : res.data.existingTxSalesHeader.checkinDatetime.substr(0,16).replace('T','-');// 取貨時間
          this.statusDetail.remark = res.data.existingTxSalesHeader.remark2 // 備註
          console.log(this.statusDetail);
          this.exchangePage = true;
          this.$loading.hide()
        } else {
          this.$loading.hide()
        }
      })
    },
    addDataToDOMArr(data) {
      const container = document.getElementById('container');
      this.$loading.show()
      console.log(data);
      console.log(container);
      for (let i = 0; i< data.length ;i++) {
        // DEV NOTE:20210914 將待接單文字改成：訂單已接單 商品製作中 ping
        // for (let e = 0;  e < data[i].length ;e++) {
          const postElement = document.createElement('div');
          postElement.classList.add('blog-post');
          postElement.innerHTML = `
            <ul class="list">
              <div class="item">
                <div class="left">
                  <li class="title">訂單狀態:</li>
                  <li class="entitle">${data[i].status === 'rejectOrder' ? '拒絕接單' : data[i].status === 'completeOrder' ? '訂單完成' : data[i].status === 'takeOrder' ? '訂單已接單 商品製作中' : '待接單 '}</li>
                </div>
                <div class="middle">
                  <li class="title">日期:${this.translate(data[i].time)}</li>
                </div>
              </div>
              <div class="item">
                <div class="left">
                  <li class="entitle">取貨店家：${data[i].posId}</li>
                </div>
                <div class="middle">
                  <li class="title" @click="${this.getDetail(data[i].posId)}">更多資訊</li>
                </div>
              </div>
              <div class="item">
                <div class="left">
                  <li class="entitle">訂單編號：${data[i].orderId.substr(0,7)}</li>
                </div>
              </div>
            </ul>
          `;
          container.appendChild(postElement);
          // loading.classList.remove('show');
          this.$loading.hide()
          //this.allowCall = true;
        // }
      }
    },
  }
}
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,600&display=swap');

  body {
    font: 300 14px/18px Roboto;
    padding-bottom: 100px;
  }

  .menu {
    z-index: 0;
    padding-left: 0!important;
    padding-right: 0!important;
    margin: 0 auto;
    overflow: hidden;
    width: 100%;
    max-width: 540px;
    // height: 650px;
    background-color: #ffffff;
    /* border: 1px solid rgb(170, 170, 170); */
    /* border:1px solid #8a8a8a; */
  }
  #layer1{
    z-index: 100000000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(181, 181, 181, 0.419);
  }
  .info {
    // position: fixed;
    border: 1px solid rgb(211, 211, 211);
    z-index: 1000;
    width: 80%;
    margin: 0 auto;
    background-color: #ffffff;
    margin-top: 90px;
    height: 400px;
    font-size: 0.5cm;
    border-radius: 10px;
    overflow-y: auto;
    padding: 30px;
    // transform:translate(-50%,-50%);
    animation: bomaction 0.15s linear forwards;
    // transform: rotate(0deg); // fix 子元素超出边框圆角部分不隐藏的问题
  }
  @keyframes bomaction {
    0% {
      -webkit-transform: scale(0.1)  rotate(0deg);
              transform: scale(0.1)  rotate(0deg);
      top:0%;
    }
    46% {
      -webkit-transform: scale(0.2)  rotate(0deg);
      transform: scale(0.2)  rotate(0deg);
      top:30%;
    }
    48% {
      -webkit-transform: scale(0.3)  rotate(0deg);
      transform: scale(0.3)  rotate(0deg);
      top:48%;
    }
    50% {
      -webkit-transform: scale(0.5)  rotate(0deg);
              transform: scale(0.5)  rotate(0deg);
      top:50%;
    }
    54% {
      top:50%;
    }
    56% {
      top:50%;
    }
    57% {
      -webkit-transform: scale(0.9)  rotate(0deg);
              transform: scale(0.9)  rotate(0deg);
      top:50%;
    }
    75% {
      -webkit-transform: scale(0.95)  rotate(0deg);
              transform: scale(0.95)  rotate(0deg);
      top:50%;
    }
    90% {
      -webkit-transform: scale(1.15)  rotate(0deg);
              transform: scale(1.15)  rotate(0deg);
      top:50%;
    }
    100% {
      -webkit-transform: scale(1) translateX(0px) rotate(0deg);
              transform: scale(1) translateX(0px) rotate(0deg);
      top:50%;
    }
  }
  .dia {
    font-size: 16px;
    padding: 10px;
    width: 75%;
    max-width: 300px;
    height: 180px;
    border-radius: 10px;
    border: 1px solid var(--main-color);
    margin: 50px auto;
  }
  .top {
    width: 100vw;
    height: 45px ;
    background-color: #ffffffff;
  }
  .top .list{
    text-align: left;
    height: 400px;
    width: 103vw;
  }
  .top  .list .item{
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: space-between;
    border-bottom: 2px solid rgba(210, 210, 210, 0.815);
    height: 40px;
    padding: 5px;
  }
  .statusBar {
    // border-bottom: 1px solid var(--main-color);
    z-index: 1000;
    position: fixed;
    font-size: 17px;
    width: 100vw;
    height: 50px ;
    background-color: #ffffffff;
    box-shadow: 0 4px 1px -2px rgb(194, 194, 194);
  }

  .container {
    // height: 900px;
    width: 100%;
    min-width: 350px;
    color: black;
    height: calc(100% - 200px);
    margin: 0 auto;
    margin-top: 40px;
    max-width: 600px;
    overflow: auto;
    overflow-y: auto;
    -ms-overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 100px;
  }

  .container .blog-post {
    background-color: #fff;
    // box-shadow: 1px 1px 2px rgba(50, 50, 50, .1), 5px 1px 3px 1px rgba(50, 50, 50, .1), 0px 0px 2px 3px rgba(60, 60, 60, 0.1);
    box-shadow: 1px 1px 5px var(--main-color);
    border-radius: 4px;
    // DEV NOTE:20210914 將height:110 改為 min-height ping
    width: 85%;
    min-height: 90px;
    padding: 20px;
    margin: 30px auto;
  }
  .container .blog-post .list{
    margin-left: -10px;
    text-align: center;
    height: 100%;
    max-height: 140px;
    // DEV NOTE:20210914 調整寬度 ping
    width: 90vw;
  }
  .container .blog-post .list .item{
    margin-left: -10px;
    margin-top: -20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: space-between;
    // height: 40px;
    padding: 7px;
    width: 95%;
  }
  .container .blog-post  .list .item .title{
    margin-top: 0px;
    color: rgba(33, 33, 33, 0.851);
    font-size: 15px;
    letter-spacing: 0.3px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
  }
  .container .blog-post .list .item .middle{
    font-size: 13px;
  }
  .entitle{
    text-align: left;
    font-size: 14px;
    // color: var(--main-color);
    margin-top: 0px;
    margin-top: 3px;
  }
</style>

