<template>
<div class="menu">
<div class="mobile-wrap" @click="judge">
  <couponPage v-if="coupon === true" @toMain="toMain"></couponPage>
  <div class="wrapper" id="app">
    <div class="card-form">
      <div class="card-list">
        <div class="card-item" v-bind:class="{ '-active' : isCardFlipped }">
          <div class="card-item__side -front">
            <div class="card-item__focus" v-bind:class="{'-active' : focusElementStyle }" v-bind:style="focusElementStyle" ref="focusElement"></div>
            <div class="card-item__cover">
              <img  :src="identifyMemberCard" class="card-item__bg" loading="eager">
              <!-- <img  :src="identifyMemberCard.memberLink" class="card-item__bg" style="transform: scale(1.075) translateY(0%) translateX(0px); "> -->
              <!-- <img v-bind:src="'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' + currentCardBackground + '.jpeg'" class="card-item__bg"> -->
            </div>

            <div class="card-item__wrapper">
              <div class="card-item__top">
              </div>
              <label for="cardNumber" class="card-item__number" ref="cardNumber">
                <template v-if="getCardType === 'amex'">
                 <span v-for="(n, $index) in amexCardMask" :key="$index">
                  <transition name="slide-fade-up">
                    <div
                      class="card-item__numberItem"
                      v-if="$index > 4 && $index < 14 && cardNumber.length > $index && n.trim() !== ''"
                    >*</div>
                    <div class="card-item__numberItem"
                      :class="{ '-active' : n.trim() === '' }"
                      :key="$index" v-else-if="cardNumber.length > $index">
                      {{cardNumber[$index]}}
                    </div>
                    <div
                      class="card-item__numberItem"
                      :class="{ '-active' : n.trim() === '' }"
                      v-else
                      :key="$index + 1"
                    >{{n}}</div>
                  </transition>
                </span>
                </template>

                <template v-else>
                  <div style="letter-spacing:2px; margin-top: 20px;">{{ personalData ? personalData.userId : ''}}</div>
                </template>
              </label>
              <div class="card-item__content">
                <label for="cardName" class="card-item__info" ref="cardName">
                  <div class="card-item__holder">Card Holder</div>
                  <transition name="slide-fade-up">
                    <div class="card-item__name" key="2">{{ personalData ? personalData.name : '' }}</div>
                  </transition>
                </label>
                <div class="card-item__date" ref="cardDate" >
                  <label for="cardMonth" class="card-item__dateTitle" >Points</label>
                  <label for="cardMonth" class="card-item__dateItem">
                    <transition name="slide-fade-up">
                      <span style="margin-left: 20px;">{{ personalData.point }} </span>
                      <!-- <div>{{ personalData ? personalData.pointRecord[new Date().getFullYear()] : ''}}</div> -->
                      <!-- <span v-else key="2">MM</span> -->
                    </transition>
                  </label>
                </div>
                <div class="card-item__status" >
                  <div>Status</div>
                  <div>{{ clientLevel && brandData.azure === true ? clientLevel : !clientLevel && brandData.azure === true ? '銅饗' : clientLevel }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="margin: 15px;">
    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr;" @click="coupon = true">
      <!-- DEV NOTE:20210915 將券夾修改券匣 ping -->
      <button type="button" class="button0" >我的券匣</button>
      <!-- <div style="transform: translateY(-60%)"><i class="cubeic-tag"></i> 我的券夾</div>
      <div style="transform: translateY(-50%)"></div>
      <div style="transform: translateY(-50%); font-size: 15px; color: var(--main-color);"><i class="cubeic-arrow"></i></div> -->
    </div>
  </div>
  <div class="qrcode">
    <div style="display: flex; justify-content: center;">
      <div class="containerbtn" style="top: 20px;">
      <div class="radio-tile-group">
        <div class="input-container" @click="popQr">
          <input id="bike" class="radio-button" type="radio" name="radio" />
          <div class="radio-tile">
            <!-- <div class="icon bike-icon">
              <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0h24v24H0z" fill="none"/>
                <path d="M15.5 5.5c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zM5 12c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5zm0 8.5c-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5 3.5 1.6 3.5 3.5-1.6 3.5-3.5 3.5zm5.8-10l2.4-2.4.8.8c1.3 1.3 3 2.1 5.1 2.1V9c-1.5 0-2.7-.6-3.6-1.5l-1.9-1.9c-.5-.4-1-.6-1.6-.6s-1.1.2-1.4.6L7.8 8.4c-.4.4-.6.9-.6 1.4 0 .6.2 1.1.6 1.4L11 14v5h2v-6.2l-2.2-2.3zM19 12c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5zm0 8.5c-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5 3.5 1.6 3.5 3.5-1.6 3.5-3.5 3.5z"/>
              </svg>
            </div> -->
            <label for="bike" class="radio-tile-label"><i class="cubeic-qr-code"></i> 會員QRCode</label>
          </div>
        </div>

        <div class="input-container" @click="popBar">
          <input id="drive" class="radio-button1" type="radio" name="radio" />
          <div class="radio-tile">
            <label for="drive" class="radio-tile-label"><i class="cubeic-credit-card"></i> 手機載具條碼</label>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div v-if="selectedCode === '1'" @click="popOut" style="text-align: center; padding: 15px; border: 1px solid #eee; width: 90%; margin: 0 auto; margin-top: 5px; border-radius: 7px;">
      <div style="text-align: right" @click="popOut()"><i class="cubeic-search"></i></div>
      <qrcode-vue :value="personalData.phone" size="145" level="Q"></qrcode-vue>
    </div>
    <div v-if="selectedCode === '2'" style="text-align: center; padding: 15px; border: 1px solid #eee; width: 90%; margin: 0 auto; margin-top: 5px; border-radius: 7px;">
      <i class="cubeic-edit" @click="addCarrierNumbers()"></i>
      <barcode  :value="tempCarrier" height="86%">
      </barcode>
      <!-- <img :src="barCode" width="100%" height="75px"/> -->
    </div>
  </div>

  <div id="dialog" class="dialog" v-if="showNoInfo === true " :class="showNoInfo === true ?'fadein':'fadeout'">
    <div class="cube-popup cube-popup_mask cube-dialog" style="z-index: 100;">
      <div class="cube-popup-mask dialog-mask"></div>
      <div class="cube-popup-container cube-popup-center dialog-content">
        <div class="cube-popup-content">
          <div class="cube-dialog-main">
            <span class="cube-dialog-close">
              <i class="cubeic-close" @click="closeInfo"></i>
            </span>
            <div class="cube-dialog-alert">
              <h2 class="cube-dialog-title">
                <!-- <i class="cubeic-alert" style="font-size: 28px;display: inline-block; width: 30px; height: 30px; padding: 10px; box-sizing: content-box; border-radius: 50%; background-color: #f3f4f5;"></i> -->
                <br>
                <qrcode-vue :value="personalData.phone" size="195" level="Q"></qrcode-vue>
              </h2>
              <div class="cube-dialog-content">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</template>

<script>
/* eslint-disable */
import { mapMutations, mapGetters, mapActions, createNamespacedHelpers} from 'vuex'
import QrcodeVue from 'qrcode.vue';
import VueBarcode from 'vue-barcode';
import couponPage from '@/components/user/couponPage.vue'
const CryptoJS = require('crypto-js');
// console.log(CryptoJS);
export default {
  data () {
    return {
      // 信用卡
      getCardType:'',
      currentCardBackground: Math.floor(Math.random()* 25 + 1), // just for fun :D
      cardName: "",
      cardNumber: "",
      cardMonth: "",
      cardYear: "",
      cardCvv: "",
      minCardYear: new Date().getFullYear(),
      // amexCardMask: "#### ###### #####" || this.eid,
      // otherCardMask: this.eid ,
      cardLink: '',
      coupon: false,
      cardNumberTemp: "",
      isCardFlipped: false,
      focusElementStyle: null,
      isInputFocused: false,
      // 信用卡
      showNoInfo: false,
      selectedCode: '1',
      clientLevel: '',
      channelData: {
        AuthToken: this.$route.query.Auth,
        Service: this.$route.params.service,
        PhoneNumber: '',
        Name: this.$route.query.Name ? this.$route.query.Name : '',
        PageID: this.$route.query.Page,
      },
    }
  },
  props: ['qrCode','barCode', 'points', 'user', 'eid',],
  components: {
    QrcodeVue,
    couponPage,
    'barcode': VueBarcode
  },
  mounted() {
    console.log(this.brandData);
    console.log(this.personalData);
    // console.log(this.tempCarrier);
    // 讓qrcode 按鈕被按的效果
    const el = document.querySelector('.radio-button');
    el.checked = true;
    // if (!this.personalData.level) {
    //   return this.brandData.defaultCard
    // }
    // else {
    //   const exp = this.personalData.exp
    //   console.log(exp);
    //   for (let i = 0; i < this.brandData.levels.length; i++) {
    //     console.log(this.brandData.levels[i]);
    //     if (this.personalData.exp <= this.brandData.levels[i].exp && this.brandData.levels[i + 1] ? this.personalData.exp < this.brandData.levels[i + 1].exp : '') {
    //       console.log(this.brandData.levels[i].exp);
    //       return this.brandData.levels[i].img
    //     } else if ((i === this.brandData.levels.length - 1) && this.brandData.levels[i].exp <= this.personalData.exp) {
    //       console.log(i);
    //       return this.brandData.levels[i].img
    //     }
    //   }
    // }
  },
  watch: {
  },
  computed: {
    ...mapGetters({
      eilisProfile: 'eilisProfile',
      personalData: 'personalData',
      brandData: 'brandData',
      tempCarrier: 'tempCarrier',
      levelContent: 'levelContent'
    }),
    storeCarrierNumber: {
      get () { return this.tempCarrier },
      set (value) { this.updateCarrier({ carrier: value }); },
    },
    identifyMemberCard() {
     if (this.levelContent) {
      if (!this.personalData.level || this.personalData.level === '一般') {
        return this.brandData.defaultCard
      }
      else {
        let cardLink = '';
        console.log(this.levelContent.list);
        // console.log(this.brandData.levels.filter(el => el.exp >= this.personalData.exp));
        const Exp = this.personalData.exp
        console.log('經驗值:' + Exp);
        Array.min = function( array ){
        return Math.min.apply( Math, array );
        };
        for (let i = 0; i < this.levelContent.list.length; i++) {
          console.log(i);
          if (i === this.personalData.level) {
            cardLink = this.levelContent.list[i].img
            console.log(this.levelContent.list[i].name);
            console.log(cardLink);
            this.clientLevel = this.levelContent.list[i].name
          }
        }
        return cardLink;
      }
     } else {
       setTimeout(() => {
        if (!this.personalData.level || this.personalData.level === '一般') {
          return this.brandData.defaultCard
        }
        else {
          let cardLink = '';
          console.log(this.levelContent.list);
          // console.log(this.brandData.levels.filter(el => el.exp >= this.personalData.exp));
          const Exp = this.personalData.exp
          console.log('經驗值:' + Exp);
          Array.min = function( array ){
          return Math.min.apply( Math, array );
          };
          for (let i = 0; i < this.levelContent.list.length; i++) {
            if (this.levelContent.list[i].name === this.personalData.level) {
              cardLink = this.levelContent.list[i].img
            }
          }
          return cardLink;
        }
       },900)
     }
    },
  },
  methods: {
  ...mapActions({
    getUserData: 'getUserData',
    getAccessToken: 'getAccessToken',
    getUserOrder: 'getUserOrder',
    updateInfo: 'updateInfo',
  }),
  ...mapMutations({
    updateCarrier: 'updateCarrier'
  }),
    calLevel () {
    console.log(this.levelContent);
    if (!this.levelContent || this.levelContent.length  === 0) {
      setTimeout(() => {
        for (let i = 0; i < this.levelContent.list.length; i++) {
          // console.log(i);
          if (i === this.personalData.level) {
            this.clientLevel = this.levelContent.list[i].name
          } else if (this.personalData.level === '') {
            this.clientLevel = ''
          }
        }
      },1000)
    } else {
      for (let i = 0; i < this.levelContent.list.length; i++) {
        // console.log(i);
        if (i === this.personalData.level) {
          this.clientLevel = this.levelContent.list[i].name
        } else if (this.personalData.level === '') {
          this.clientLevel = ''
        }
      }
    }
  },
  toMain(val) {
    console.log(val);
    this.coupon = false;
  },
  judge() {
    if (this.showNoInfo === true) {
      this.showNoInfo = false;
    }
  },
  closeInfo() {
    this.showNoInfo = false
  },
  popOut() {
    console.log('1');
    setTimeout(() => {
      this.showNoInfo = true;
    },50)
  },
  addCarrierNumbers() {
    this.dialog = this.$createDialog({
      type: 'prompt',
      title: '請輸入載具條碼',
      confirmBtn: {
        text: '確定',
        active: true,
        disabled: false,
        href: 'javascript:;'
      },
      prompt: {
        value: '',
        placeholder: '範例: /AD9CKHG'
      },
      onConfirm: (e, promptValue) => {
        console.log(promptValue.length);
        const toast = this.$createToast({
          time: 100000,
          txt: 'loading'
        })
        toast.show()
        for (let i = 0 ; i < promptValue.length; i++) {
          if (promptValue[0] !== '/') {
            const alert = this.$createToast({
              txt: '格式有誤,請參考範例',
              type: 'warn'
            })
            alert.show()
            this.popBar()
            return;
          }
        }
        if (promptValue.length !== 8) {
          // alert('條碼限制為八碼')
          // this.selectedCode = '1';
          const alert = this.$createToast({
            txt: '條碼限制為八碼',
            type: 'warn'
          })
          alert.show()
          // toast.hide()
          this.popBar()
        }
        else {
          // /AD9CKHG
          // console.log(promptValue);
          this.updateInfo({phone: this.personalData.phone, carrier: promptValue})
          .then((res) => {
            this.storeCarrierNumber = promptValue;
            const str = ''
            promptValue = str
            // this.tempCarrier = promptValue;
            toast.hide()
            this.$createToast({
              type: 'correct',
              time: 3000,
              txt: `載具條碼: ${promptValue}已成功綁定`
            }).show()
            // toast.hide()
            // console.log(res);
          })
        }
      },
      onCancel: () => {
        const el1 = document.querySelector('.radio-button1');
        el1.checked = false;
        const el = document.querySelector('.radio-button');
        el.checked = true;
        this.selectedCode = '1';
        }
      }).show()
  },
  popQr() {
    this.selectedCode = '1';
  },
  popBar() {
      // console.log(this.tempCarrier);
      if (!this.tempCarrier) {
        this.addCarrierNumbers()
        this.selectedCode = '2';
      } else {
        this.selectedCode = '2';
      }
    },
  }
}
</script>

<style lang="scss" scoped>
    @import url("https://fonts.googleapis.com/css?family=Source+Code+Pro:400,500,600,700|Source+Sans+Pro:400,600,700&display=swap");

body {
  background: #ddeefc;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
}
  * {
    box-sizing: border-box;
    &:focus {
      outline: none;
    }
  }
  .mobile-wrap {
    padding-left: 0!important;
    padding-right: 0!important;
    // margin: 50px auto;
    overflow: hidden;
    width: 100%;
    max-width: 540px;
    height: 900px;
    background-color: #ffffff;
    /* border: 1px solid rgb(170, 170, 170); */
    /* border:1px solid #8a8a8a; */
  }
  .button0 {
    font-size: 15px;
    border: none;
    padding-left: 0!important;
    padding-right: 0!important;
    width: 90vw;
    height: 33px;
    // position: relative;
    cursor: pointer;
    background: var(--main-color);
    border-radius: 5px;
    padding: 2px 7px;
    color: white;
    margin-top: -20px;
    margin-left: 5px;
    .button0:hover {
      opacity: 0.7;
    }
  }
  // .mobile-wrap:before {
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   content: '';
  //   position: absolute;
  //   // background: rgba(90, 93, 165, .8);
  //   // background: linear-gradient(to bottom, rgba(90, 93, 165, 1), rgba(0, 0, 0, .7))
  // }
  .dialog {
    position: fixed;
    width: 100vw;
    right: 0;
    bottom: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.40);
    border: none;
    border-radius: 5px;
    transform: translate(0, 0);
    color: #fff;
    font-size: 13px;
    z-index: 99999999;
    text-align: justify;
  }
.wrapper {
  min-height: 15vh;
  display: flex;
  padding: 20px 15px;
  @media screen and (max-width: 700px), (max-height: 500px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
}
.card-item__wrapper {
  top: 38px;
  position: relative;
  left: -30px;
}
.card-item__name {
  //transform: translateX(20px);
  margin-left: -30px;
}
.card-form {
  max-width: 580px;
  margin: auto;
  // min-width: 350px;
  width: 100%;

  @media screen and (max-width: 576px) {
    margin: 0 auto;
  }

  &__inner {
    background: #fff;
    // box-shadow: 3px 13px 30px 0px rgba(21, 34, 67, 0.2);
    box-shadow: 0 30px 30px 0 rgba(90, 116, 148, 0.4);
    border-radius: 10px;
    padding: 35px;
    padding-top: 180px;

    @media screen and (max-width: 480px) {
      padding: 25px;
      padding-top: 165px;
    }
    @media screen and (max-width: 360px) {
      padding: 15px;
      padding-top: 165px;
    }
  }

  &__row {
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: 480px) {
      flex-wrap: wrap;
    }
  }

  &__col {
    flex: auto;
    margin-right: 35px;

    &:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: 480px) {
      margin-right: 0;
      flex: unset;
      width: 100%;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.-cvv {
      max-width: 150px;
      @media screen and (max-width: 480px) {
        max-width: initial;
      }
    }
  }

  &__group {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .card-input__input {
      flex: 1;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__button {
    width: 100%;
    height: 55px;
    background: #2364d2;
    border: none;
    border-radius: 5px;
    font-size: 22px;
    font-weight: 500;
    font-family: "Source Sans Pro", sans-serif;
    box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
    color: #fff;
    margin-top: 20px;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      margin-top: 10px;
    }
  }
}

.card-item {
  max-width: 450px;
  height: 270px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
  width: 100%;

  .card-item__cover{
    background-position: top center;
    // background-image: url('../img/Louisablack.png');
    // transform: rotateY(-180deg)
  }
  .card-item__bg {
    background-position: top center;
    // background-image: url('../img/blackcard.png');
    // background: url('../img/blackcard.png');
    // background-position: center;
    // // background-size: 300px 400px;
    // transform: scale(5);
  }
  @media screen and (max-width: 480px) {
    max-width: 410px;
    height: 235px;
    width: 100%;
  }
  @media screen and (max-width: 400px) {
    height: 210px;
  }
  @media screen and (max-width: 360px) {
    height: 180px;
  }

  &.-active {
    .card-item__side {
      &.-front {
        transform: perspective(1000px) rotateY(180deg) rotateX(0deg)
          rotateZ(0deg);
      }
      &.-back {
        transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
        // box-shadow: 0 20px 50px 0 rgba(81, 88, 206, 0.65);
      }
    }
  }

  &__focus {
    position: absolute;
    z-index: 3;
    border-radius: 5px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    border: 2px solid rgba(255, 255, 255, 0.65);

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: rgb(8, 20, 47);
      height: 100%;
      border-radius: 5px;
      filter: blur(25px);
      opacity: 0.5;
    }

    &.-active {
      opacity: 1;
    }
  }

  &__side {
    border-radius: 15px;
    overflow: hidden;
    // box-shadow: 3px 13px 30px 0px rgba(11, 19, 41, 0.5);
    // box-shadow: 0 1px 10px 0 rgba(14, 42, 90, 0.55);
    box-shadow: 0 1px 7px 0 rgba(14, 42, 90, 0.473);
    transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
    transform-style: preserve-3d;
    transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
    backface-visibility: hidden;
    height: 100%;

    &.-back {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      transform: perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg);
      z-index: 2;
      padding: 0;
      // background-color: #2364d2;
      // background-image: linear-gradient(
      //   43deg,
      //   #4158d0 0%,
      //   #8555c7 46%,
      //   #2364d2 100%
      // );
      height: 100%;
    }
  }
  &__bg {
    max-width: 100%;
    display: block;
    max-height: 100%;
    height: 100%;
    width: 100%;
    // object-fit: cover; //讓高度對齊邊緣
  }
  &__cover {
    position: absolute;
    height: 100%;
    background-color: #414141;
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      // background: rgba(6, 2, 29, 0.2);
    }
  }

  &__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 40px;
    padding: 0 10px;

    @media screen and (max-width: 480px) {
      margin-bottom: 25px;
    }
    @media screen and (max-width: 360px) {
      margin-bottom: 15px;
    }
  }

  &__chip {
    width: 60px;
    @media screen and (max-width: 480px) {
      width: 50px;
    }
    @media screen and (max-width: 360px) {
      width: 40px;
    }
  }

  &__type {
    height: 45px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    max-width: 100px;
    margin-left: auto;
    width: 100%;

    @media screen and (max-width: 480px) {
      height: 40px;
      max-width: 90px;
    }
    @media screen and (max-width: 360px) {
      height: 30px;
    }
  }

  &__typeImg {
    max-width: 100%;
    object-fit: contain;
    max-height: 100%;
    object-position: top right;
  }

  &__info {
    color: #fff;
    width: 100%;
    max-width: calc(100% - 85px);
    padding: 10px 15px;
    font-weight: 500;
    display: block;

    cursor: pointer;

    @media screen and (max-width: 480px) {
      padding: 10px;
    }
  }

  &__holder {
    opacity: 0.9;
    font-size: 13px;
    margin-bottom: 6px;
    @media screen and (max-width: 480px) {
      font-size: 12px;
      margin-bottom: 0px;
      margin-left: -70px;
    }
    @media screen and (max-width: 400px) {
      margin-left: -70px;
      margin-top: -10px;
    }
  }

  &__status {
    opacity: 0.9;
    font-size: 13px;
    margin-bottom: 6px;
    @media screen and (max-width: 480px) {
      font-size: 12px;
      margin-top: 10px;
      margin-left: 30px;
    }
    @media screen and (max-width: 400px) {
      margin-left: 40px;
      margin-top: 0px;
    }
  }

  &__wrapper {
    font-family: "Source Code Pro", monospace;
    padding: 25px 15px;
    position: relative;
    z-index: 4;
    height: 100%;
    text-shadow: 4px 2px 7px rgba(14, 42, 90, 0.461);
    // text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
    user-select: none;
    @media screen and (max-width: 480px) {
      padding: 20px 10px;
    }
    @media screen and (max-width: 320px) {
      left: -10px;
      padding: 0px 0px;
      // margin-bottom: 20px;
    }
  }

  &__name {
    font-size: 18px;
    line-height: 1;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }
  &__nameItem {
    display: inline-block;
    min-width: 8px;
    position: relative;
  }

  &__number {
    font-weight: 500;
    line-height: 1;
    color: #fff;
    font-size: 27px;
    margin-bottom: 35px;
    display: inline-block;
    padding: 10px 15px;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      font-size: 21px;
      margin-bottom: 15px;
      padding: 10px 10px;
    }
    @media screen and (max-width: 400px) {
      font-size: 19px;
      margin-bottom: 10px;
      padding: 10px 10px;
    }
    @media screen and (max-width: 360px) {
      font-size: 19px;
      margin-bottom: 10px;
      padding: 10px 10px;
    }
  }

  &__numberItem {
    width: 16px;
    display: inline-block;
    &.-active {
      width: 30px;
    }

    @media screen and (max-width: 480px) {
      width: 13px;

      &.-active {
        width: 16px;
      }
    }

    @media screen and (max-width: 360px) {
      width: 12px;

      &.-active {
        width: 8px;
      }
    }
  }

  &__content {
    color: #fff;
    display: flex;
    align-items: flex-start;
  }

  &__date {
    flex-wrap: wrap;
    font-size: 18px;
    margin-left: auto;
    padding: 10px;
    display: inline-flex;
    width: 80px;
    white-space: nowrap;
    flex-shrink: 0;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      font-size: 16px;
      margin-top: 0px;
      margin-left: -70px;
    }
    @media screen and (max-width: 400px) {
      font-size: 16px;
      margin-left: -70px;
      margin-top: -10px;
    }
  }

  &__dateItem {
    position: relative;
    width: 22px;
    display: inline-block;
    @media screen and (max-width: 480px) {
      font-size: 16px;
      margin-top: -5px;
    }
  }

  &__statusItem {
    font-size: 18px;
    line-height: 1;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    @media screen and (max-width: 480px) {
      font-size: 16px;
      margin-top: 0px;
    }
  }

  &__dateTitle {
    opacity: 0.9;
    font-size: 13px;
    padding-bottom: 6px;
    width: 100%;

    @media screen and (max-width: 480px) {
      font-size: 12px;
      padding-bottom: 5px;
    }
  }
  &__band {
    background: rgba(0, 0, 19, 0.8);
    width: 100%;
    height: 50px;
    margin-top: 30px;
    position: relative;
    z-index: 2;
    @media screen and (max-width: 480px) {
      margin-top: 20px;
    }
    @media screen and (max-width: 360px) {
      height: 40px;
      margin-top: 10px;
    }
  }

  &__cvv {
    text-align: right;
    position: relative;
    z-index: 2;
    padding: 15px;
    .card-item__type {
      opacity: 0.7;
    }

    @media screen and (max-width: 360px) {
      padding: 10px 15px;
    }
  }
  &__cvvTitle {
    padding-right: 10px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 5px;
  }
  &__cvvBand {
    height: 45px;
    background: #fff;
    margin-bottom: 30px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    color: #1a3b5d;
    font-size: 18px;
    border-radius: 4px;
    box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35);

    @media screen and (max-width: 480px) {
      height: 40px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 360px) {
      margin-bottom: 15px;
    }
  }
}
  .button {
    color: orange;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }
  .qrcode {
    margin-top: -15px;
    width: 100vw;
    height: 300px ;
    // background-color: rgb(114, 114, 114);
  }
  .codearea {
    width: 90%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .list{
    text-align: left;
    height: 40px;
    width: 100%;
  }
  .list .item{
    margin-top: -5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: space-between;
    // border-bottom: 1px solid rgba(210, 210, 210, 0.815);
    height: 30px;
    padding: 5px;
  }
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    box-sizing: border-box;
  }
  .container .list{
    text-align: left;
    height: 400px;
    width: 95vw;
  }
  .container .list .item{
    margin-top: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: space-between;
    border-bottom: 1px solid rgba(210, 210, 210, 0.815);
    height: 50px;
    padding: 5px;
  }
  .container .list .item .title{
    color: rgba(0, 0, 0, 0.851);
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
  }
  .container .list .item .entitle{
    font-size: 13px;
    color: #ccc;
    margin-top: 7px;
  }


$primary-color:  #F57E28; //   #079ad9;
.radio-tile-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  //set dimensions for invisible container
  .input-container {
    position: relative;
    height:  2.3rem;
    width:  10rem;
    margin: 0.5rem;
    @media screen and (max-width: 360px) {
      width: 8rem;
      height: 2rem;
    }
    //make actual radio input invisible
    // + stretch to fill container
    .radio-button {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      margin: 0;
      cursor: pointer;
    }

    .radio-button1 {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      margin: 0;
      cursor: pointer;
    }
    //default tile styles
    .radio-tile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border: 2px solid var(--main-color);
      border-radius: 5px;
      padding: 1rem;
      transition: transform 300ms ease;
    }
    .icon svg {
      fill: var(--main-color);
      width: 2rem;
      height: 2rem;
    }
    .radio-tile-label {
      text-align: center;
      font-size: 0.9rem;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: var(--main-color);
    }

    //active tile styles
    .radio-button:checked + .radio-tile {
      background-color: var(--main-color);
      border: 2px solid var(--main-color);
      color: white;
      transform: scale(1.1, 1.1);
      @media screen and (max-width: 360px) {
        transform: scale(0.9, 0.9);
      }

      .icon svg {
        fill: white;
        background-color: var(--main-color);
      }
      .radio-tile-label {
        color: white;
        background-color: var(--main-color);
      }
    }
    .radio-button1:checked + .radio-tile {
      background-color: var(--main-color);
      border: 2px solid var(--main-color);
      color: white;
      transform: scale(1.1, 1.1);
      @media screen and (max-width: 360px) {
        transform: scale(0.9, 0.9);
      }

      .icon svg {
        fill: white;
        background-color: var(--main-color);;
      }
      .radio-tile-label {
        color: white;
        background-color: var(--main-color);;
      }
    }
  }
}
</style>
