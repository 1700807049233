<template>
<div class="box">
  <!-- <div class="nameHint" v-if="drinkDialog !== 'out'" @click="test(shopName)" ><div style="margin: 10px;"><i class="cubeic-location"></i>{{ shopName }}</div></div> -->
  <!-- <cube-loading :size="35" class="loading" v-if="loading"></cube-loading>
  <div id="layer" v-if="loading"/> -->
  <!-- 菜單品項內容 -->
  <div class="drinkItem">
    <cube-scroll-nav
      :side="true"
      :data="couponList"
      :current="current"
      @change="changeHandler"
      @sticky-change="stickyChangeHandler">
      <div style="height: 15px;"></div>
      <cube-scroll-nav-panel class="cube-scroll-nav-panel"
        v-for="item in couponList"
        :key="item.type"
        :label="item.type"
        :title="item.type">
      <div class="item-box" >
        <ul class="list" v-for="(item,index) in item.item" :key="index" @click="decideItem(item)">
        <!-- <ul class="list" @click="decideItem(item)"> -->
          <div class="item" :style="{ opacity: (item.levels !== '無' && item.levels && item.levels.indexOf(clientLevel) === -1) || (item.genders && item.genders !== '' && item.genders.indexOf(personalData.gender) === -1) || (item.shops && item.shops.indexOf(personalData.shopNo) === -1) || (item.maxRedeem && redeemList.length > 0 && redeemList.filter(el => el.eventId === item.eventId).length > 0 && item.maxRedeem - redeemList.filter(el => el.eventId === item.eventId)[0].count <= 0) ? 0.5 : 1}">
            <div class="left" style="width: 90px; ">
              <img :src="item.img" width="100%" height="48px" style="border-radius:3px; margin-left: -5px; margin-top:-10px;" >
            </div>
            <div class="middle" style="width:200px; margin-left: 0px; margin-top: -5px">
              <li class="title" style="font-size: 10px;" >{{ item.itemName }}</li>
              <!-- <li class="title" v-else >{{ item.content }}</li> -->
              <li class="entitle">兌換點數:{{ item.point }}</li>
              <li class="entitle" v-if="item.maxRedeem && redeemList.length > 0 && redeemList.filter(el => el.eventId === item.eventId).length > 0 && item.maxRedeem - redeemList.filter(el => el.eventId === item.eventId)[0].count <= 0">無法兌換</li>
              <li class="entitle" v-else >{{ item.maxRedeem && redeemList.length > 0 && item.maxRedeem && redeemList.filter(el => el.eventId === item.eventId).length > 0 && redeemList.filter(el => el.eventId === item.eventId)[0] ? '兌換次數:' + redeemList.filter(el => el.eventId === item.eventId)[0].count + '/' + item.maxRedeem : item.maxRedeem ? '兌換次數:' + item.maxRedeem : null }}</li>
              <li class="entitle" :style="{ opacity: item.limit === true ? 0 : 0}">{{ item.eventId }}</li>
            </div>
            <div class="right"><i class="cubeic-arrow"/></div>
          </div>
        </ul>
      </div>
      </cube-scroll-nav-panel>
    </cube-scroll-nav>
  </div>

    <!-- 品項彈出內容 -->
    <div class="drinkPopDia" v-if="drinkDialog === 'out'">
      <div class="movePart">
        <div class="drinktop">
          <div></div>
          <div></div>
          <!-- DEV NOTE: 20210914 新增左上角‘返回菜單’ -->
          <div class="bottom_left">
            <cube-button
              class="bottom_left_button"
              @click="close"
              :primary="true"
              >返回菜單</cube-button>
          </div>
          <!-- DEV NOTE: 20210914 新增左上角‘返回菜單’ -->
          <!-- DEV NOTE: 20210914 關閉原本的‘關閉按鈕’ -->
          <!-- <div style="margin:30px -10px 10px 10px; transform: scale(1.9); color: var(--main-color); " @click="close"><i class="cubeic-wrong"></i></div> -->
        </div>
        <div class="img" style="width:100%; height:200px; background-color: #ffffff;">
          <div :style="{ overflow: 'hidden', width: `${100}%`, height: `${210}px`, backgroundImage: 'url(' + item.img + ')', backgroundSize: 'cover', backgroundPosition: 'center', margin: '0 auto', }"></div>
        </div>
        <br>
        <div class="title">
          <div style="font-weight: 500;">{{ item.itemName }}</div>
          <!-- <div style="font-size:10px;">可兌換次數:{{ item.redeemTime }}</div> -->
        </div>
        <br>
        <div class="item_contain">
          <div class="item">
             <!-- DEV NOTE:20210914 調整換行 ping -->
            <div>內容：<br>{{ item.content }} </div>
          </div>
          <!-- <div class="item">
            <div>櫃位：{{ item.saleroom }}</div>
          </div> -->
          <div class="item" style="height: 20px;">
            <div>所需點數：{{ item.point }}</div>
          </div>
          <div class="item">
            <div>注意事項：{{ item.notice }}</div>
          </div>
        </div>
      </div>
      <div class="bottom_contain" :style="{ margin: adjest === true ? '-80px auto' : '0px auto' }">
        <div class="bottom_item_box">
          <ul class="bottom_list">
            <div class="bottom_item">
              <div class="bottom_left" style="color: transparent;">充值</div>
              <div class="bottom_middle"><cube-button class="bottom_middle_button" :primary="true" @click="beforeCheck()">確認兌換</cube-button></div>
              <div class="bottom_right"><cube-button class="bottom_right_button" @click="close()" :primary="true">取消</cube-button></div>
            </div>
          </ul>
        </div>
      </div>
  </div>
  <div id="layer" v-if="loading"/>
  <cube-loading :size="35" class="loadingIcon" v-if="loading" ></cube-loading>
  <div id="layer1" v-if="exchangePage === true">
    <div class="info">
      <div class="drinktop">
        <div style="position: fixed; right: 20px; margin: -10px  auto; transform: scale(1.4); color: var(--main-color);" @click="exchangePage = false"><i class="cubeic-wrong"></i></div>
        <!-- <div style="margin:20px -10px 10px 290px; transform: scale(1.3); color: #51b0b5" @click="exchangePage = false"><i class="cubeic-wrong"></i></div> -->
      </div>
      <div class="img" style="width:100%; height: 200px; background-color: #ffffff;">
        <div :style="{ overflow: 'hidden', width: `${100}%`, height: `${210}px`, backgroundImage: 'url(' + item.img + ')', backgroundSize: 'cover', backgroundPosition: 'center', margin: '0 auto', }"></div>
      </div>
      <br>
      <div class="title" style="text-align: left; font-size: 14px;">
        <ul style="text-align: left; margin-left: 20px;padding: 10px; font-size: 14px;">
          <li style="line-height:20px;">本次兌換商品: {{ item.itemName }}</li>
          <li style="line-height:20px;">本次兌換點數: {{ item.point }}</li>
          <li style="line-height:20px;">剩餘點數:   {{ redeemPoint.point }}</li>
        </ul>
      </div>
      <br><br>
      <div class="list">
        <div class="item" style="text-align:center;">
          <div class="left"></div>
          <div class="middle" style="margin-left: 0px;">
            <qrcode-vue :value="qrCode" size="120" level="H"></qrcode-vue>
            <br>
          </div>
          <div class="right">
            <!-- <i class="cubeic-close" style="margin-left: -40px;" @click="closeDia"/> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="dialog" class="dialog" v-if="showWrap === true " style="z-index: 9999999;" :class="showWrap === true ?'fadein':'fadeout'">
    <div class="cube-popup cube-popup_mask cube-dialog" style="z-index: 100;">
      <div class="cube-popup-mask dialog-mask"></div>
      <div class="cube-popup-container cube-popup-center dialog-content">
        <div class="cube-popup-content">
          <div class="cube-dialog-main">
            <span class="cube-dialog-close">
              <i class="cubeic-close" @click="showWrap = false"></i>
            </span>
            <div class="cube-dialog-alert">
              <h2 class="cube-dialog-title">
                <br>
                <i class="cubeic-alert" style="font-size: 28px;display: inline-block; width: 30px; height: 30px; padding: 10px; box-sizing: content-box; border-radius: 50%; background-color: #f3f4f5;"></i>
                <br><br>
                <div style="font-size: 20px;" >小提示</div>
              </h2>
              <div class="cube-dialog-content">
                <p class="my-content" style="font-size:16px;text-align: center;">{{'點選「確定」'+  '即扣除並產生兌換序號，'+ '恕無法溯回。 '}}</p>
              </div>
              <div class="cube-dialog-btns border-right-1px">
                <cube-button :light="true" class="cube-dialog-btn border-top-1px" style="width: 50%; float: left;" @click="showWrap = false">取消</cube-button>
                <cube-button :light="true" class="cube-dialog-btn border-top-1px" style="width: 50%; float: right;" @click="confirmedRedeem">確定</cube-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
/* eslint-disable */
import { mapMutations, mapGetters, mapActions } from 'vuex'
import QrcodeVue from 'qrcode.vue';
import flex from '@/components/data/flex.json'
import brandPhoto from  '@/components/data/brandPhoto.json'
export default {
  data () {
    return {
      thisDate: '',
      tempArr: [],
      showWrap: false,
      qrPng: '',
      nums: '',
      exchangePage: false,
      adjest: false,
      item: {},
      loading: false,
      clientLevel: '',
      //
      qrCode: '',
      drinkDialog: '',
      beforeStep: '1',
      // itemData: [],
      current: '',
      CityandArea: [],
      citylist: [],
      place: '',
      shopName: '',
      shopAddress: '',
      location: this.locate,
      showList: false,
      page: '1',
      title: '',
      options: {
        pullUpLoad: true
      }
    }
  },
  components: {
    QrcodeVue,
  },
  beforeCreate() {
  },
  mounted() {
    var date = new Date();
    var year = date.getFullYear().toString();
    var month = (date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1).toString()}` : (date.getMonth() + 1).toString();
    var day = date.getDate() < 10 ? `0${date.getDate().toString()}` : date.getDate().toString();
    this.thisDate = parseInt(year + month + day);
    // console.log(this.personalData);
    // console.log(this.item);
    // console.log(this.redeemList.filter(el => el.eventId === this.item.eventId));

    // this.loading = true;
    // console.log(flex2);
    // this.getEntireCoupon()
    const height = window.screen.height
    if (height === 667) {
      this.adjest = true;
    }
    this.getCouponItems()
    // if (this.couponList.length === 0) {
    //   this.$emit('gotoMain')
    // }
    // },1500)
  },
  computed: {
    ...mapGetters({
      eilisProfile: 'eilisProfile',
      personalData: 'personalData',
      couponList: 'couponList',
      redeemList: 'redeemList',
      levelContent: 'levelContent'
    }),
    storeRedeemTimeList: {
      get () { return this.redeemList },
      set(value) { this.storeRedeemList({ redeemList: value }); },
    },
    updateCouponList: {
      get () { return this.couponList },
      set(value) { this.storeCouponList({ couponList: value }); },
    },
    redeemPoint: {
      get () { return this.personalData },
      set(value) { this.storepersonal({ personal: value }); },
    },
    locate(val) {
      if (this.shopName === '') {
        return `https://www.google.com/maps/embed/v1/place?key=AIzaSyCNtpQAXt_GZAzMOiQ7ZvPmejKmZfmeYJo&q=${this.place}`
      } else {
        return `https://www.google.com/maps/embed/v1/place?key=AIzaSyCNtpQAXt_GZAzMOiQ7ZvPmejKmZfmeYJo&q=${this.shopAddress}`
      }
    }
  },
  methods: {
    ...mapActions({
      getCoupon: 'getCoupon',
      redeemCoupon: 'redeemCoupon',
      getRedeemTimes: 'getRedeemTimes',
      generateCouponNumber: 'generateCouponNumber'
    }),
    ...mapMutations({
      storeEilisFile: 'storeEilisFile',
      storeCouponList: 'storeCouponList',
      storeRedeemList: 'storeRedeemList'
    }),
    changeHandler() {},
    stickyChangeHandler() {},
    monthFilter(val) {
      console.log(val);
      // DEV NOTE:20210915 ping 優惠券排序
      val = val.sort((a, b) => a.index - b.index);
      console.log(this.personalData.birth.substr(5,2));
      let arr = val.filter(el => el.births && el.births === this.personalData.birth.substr(5,2))
      let arr1 = val.filter(el => !el.births)
      arr = arr.concat(arr1)
      this.tempArr = arr
    },
    getCouponItems() {
      this.$loading.show()
      // console.log(this.couponList);
      for (let i = 0; i < this.levelContent.list.length; i++) {
        // console.log(i);
        if (i === this.personalData.level) {
            this.clientLevel = this.levelContent.list[i].name
          } else if (this.personalData.level === '') {
            this.clientLevel = ''
        }
      }
      if (this.couponList.length === 0) {
        const A = this.getRedeemTimes({accountId: this.eilisProfile.accountId, userId: this.personalData.userId})
        const B = this.getCoupon({accountId: this.eilisProfile.accountId})
        Promise.all([A, B]).then(values => {
          // console.log(values[0]);
          this.storeRedeemTimeList = values[0].data.result
        })
        this.getCoupon({accountId: this.eilisProfile.accountId})
        .then((res) => {
          // console.log(res);
          let lineUp = {};
          lineUp = res.data.type
          let a = res.data.result
          if (res && res.data.result) {
            this.$loading.hide()
            let arr = [];
            let filterTag = [];
            // let birtharr = a.filter(el => el.births).length > 0 ? a.filter(el => el.births) : a

            this.monthFilter(a)
            filterTag = this.tempArr
            console.log(this.clientLevel);
            console.log(this.personalData);
            // 判斷等級、性別、櫃位
            console.log(filterTag);
            // console.log(filterTag[0].indexOf(this.clientLevel));
            filterTag = filterTag.filter(el =>
              ((el.levels && el.levels.indexOf(this.clientLevel) === -1 && !el.noShow ) || (el.levels && el.levels.indexOf(this.clientLevel) !== -1  ) || el.levels && el.levels.length < 1 || !el.levels) &&
              ((el.genders && el.genders.indexOf(this.personalData.gender) === -1 && !el.noShow)|| el.genders && el.genders.indexOf(this.personalData.gender) !== -1  || el.genders && el.genders.length < 1 || !el.genders) &&
              ((el.shops && el.shops.indexOf(this.personalData.shopNo) === -1 && !el.noShow) || (el.shops && el.shops.indexOf(this.personalData.shopNo) !== -1 ) || el.shops && el.shops.length < 1 || !el.shops)
            )
            console.log(filterTag);
            // DEV NOTE:20210915 ping 關閉原本過濾同名 === VVV
            // 過濾同名
            // let array2 = res.data.result.map(el => el.type)
            // let typeArr = array2.filter((ele,pos) => array2.indexOf(ele) == pos);
            // DEV NOTE:20210915 ping 關閉原本過濾同名 === ^^^^
            // DEV NOTE:20210915 ping 優惠商城標題直接吃資料庫type 
            let typeArr = res.data.type;
            for (let i = 0; i < typeArr.length; i++) {
              arr.push({
                item: [],
                type: typeArr[i]
              })
            }
            console.log(arr);
            for (let i = 0; i < filterTag.length; i++) {
              const type = filterTag[i].type
              const index = arr.map(el => el.type).indexOf(type);
              console.log(index);
              arr[index].item.push(filterTag[i])
              arr[index].item.filter(n => n)
            }
            console.log(this.thisDate);

            for (let i = 0; i < arr.length; i++) {
              for (let e = 0; e < arr[i].item.length; e++) {
                if (arr[i].item[e].onShelf && arr[i].item[e].offShelf) {
                  arr[i].item = arr[i].item.filter(el => parseInt(this.getFullDate(el.offShelf).substr(0,10).replace('-','').replace('-','')) >= this.thisDate && parseInt(this.getFullDate(el.onShelf).substr(0,10).replace('-','').replace('-','')) <= this.thisDate)                  // arr[i].item.splice(e,1);
                }
              }
            }
            const S = {}
            for (let i = 0; i < lineUp.length; i += 1) {
              const type = lineUp[i];
              S[type] = i;
            }
            console.log(S);
            arr = arr.sort((a, b) => S[a.type] - S[b.type]);
            console.log(arr);
            this.updateCouponList = arr
            console.log(this.updateCouponList);
          }
        })
      } else {
        this.$loading.hide()
      }
    },
    sendToLINE() {
      // console.log(flex);
      console.log(this.item.onShelf);
      // this.item.offshelf
      // this.item.onshel
      const url = `https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${this.qrCode}&choe=UTF-8`
      // const shop = store.filter(el => el.id === this.saleroom)[0].name
      // console.log(shop);
      const onshelf = this.getFullDate(this.item.onShelf).substr(0,10).replace('-','').replace('-','')
      const offshelf = this.getFullDate(this.item.offShelf).substr(0,10).replace('-','').replace('-','')

      // const onshelf  = this.item.onShelf.substr(0,10).replace('-','').replace('-','')
      // const offshelf = this.item.offShelf.substr(0,10).replace('-','').replace('-','')
      flex.hero.url = brandPhoto[0].items.photo;

      flex.hero.action.uri = brandPhoto[0].items.link;
      flex.body.contents[0].text = brandPhoto[0].items.name;
      flex.body.contents[1].contents[0].contents[1].text = this.item.itemName;
      flex.body.contents[1].contents[1].contents[1].text = this.nums;
      flex.body.contents[1].contents[2].contents[1].text = onshelf + '-' + offshelf;
      flex.body.contents[2].contents[2].text = this.item.notice;
      flex.body.contents[2].contents[1].url = url
      console.log(flex);
      // liff.init({
      //   liffId: this.eilisProfile.liffId
      // })
      // .then(() => {
        liff.sendMessages([
          {
            type: "flex",
            altText: "兌換序號為:",
            contents: flex,
          }
        ])
        .then(function() {
          // liff.closeWindow();
          // console.log('Message sent');
        }).catch(function(error) {
          window.alert('Error sending message: ' + error);
        });
      // })
    },
    showAlert() {
      this.$createDialog({
        zIndex: 100000000,
        type: 'confirm',
        title: '小提示',
        icon: 'cubeic-alert',
        content: '確認已經兌換完畢?',
        confirmBtn: {
          text: '確認',
          active: true,
          disabled: false,
          href: 'javascript:;'
        },
        cancelBtn: {
          text: '返回',
          active: false,
          disabled: false,
          href: 'javascript:;'
        },
        onConfirm: () => {
          this.exchangePage = false;
          location.reload()
        },
        onCancel: () => {
          this.showCoupon()
        },
        // icon: 'cubeic-alert'
      }).show()
    },
    showCoupon(contents) {
      // console.log(contents);
      this.exchangePage = true;
    },
    confirmedRedeem() {
      console.log(this.eilisProfile);
      console.log(this.personalData.point);
      console.log(this.item);
      // return;
      const year = new Date(Date.now()).getFullYear().toString()
      const month = (new Date(Date.now()).getMonth() +1 ) < 10 ? `0${new Date(Date.now()).getMonth() +1}` : new Date(Date.now()).getMonth() +1;
      const date = new Date(Date.now()).getDate() < 10 ? `0${new Date(Date.now()).getDate()}` : new Date(Date.now()).getDate()

      if (this.item.maxRedeem === 1 && this.storeRedeemTimeList && this.storeRedeemTimeList.filter(el => el.eventId === this.item.eventId).length > 0) {
        // console.log('1');

        this.storeRedeemTimeList.filter(el => el.eventId === this.item.eventId)[0].count = this.item.num
      } else if (this.storeRedeemTimeList && this.storeRedeemTimeList.filter(el => el.eventId === this.item.eventId).length > 0) {
        // console.log('2');

        if (this.storeRedeemTimeList && this.storeRedeemTimeList.filter(el => el.eventId === this.item.eventId).length > 0) {
          this.storeRedeemTimeList.filter(el => el.eventId === this.item.eventId)[0].count = this.storeRedeemTimeList.filter(el => el.eventId === this.item.eventId)[0].count + 1
        } else {
          this.storeRedeemTimeList.filter(el => el.eventId === this.item.eventId)[0].count = null;
          this.storeRedeemTimeList.filter(el => el.eventId === this.item.eventId)[0].count = this.storeRedeemTimeList.filter(el => el.eventId === this.item.eventId)[0].count + 1
        }
        // this.redeemPoint.exchangeItem[this.item.itemNo] = this.redeemPoint.exchangeItem[this.item.itemNo].valueOf() // valueOf() : 將數字以數字型態返回。
      } else {
        // console.log('3');
        // this.redeemPoint.exchangeItem[this.item.itemNo].valueOf();
        this.storeRedeemTimeList.push({ count: 0, eventId: this.item.eventId})
        // this.redeemPoint.exchangeItem[this.item.itemNo] = this.redeemPoint.exchangeItem[this.item.itemNo].valueOf();
        this.storeRedeemTimeList.filter(el => el.eventId === this.item.eventId)[0].count = this.storeRedeemTimeList.filter(el => el.eventId === this.item.eventId)[0].count + 1
        // console.log(this.storeRedeemTimeList);
      }
      // return;
      this.redeemPoint.point = this.redeemPoint.point - this.item.point
      console.log(this.redeemPoint.point);
      // return;
      const toast = this.$createToast({
        txt: 'Loading...',
        mask: true,
        zIndex: 1000000
      })
      toast.show()
      this.showWrap = false;
this.redeemCoupon({ accountId: this.eilisProfile.accountId, clientId: this.eilisProfile.clientId, eventId: this.item.eventId, phone: this.personalData.phone, point: this.item.point, coupon: this.nums, eventId: this.item.eventId, expire: this.item.expire, itemName: this.item.itemName})
      .then((res) => {
        console.log(res);
        toast.hide()
        const toa = this.$createToast({
          txt: '序號已傳送到LINE',
          mask: true,
          zIndex: 100000000
        })
        toa.show()
        // if (this.item.num === 1) {
        //   this.$emit('getEntireCoupon', '首頁');
        // }
        this.sendToLINE()
        this.drinkDialog = '';
        this.showCoupon()
      })
    },
    getFullDate(time) {
      const date = time ? new Date(time) : new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1).toString()}` : (date.getMonth() + 1).toString();
      const day = date.getDate() < 10 ? `0${date.getDate().toString()}` : date.getDate().toString();
      return `${year}${month}${day}`;
    },
    beforeCheck() {
      const year = new Date(Date.now()).getFullYear().toString()
      const month = (new Date(Date.now()).getMonth() +1 ) < 10 ? `0${new Date(Date.now()).getMonth() +1}` : new Date(Date.now()).getMonth() +1;
      const date = new Date(Date.now()).getDate() < 10 ? `0${new Date(Date.now()).getDate()}` : new Date(Date.now()).getDate()
      // console.log(year+month+date);
      // return;
      if ((this.personalData.point - this.item.point) < 0) {
        this.$createDialog({
        type: 'confirm',
        title: '小提示',
        zIndex: 100000,
        content: '點數不足無法兌換',
        icon: 'cubeic-alert',
        confirmBtn: {
          text: '確定',
          active: true,
          disabled: false,
          href: 'javascript:;'
        },
        cancelBtn: {
          text: '取消',
          active: false,
          disabled: false,
          href: 'javascript:;'
        },
        }).show()
        return;
      }
      this.showWrap = true;
    },
    decideItem(content) {
      console.log(this.personalData);
      console.log(content);
      console.log("529");
      // console.log(this.redeemList.filter(el => el.eventId === content.eventId).length > 0)
      // item.genders && item.genders !== '' && item.genders.indexOf(personalData.gender) === -1
      if ( (content.levels && content.levels.indexOf(this.clientLevel) === -1 ) || (content.genders && content.genders !== '' && content.genders.indexOf(this.personalData.gender) === -1) || (content.shops && content.shops !== '' && content.shops.filter(el => el !== this.personalData.shopNo)  || (content.maxRedeem && this.redeemList.filter(el => el.eventId === content.eventId).length > 0 && content.maxRedeem - this.redeemList.filter(el => el.eventId === content.eventId)[0].count  <= 0))) {
        console.log(1);
        // this.toast = this.$createToast({
        //   txt: `${content.levels}會員專屬`,
        //   time: 900,
        //   type: 'txt'
        // }).show()
        return;
      } else if (this.personalData.point < content.point) {
        const toast = this.$createToast({
          txt: 'Loading...',
          mask: true
        })
        toast.show()
        this.$createDialog({
          type: 'confirm',
          title: '小提示',
          zIndex: 100000,
          content: '點數不足',
          icon: 'cubeic-alert',
          confirmBtn: {
            text: '確定',
            active: true,
            disabled: false,
            href: 'javascript:;'
          },
        }).show()
        toast.hide()
        return;
      }
      const toast = this.$createToast({
        txt: 'Loading...',
        mask: true
      })
      toast.show()
      this.generateCouponNumber({ accountId: this.eilisProfile.accountId,eventId: content.eventId})
      .then((res) => {
        console.log(res);
        console.log("569");
        if (res.data.status === 10020) {
          toast.hide()
          this.nums = res.data.coupon;
          this.qrCode = res.data.coupon;
          this.drinkDialog = 'out';
          this.item.maxRedeem = content.maxRedeem
          this.item.expire = content.expire ? content.expire : null;          
          this.item.itemName = content.itemName;
          this.item.gender = content.gender;
          this.item.point = content.point;
          this.item.img = content.img;
          this.item.level = content.level;
          this.item.notice = content.notice;
          this.item.shopName = content.shopName;
          this.item.content = content.content;
          this.item.eventId = content.eventId;
          this.item.num = content.num;
          this.item.offShelf = content.offShelf;
          this.item.onShelf = content.onShelf;
        } else {
          toast.hide()
        }
      })
    },
    test(val) {
      this.beforeStep = '1';
      console.log(val);
      this.showList = true;
      // this.place = '';
    },
    plus() {
      if (this.item.num > 39) {
        return this.num;
      } else {
        this.item.num += 1;
      }
    },
    minus() {
      if (this.item.num < 2) {
        return this.num === 1;
      } else {
        this.item.num -= 1;
      }
    },
    close() {
      this.drinkDialog = '';
    },
    closeWrap() {
      this.showWrap = false;
    },
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  font-family: 'Helvetica Neue', sans-serif;
  }
  .cube-dialog-confirm .p {
    font-size: 17px;
  }
  .box {
    height: 100vh;
    z-index: 0;
    padding-left: 0!important;
    padding-right: 0!important;
    margin: 0 auto;
    width: 100%;
    max-width: 540px;
    background-color: #ffffff;
    /* border: 1px solid rgb(170, 170, 170); */
    /* border:1px solid #8a8a8a; */
  }
  #smallLayer {
    z-index: 10000000000;
    position: fixed;
    right: 0px;
    height: 85px;
    width: 85vw;
    background: rgba(255, 255, 255, 0.577);
    @media screen and (max-width: 414px) {
      margin-bottom: 4px;
      height: 85px;
      // margin-bottom: 20px;
    }
    @media screen and (max-width: 375px) {
      height: 95px;
      // margin-bottom: 20px;
    }
    @media screen and (max-width: 320px) {
      height: 105px;
      // margin-bottom: 20px;
    }
  }
  #layer{
    z-index: 100000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(181, 181, 181, 0.419);
  }
  #layer1{
    z-index: 100000000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(181, 181, 181, 0.419);
  }
  .info {
    // position: fixed;
    // border: 1px solid rgb(211, 211, 211);
    border-radius: 0px;
    z-index: 1000;
    width: 90%;
    margin: 0 auto;
    z-index: 10000;
    background-color: #ffffff;
    margin-top: 90px;
    height: 500px;
    font-size: 0.5cm;
    border-radius: 3px;
    overflow-y: auto;
    // transform:translate(-50%,-50%);
    animation: bomaction 0.15s linear forwards;
    // transform: rotate(0deg); // fix 子元素超出边框圆角部分不隐藏的问题
  }
  @keyframes bomaction {
  0% {
    -webkit-transform: scale(0.1)  rotate(0deg);
            transform: scale(0.1)  rotate(0deg);
    top:0%;
  }
  46% {
    top:52%;
  }
  48% {
    top:48%;
  }
  50% {
    -webkit-transform: scale(0.5)  rotate(0deg);
            transform: scale(0.5)  rotate(0deg);
    top:52%;
  }
  54% {
    top:52%;
  }
  56% {
    top:48%;
  }
  57% {
    -webkit-transform: scale(0.9)  rotate(0deg);
            transform: scale(0.9)  rotate(0deg);
    top:48%;
  }
  75% {
    -webkit-transform: scale(0.95)  rotate(0deg);
            transform: scale(0.95)  rotate(0deg);
    top:48%;
  }
  90% {
    -webkit-transform: scale(1.15)  rotate(0deg);
            transform: scale(1.15)  rotate(0deg);
    top:48%;
  }
  100% {
    -webkit-transform: scale(1) translateX(0px) rotate(0deg);
            transform: scale(1) translateX(0px) rotate(0deg);
    top:50%;
  }
  }
  .loadingIcon {
    z-index: 100000;
    display: flex; // 置中
    align-items: center; // 置中
    justify-content: center; // 置中
    margin: 0 auto;
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    // margin-top: -270px;
    width: 55px;
    height: 55px;
    color: #ffffff;
    border-radius: 5px;
    background-color: #717171;
    -webkit-text-fill-color: transparent;
  }
  .top {
    position: fixed;
    z-index: 10000;
    margin: 0px auto ;
    margin-left: 0px;
    width: 100%;
    height: 50px ;
    background-color: rgba(255, 255, 255, 0.24);
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.4);
  }
  .infoBottom {
    position: fixed;
    z-index: 10000;
    bottom: 0px;
    left: 0!important;
    right: 0!important;
    // width: 100%;
    // margin: 0px auto ;
  }
  .list{
    padding-left: 0!important;
    padding-right: 0!important;
    text-align: left;
    height: 100px;
    width: 100%;
    max-width: 400px;
    margin: 0px;
    padding: 0px;
    // overflow-y: auto;
    // -webkit-overflow-scrolling: touch;
    padding-bottom: 0px;
  }
  .list .item{
    margin-top: 2px;
    display: flex;
    //overflow-y: auto;
    align-items: center;
    justify-content: space-between;
    align-content: space-between;
    height: 85px;
    padding: 10px;
  }
  .item-box {
    padding-left: 0!important;
    padding-right: 0!important;
    z-index: 1000;
    height: 100%;
    width: 100%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0;
    padding-bottom: 80px;
    // overflow-y: auto;
  }
  .item-box .list{
    padding-left: 0!important;
    padding-right: 0!important;
    text-align: left;
    height: 100px;
    width: 100%;
    max-width: 400px;
    // margin: 0px;
    padding: 0px;
    // overflow-y: auto;
    // -webkit-overflow-scrolling: touch;
    padding-bottom: 0px;
  }
  .item-box .list .item {
    // overflow: auto;
    // -webkit-overflow-scrolling: touch;
    // overflow-y: auto;
    // -webkit-overflow-scrolling: touch;
    margin-top: 2px;
    display: flex;
    //overflow-y: auto;
    align-items: center;
    justify-content: space-between;
    align-content: space-between;
    border-bottom: 1px solid rgba(210, 210, 210, 0.815);
    height: 85px;
    width: 70vw;
    // padding: 10px;
  }
  .item-box .list .item .middle .title {
    margin-top: 0px;
    color: rgba(0, 0, 0, 0.851);
    font-size: 16px;
    font-weight: 500;
    display: flex;
    letter-spacing: 1px;
    justify-content: space-between;
  }
  .item-box .list .item .middle .entitle {
    margin-top: 2px;
    transform: scale(0.8);
    margin-left: -19px;
    color: rgba(67, 67, 67, 0.851);
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 1px;
  }
  .item-box .list .item .right {
    transform: scale(1.5);
    border-radius: 50%;
    // border: 1px solid  rgba(177, 198, 44, 0.877) ;
    background-clip: text;
    // -webkit-text-fill-color: transparent;
    // border: 1px solid rgba(112, 88, 88, 0.877);
    color: var(--main-color);
    margin-top: 30px;
    width: 20px;
    // margin-left: 0px;
  }
  .nameHint {
    // position: fixed;
    // width: 95%;
    // height: 40px;
    // margin: 10px auto;
    // border: 1px solid #F37423;
    // border-radius: 4px;
    // background-color: #ffffff;
    z-index: 1000;
    background-color: white;
    position: fixed;
    padding: 10px;
    height: 50px;
    top: 0;
    width: 100%;
  }
  .drinkItem {
    position: fixed;
    overflow: hidden;
    margin: 10px auto;
    height: 100%;
    width: 100%;
    // overflow-y: auto;
    // -webkit-overflow-scrolling: touch;
  }
  .drinkPopDia {
    // padding: 1em;
    position: absolute;
    z-index: 20000;
    // display: grid;
    // grid-gap: 15px;
    // position: fixed;
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
    height: 800px;
    background-color: #f9f9f9;
  }
  .drinkPopDia .movePart {
    position: relative;
    // height: 100%;
    width: 100%;
    // padding-bottom: 110px;
    // overflow-y: auto;
    // -webkit-overflow-scrolling: touch;
  }
  .drinkPopDia .drinktop {
    display: grid;
    grid-template-columns: 10% 70% 20%;
    position: fixed;
    z-index: 10000;
    width: 100%;
    height: 40px ;
    // background-color: rgba(255, 255, 255, 0.24);
    // box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.4);
  }
  .drinkPopDia .title {
    display: grid;
    grid-gap: 5px;
    width:95%;
    padding: 10px;
    height: 60px;
    background-color: rgba(255, 255, 255, 0.933);
    margin: 0 auto;
    border-radius:5px;
    text-align: left;
    // background-color: rgba(255, 255, 255, 0.24);
    // box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.4);
  }
  .drinkPopDia .item_contain {
    width:95%;
    // height: 100vh;
    // DEV NOTE:20210914 修改原為height:700px ping
    min-height: 700px;
    background-color: rgb(255, 255, 255);
    margin: 0 auto;
    border-radius:5px;
    // grid-template-columns: 2fr 2fr ;
    // grid-template-rows:  2fr 2fr;
    // grid-gap: 20px;
    padding: 10px;
  }
  .drinkPopDia .item_contain .item{
    text-align: left;
    color: #5b5b5b;
    width: 22em;
    max-width: 540px;
    height: 15%;
    margin: 15px 0px;
    // background: #ffa5a8;
    // DEV NOTE:20210914 內容換行 ping
    white-space:pre-wrap;
    line-height: 22px;
  }
  .drinkPopDia .item_contain .item .button{
    color: #6e6e6e;
    background-color: transparent;
    width: 65px;
    height: 28px;
    margin: 5px;
    border-radius: 50px;
    font-size: 13px;
  }
  .active{
    border: 1px solid rgb(255, 116, 30);
    color: rgb(19, 3, 3);
    background-color:rgb(255, 255, 255);
  }
  .drinkPopDia .bottom_contain {
    position: fixed;
    bottom: 0px;
    width:100%;
    // height: 150px;
    border-top: 1px solid #dfdfdf;
    background-color: #ffff;
    // background-color: #f9f9f9;
    margin: 0px auto;
    // display: grid;
    // grid-gap: 5px;
    // grid-template-columns: 33% 33% 33%;
    padding: 5px;
  }
  .bottom_item_box {
    padding-left: 0!important;
    padding-right: 0!important;
    height: 150px;
    width: 100%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0;
  }
  .bottom_list {
    padding-left: 0!important;
    padding-right: 0!important;
    text-align: left;
    height: 110px;
    width: 94vw;
    margin-top: 0px;
  }
  .bottom_middle_button {
    width: 110px;
    height: 35px;
    line-height: 0px;
    font-size: 15px;
    color: var(--main-color);
    background-color: rgba(255, 255, 255, 0.877);
    border:1px solid var(--main-color);
    border-radius:50px;
    margin-left: 30px;
  }
  .bottom_right_button {
    // background-clip: text;
    font-size: 15px;
    border-radius:50px;
    width: 110px;
    height: 35px;
    // -webkit-text-fill-color: transparent;
    // border: 1px solid rgba(112, 88, 88, 0.877);
    color: rgb(255, 255, 255);
    background-color: var(--main-color);
    padding: 0px;
  }
  .bottom_item {
    // margin-top: 0px;
    // border-bottom: none;
    // // align-items: center;
    // height: 55px;
    // padding: 5px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: space-between;
    margin-top: 5px;
    // border-bottom: 1px solid rgba(210, 210, 210, 0.815);
    height: 50px;
    padding: 0px;
  }
  .bottom_left {
    // height: 5px;
    // text-align: left;
    align-items: center;
  }
    // DEV NOTE: 20210914 增加‘返回菜單’按鈕bottom_left_button樣式
  .bottom_left_button {
  // background-clip: text;
  font-size: 15px;
  border-radius: 20px;
  width: 90px;
  height: 35px;
  margin-top: 15px;
  margin-left: -380%;
  // -webkit-text-fill-color: transparent;
  // border: 1px solid rgba(112, 88, 88, 0.877);
  color: rgb(255, 255, 255);
  background-color: #da3925;
  padding: 0px;
  z-index: 1000;
  }
  .bottom_right {
    background-clip: text;
    font-size: 17px;
    padding: 0px;
    transform: translateX(5px);
  }
  .bottom_middle {
    transform: translateX(25px);
  }


  div.dialog-mask {
    width: 100%;
    position: absolute;
    z-index: 110;
    height: 100%;
  }

  .dialog-content {
    position: fixed;
    z-index: 111;
  }

  .dialog {
    position: fixed;
    width: 100%;
    right: 0;
    bottom: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.40);
    border: none;
    border-radius: 5px;
    transform: translate(0, 0);
    color: #fff;
    font-size: 13px;
    z-index: 999999;
    text-align: justify;
  }

  #dialog {
    z-index: 999999;
  }

  .fadein {
    animation: animate_in 0.25s;
  }

  .fadeout {
    animation: animate_out 0.25s;
    opacity: 0;
  }

  @keyframes animate_in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes animate_out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
</style>